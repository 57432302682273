/* eslint-disable no-unused-vars */
import { createContext } from 'react';

export interface IToastContext {
    addInfoToast: (message: string) => void;
    addErrorToast: (message: string) => void;
    addSuccessToast: (message: string) => void;
    addWarningToast: (message: string) => void;
}

export const DEFAULT: IToastContext = {
    addInfoToast(_message: string): void {
        throw new Error('Function not implemented.');
    },
    addErrorToast(_message: string): void {
        throw new Error('Function not implemented.');
    },
    addSuccessToast(_message: string): void {
        throw new Error('Function not implemented.');
    },
    addWarningToast(_message: string): void {
        throw new Error('Function not implemented.');
    }
};

export default createContext<IToastContext>(DEFAULT);
