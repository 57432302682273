import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { useAdminDataStore } from '../../../../hooks/useStore';
import { Avatar } from '../../../../components';
import renderChip from '../../../../utils/helpers/renderChip';
import config from '../../../../config';

import useHomeStyles from './styles';
import Promocodes from '../../../../components/Promocodes/Promocodes';
import TournamentsReport from '../../../../components/TournamentsReport/TournamentsReport';

const DashboardHomePage = () => {
    const classes = useHomeStyles();
    const adminDataStore = useAdminDataStore();
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Grid container spacing={5} direction={isLargeScreen ? 'row' : 'column'}>
            <Grid item xs={12}>
                <Card className={classes.root} elevation={3}>
                    <CardContent>
                        <Typography variant="h3" gutterBottom>
                            Добро пожаловать, {adminDataStore.first_name}!
                        </Typography>
                        <Grid container spacing={5} direction={isLargeScreen ? 'row' : 'column'}>
                            <Grid item>
                                <Avatar user={adminDataStore} size="medium" />
                            </Grid>
                            <Grid item lg={2} md={4} xs={12}>
                                <Typography variant="h4">Личные данные</Typography>
                                <List disablePadding className={classes.list}>
                                    <ListItem disableGutters>
                                        Имя: {adminDataStore.first_name || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Фамилия: {adminDataStore.last_name || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Отчество: {adminDataStore.middle_name || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Дата рождения:{' '}
                                        {adminDataStore.birthdate
                                            ? new Date(
                                                  adminDataStore.birthdate
                                              ).toLocaleDateString()
                                            : '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Пол:{' '}
                                        {config.references.sexOptions[adminDataStore.sex] || '-'}
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item lg={2} md={4} xs={12}>
                                <Typography variant="h4">Местоположение</Typography>
                                <List disablePadding className={classes.list}>
                                    <ListItem disableGutters>
                                        Страна: {adminDataStore.country || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Регион: {adminDataStore.region || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Город: {adminDataStore.city || '-'}
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item lg={2} md={4} xs={12}>
                                <Typography variant="h4">Контакты</Typography>
                                <List disablePadding className={classes.list}>
                                    <ListItem disableGutters>
                                        Email: {adminDataStore.email || '-'}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Телефон:{' '}
                                        {parsePhoneNumber(`+${adminDataStore.phone}`)?.number
                                            ?.international ||
                                            adminDataStore.phone ||
                                            '-'}
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item lg={2} md={4} xs={12}>
                                <Typography variant="h4">Профиль Amatour</Typography>
                                <List disablePadding className={classes.list}>
                                    <ListItem disableGutters>
                                        Роль: &nbsp;
                                        {renderChip({
                                            label: config.references.roles[adminDataStore.role],
                                            icon: config.references.rolesIcons[adminDataStore.role],
                                            color: config.references.rolesColors[
                                                adminDataStore.role
                                            ],
                                            variant: 'outlined'
                                        })}
                                    </ListItem>
                                    <ListItem disableGutters>
                                        Статус: &nbsp;
                                        {adminDataStore.status
                                            ? renderChip({
                                                  label: config.references.playerStatus[
                                                      adminDataStore.status
                                                  ],
                                                  icon: config.references.playerStatusIcons[
                                                      adminDataStore.status
                                                  ],
                                                  color: config.references.playerStatusColors[
                                                      adminDataStore.status
                                                  ],
                                                  variant: 'outlined',
                                                  width: 'max-content'
                                              })
                                            : '-'}
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <TournamentsReport />
            <Grid item lg={6} xs={12}>
                <Card className={classes.root} elevation={3} style={{ height: '100%' }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Турниры
                        </Typography>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">
                                    Всего турниров:
                                </Typography>

                                <Typography variant="h2" component="h2">
                                    {adminDataStore.stats.tournament_count}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">
                                    Текущие турниры:
                                </Typography>
                                <Typography variant="h2" component="h2">
                                    {adminDataStore.stats.upcoming_tournament_count}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">
                                    Прошедшие турниры:
                                </Typography>
                                <Typography variant="h2" component="h2">
                                    {adminDataStore.stats.past_tournament_count}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            className={classes.btn}
                            onClick={() => navigate('/dashboard/tournaments', { replace: true })}
                        >
                            Смотреть все
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Card className={classes.root} elevation={3} style={{ height: '100%' }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Работа с заявками
                        </Typography>
                        <Grid container spacing={5}>
                            <Grid item>
                                <Typography variant="h5" component="h2">
                                    Новые заявки:
                                </Typography>
                                <Typography variant="h2" component="h2">
                                    {adminDataStore.stats.waiting_requests_count}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            className={classes.btn}
                            onClick={() =>
                                navigate('/dashboard/applications', {
                                    replace: true
                                })
                            }
                        >
                            Смотреть все
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Card className={classes.root} elevation={3} style={{ height: '100%' }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Продвижение
                        </Typography>
                        <Grid container spacing={5}>
                            <Grid item>
                                <Typography variant="h5" component="h2">
                                    Активные продвижения:
                                </Typography>
                                <Typography variant="h2" component="h2">
                                    {adminDataStore.stats.active_announces_count}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            className={classes.btn}
                            onClick={() => navigate('/dashboard/promotion', { replace: true })}
                        >
                            Смотреть все
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item lg={12} xs={12}>
                <Promocodes />
            </Grid>
        </Grid>
    );
};

export default observer(DashboardHomePage);
