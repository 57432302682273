import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { ToastContext } from '../../utils/context';
import PromocodesContext from './Promocodes.context';
import styles from './Promocodes.module.scss';
import { promocodeTypes } from './Promocodes.schema';
import ButtonWithConfirm from '../ButtonWithConfirm/ButtonWithConfirm';
import config from '../../config';

const confirmationProps = (promocode) => ({
    title:
        promocode.code_type === 'referral'
            ? 'Деактивировать реферальную ссылку'
            : 'Деактивировать промокод',
    text:
        promocode.code_type === 'referral'
            ? 'При деактивации реферальной ссылки игрок, перешедший по этой ссылке не будет закреплен за директором создавшим ее'
            : 'При деактивации промокода игрок не сможет воспользоваться им'
});

const getReferalLink = (code) => `${config.server.service}/referral?code=${code}`;

const copyLinkHandler = (promocode, onSuccess = () => {}) => {
    if (navigator) {
        navigator.clipboard
            .writeText(
                promocode.code_type === 'referral' ? getReferalLink(promocode.code) : promocode.code
            )
            .then(() => {
                onSuccess();
            });
    }
};

const PromocodesListitemActions = ({ promocode }) => {
    const pomocodesContest = useContext(PromocodesContext);
    const { addSuccessToast } = useContext(ToastContext);
    return (
        <Grid container spacing={3} className={styles.promocodesListitem__secondaryAction}>
            {promocode.is_active && (
                <Grid item>
                    <ButtonWithConfirm
                        onClick={() => {
                            pomocodesContest.deletePromocodeHandler(promocode.id);
                            addSuccessToast('Промокод деактивирован');
                        }}
                        confirmationProps={confirmationProps(promocode)}
                    >
                        Деактивировать
                    </ButtonWithConfirm>
                </Grid>
            )}
            <Grid item>
                <Button
                    onClick={() =>
                        copyLinkHandler(promocode, () =>
                            addSuccessToast(
                                promocode.code_type === 'referral'
                                    ? 'Реферальная ссылка скопирована'
                                    : 'Код скопирован'
                            )
                        )
                    }
                    variant="contained"
                    disabled={!promocode.is_active}
                >
                    Копировать <CopyAll />
                </Button>
            </Grid>
        </Grid>
    );
};

PromocodesListitemActions.propTypes = {
    promocode: promocodeTypes
};

export default PromocodesListitemActions;
