import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio as MuiRadio } from '@mui/material';
import { isUndefined } from 'lodash';
import useFormFieldStyles from './styles';

export interface RadioProps {
    id: number | string;
    name: string;
    disabled?: boolean;
    value?: any;
}

const Radio = ({ id, name, disabled, value }: RadioProps) => {
    const classes = useFormFieldStyles({
        error: false
    });

    return (
        <FormControlLabel
            classes={{
                label: classes.label
            }}
            value={isUndefined(value) ? id.toString() : value}
            control={<MuiRadio color="primary" />}
            label={name}
            disabled={disabled}
        />
    );
};

export default Radio;
