import { createRoot } from 'react-dom/client';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';

import { BrowserRouter as Router } from 'react-router-dom';

import theme from './theme';
import App from './App';
import { StoreProvider } from './utils/context/StoreContext';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <StoreProvider>
                <Router>
                    <App />
                </Router>
            </StoreProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);
