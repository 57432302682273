import { debounce, inRange } from 'lodash';
import { makeAutoObservable } from 'mobx';

const SCHEMA = {
    id: null,
    place: null,
    request_id: null,
    win_cnt: null
};

const DEFAULT_VALUES = {
    ...SCHEMA,
    input: ''
};

export default class PlaceClass {
    constructor(api, values = {}, substage) {
        this.api = api;
        this.substage = substage;

        const initialValues = { ...SCHEMA, ...values };
        this.initialValues = initialValues;

        Object.entries({ ...DEFAULT_VALUES, ...initialValues }).forEach(([key, value]) => {
            this[key] = value;
        });

        this.input = `${values.place}`;

        makeAutoObservable(this);
    }

    get isInputValid() {
        return inRange(+this.input, 1, 100);
    }

    get getData() {
        return Object.keys(SCHEMA).reduce((acc, curr) => {
            acc[curr] = this[curr];
            return acc;
        }, {});
    }

    setInput = (value) => {
        this.input = value;
        if (this.isInputValid) {
            this.place = +value;
            this.patchPlaceDebounced();
        }
    };

    patchPlace = async () => {
        await this.api.putPlace({
            placeId: this.id,
            payload: { place: this.place, win_cnt: this.win_cnt }
        });
        this.initialValues = { ...this.getData };
    };

    patchPlaceDebounced = debounce(() => this.patchPlace(), 300, {
        leading: false,
        trailing: true
    });
}
