import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import useHomeStyles from '../../pages/dashboardPage/sections/homePage/styles';
import PromocodesContext from './Promocodes.context';
import PromocodesList from './Promocodes.list';
import PromocodesCreatePopup from './Promocodes.CreatePopup';
import usePromocodesLogic from './Promocodes.logic';
import { DEFAULT_REFERAL } from './Promocodes.schema';

const Promocodes = () => {
    const classes = useHomeStyles();

    const {
        promocodesLoading,
        getPromocodeHandler,
        deletePromocodeHandler,
        postPromocodeHandler,
        promocodes
    } = usePromocodesLogic();

    return (
        <PromocodesContext.Provider
            value={{
                promocodesLoading,
                getPromocodeHandler,
                deletePromocodeHandler,
                postPromocodeHandler,
                promocodes
            }}
        >
            <Card className={classes.root} elevation={3} style={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        {/* Промокоды и реферальные ссылки */}
                        Реферальные ссылки
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                            <PromocodesList list={promocodes} isLoading={promocodesLoading} />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    {/* <PromocodesCreatePopup /> */}
                    <Button
                        className={classes.btn}
                        onClick={() => postPromocodeHandler(DEFAULT_REFERAL)}
                    >
                        Новая реферальная ссылка
                    </Button>
                </CardActions>
            </Card>
        </PromocodesContext.Provider>
    );
};

export default Promocodes;
