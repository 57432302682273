import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';

export default class VideosStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    videos = [];

    page = 1;

    title = null;

    page_size = 6;

    state = null;

    dates = { gte: null, lte: null };

    get getVideos() {
        return this.videos;
    }

    addVideo = (video) => {
        if (video) {
            this.count += 1;
            this.videos = [video, ...this.videos];
        }
    };

    setVideos = ({ results, count }) => {
        this.count = count || 0;
        this.videos = results || [];
    };

    clearQuery = () => {
        this.page = 1;
        this.title = null;
        this.page_size = 6;
        this.state = null;
        this.dates = { gte: null, lte: null };
    };

    get queryParams() {
        const { page, page_size, title, state } = this;

        let gte = null;
        let lte = null;

        if (this.dates.gte) {
            try {
                gte = format(this.dates.gte, 'yyyy-MM-dd');
            } catch (er) {
                //
            }
        }
        if (this.dates.lte) {
            try {
                lte = format(this.dates.lte, 'yyyy-MM-dd');
            } catch (er) {
                //
            }
        }

        return {
            page: page > 1 ? page : null,
            page_size,
            title: title || null,
            state: state || null,
            gte,
            lte
        };
    }

    get datesAsArray() {
        const { gte, lte } = this.dates;
        return [gte, lte];
    }

    get dateRangePickerRanges() {
        const { gte, lte } = this.dates;
        return [{ startDate: gte, endDate: lte }];
    }

    setDates = ([gte, lte]) => {
        this.dates = { gte, lte };
    };

    setDateRangePickerRanges = ({ startDate, endDate }) => {
        this.dates = { gte: startDate, lte: endDate };
    };

    setTitle = (title) => {
        this.title = title;
        this.page = 1;
    };

    setState = (state) => {
        this.state = state;
    };

    handlePageChange = (_, newPage) => {
        if (newPage !== this.page) {
            this.page = newPage;
        }
    };

    updateVideo = (id, value, field) => {
        const videoIndex = this.videos.findIndex((video) => video.id === id);
        this.videos[videoIndex] = {
            ...this.videos[videoIndex],
            [field]: value
        };
    };

    removeVideo = (id) => {
        this.videos = this.videos.filter((video) => video.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['videos'].forEach((key) => {
            this[key] = [];
        });
        console.warn('VideosStore clear');
    };
}
