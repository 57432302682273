import { makeAutoObservable } from 'mobx';

export default class MatchCellClass {
    constructor({ match, isMirror = false, saveAsMirror = false }) {
        this.match = match;
        this.isMirror = isMirror;
        this.saveAsMirror = saveAsMirror;

        makeAutoObservable(this);
    }
}
