import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    applicationPartnerSelect: {
        margin: 0
    },
    input: {
        fontSize: '1rem',
        color: theme.palette.primary.dark
    },
    autocomplete: {
        margin: theme.spacing(2, 0, 2),

        '& .MuiFormLabel-root': {
            fontSize: '1rem'
        },

        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50%-15px)'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            fontSize: '1rem'
        }
    },
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(5),
        fontSize: '0.9rem',

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end'
    }
}));
export default useStyles;
