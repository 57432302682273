import PropTypes from 'prop-types';

const THIRTY_DAYS_IN_MS = 2_592_000_000;

const CODE_TYPES = {
    discount: 'Скидка',
    referral: 'Реферальная ссылка'
};
const CODE_CATEGORY = {
    subscription: 'На подписку',
    tournament: 'На турнир'
};

const DEFAULT_REFERAL = {
    code_type: 'referral',
    onetime: false,
    start_date: new Date(),
    end_date: new Date(new Date().getTime() + THIRTY_DAYS_IN_MS),
    category: 'subscription',
    discount: 0,
    max_use_number: 1,
    product: null
};

const DEFAULT_DISCOUNT = {
    code_type: 'discount',
    onetime: true,
    start_date: new Date(),
    end_date: new Date(new Date().getTime() + THIRTY_DAYS_IN_MS),
    category: 'subscription',
    discount: 10,
    max_use_number: 1,
    product: null
};

const promocodeTypes = PropTypes.shape({
    code: PropTypes.string.isRequired,
    category: PropTypes.oneOf(['subscription', 'tournament']),
    code_type: PropTypes.oneOf(['discount', 'referral']),
    discount: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    end_date: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_active: PropTypes.bool.isRequired,
    max_use_number: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    number_of_uses: PropTypes.number,
    onetime: PropTypes.bool,
    product: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    start_date: PropTypes.string.isRequired
});

export { promocodeTypes, CODE_TYPES, CODE_CATEGORY, DEFAULT_REFERAL, DEFAULT_DISCOUNT };
