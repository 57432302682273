import axios from 'axios';
import config from '../../config';

const AdminDataStoreApi = {
    async refreshToken(refresh) {
        const data = await axios.post(
            `${config.server.serviceHostPublic}${config.server.apiPublic.refreshToken.uri}`,
            { refresh }
        );
        return data;
    },

    getUserData(access, token) {
        return axios.get(
            `${config.server.serviceHost}${config.server.api.getCurrentUserInfo.uri}`,
            {
                headers: {
                    Authorization: `Bearer ${access}`,
                    'X-Requested-With': token
                }
            }
        );
    },

    logOut() {}
};

export default AdminDataStoreApi;
