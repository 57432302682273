import { makeAutoObservable } from 'mobx';
import differenceBetweenObjects from '../../../utils/helpers/differenceBetweenObjects';

const SCHEMA = {
    id: null,
    p1_score: null,
    p2_score: null
};

const DEFAULT_VALUES = {
    ...SCHEMA
};

export default class SetClass {
    constructor(api, match, values = {}) {
        this.api = api;
        this.match = match;

        const initialValues = { ...SCHEMA, ...values };
        this.initialValues = initialValues;

        Object.entries({ ...DEFAULT_VALUES, ...initialValues }).forEach(([key, value]) => {
            this[key] = value;
        });

        makeAutoObservable(this);
    }

    uploadSet = () => {
        if (this.id) {
            return this.patchSet();
        }
        return this.postSet();
    };

    postSet = async () => {
        try {
            const { id } = await this.api.postSet(this.match.id, this.p1_score, this.p2_score);
            this.updateValues({ id });
            this.initialValues = { ...this.initialValues, id };
        } catch (error) {
            //
        }
    };

    deleteSet = () => {
        if (this.id) {
            try {
                this.api.deleteSet(this.match.id, this.id);

                Object.entries({ ...DEFAULT_VALUES }).forEach(([key, value]) => {
                    this[key] = value;
                });

                this.initialValues = { ...DEFAULT_VALUES };
            } catch (error) {
                //
            }
        }
    };

    patchSet = async () => {
        const payload = differenceBetweenObjects(this.getData, this.initialValues);
        if (Object.keys(payload).length) {
            try {
                const data = await this.api.patchSet(this.match.id, this.id, payload);
                if (data) {
                    this.initialValues = { ...this.getData };
                }
            } catch (error) {
                this.updateValues({ ...this.initialValues });
                throw error;
            }
        }
        // this.initialValues = { ...this.initialValues, ...payload };
    };

    updateValues = (values = {}) => {
        Object.entries(values).forEach(([key, value]) => {
            this[key] = value;
        });
    };

    get getData() {
        return Object.keys(SCHEMA).reduce((acc, curr) => {
            acc[curr] = this[curr];
            return acc;
        }, {});
    }
}
