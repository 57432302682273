import React, { useCallback, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export type DateFieldProps<TInputDate, TDate = TInputDate> = Omit<
    DatePickerProps<TInputDate, TDate>,
    'renderInput' | 'onChange'
> &
    React.RefAttributes<HTMLDivElement> & {
        touched?: boolean;
        errors?: any;
        value?: TDate;
        name: string;
        // eslint-disable-next-line no-unused-vars
        handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
        // eslint-disable-next-line no-unused-vars
        setFieldValue?: (field: string, value: any) => void;
        onChange?: () => void;
        maxDate?: TDate;
        fullWidth?: boolean;
        disabled?: boolean;
    };

const DateField = <TInputDate, TDate = TInputDate>({
    touched,
    errors,
    label,
    value,
    name,
    handleBlur,
    setFieldValue,
    onChange,
    maxDate,
    fullWidth = true,
    disabled,
    ...rest
}: DateFieldProps<TInputDate, TDate>) => {
    const classes = useFormFieldStyles({
        error: !!errors && !!touched
    });
    const [selectedDate, setSelectedDate] = useState<TDate | null>(value || null);

    const handleDateChange = useCallback(
        (date: TDate | null) => {
            setSelectedDate(date);
        },
        [setSelectedDate]
    );

    useEffect(() => {
        setFieldValue?.(name, selectedDate);
    }, [selectedDate]);

    return (
        <DatePicker
            // @ts-ignore
            autoOk
            variant="inline"
            inputVariant="outlined"
            inputFormat="dd/MM/yyyy"
            placeholder="20/03/2018"
            label={label}
            value={selectedDate as TInputDate}
            name={name}
            maxDate={maxDate}
            renderInput={(params) => (
                <TextFieldStyled
                    {...params}
                    className={classes.formField}
                    label={label}
                    variant="outlined"
                    FormHelperTextProps={{
                        className: classes.errorText
                    }}
                    InputProps={{
                        ...params.InputProps
                    }}
                    error={!!(touched && errors)}
                    helperText={touched && errors}
                    fullWidth
                />
            )}
            InputProps={{
                className: classes.input,
                endAdornment: !!(touched && errors) && (
                    <InputAdornment position="end">
                        <CloseIcon color="primary" />
                    </InputAdornment>
                )
            }}
            onChange={(date) => {
                handleDateChange(date);
                onChange?.();
            }}
            InputLabelProps={{
                className: classes.input,
                shrink: true
            }}
            FormHelperTextProps={{
                className: classes.errorText
            }}
            InputAdornmentProps={{
                position: 'start'
            }}
            onPaste={(e: React.ClipboardEvent) => e.preventDefault()}
            TextFieldComponent={TextFieldStyled}
            onBlur={handleBlur}
            error={!!(touched && errors)}
            helperText={touched && errors}
            fullWidth={fullWidth}
            disabled={disabled}
            {...rest}
        />
    );
};

export default DateField;
