import tinycolor from 'tinycolor2';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    table: {
        marginTop: theme.spacing(3),

        '& thead th': {
            fontWeight: '800',
            fontSize: '0.8rem',
            letterSpacing: '1px'
        },
        '& tbody td': {
            fontWeight: '300',
            fontSize: '0.8rem'
        },
        '& tbody tr:hover': {
            background: 'rgba(203, 211, 227, 0.2)',
            cursor: 'pointer'
        }
    },
    pagination: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        justifyContent: 'flex-end',

        '& .MuiPaginationItem-textPrimary.Mui-selected:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(10)
        }
    },
    controls: {
        [theme.breakpoints.down('lg')]: {
            width: '20%'
        }
    }
}));
