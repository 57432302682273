import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { Select as MuiSelect } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';

import useFormFieldStyles from './styles';

export type SelectProps = {
    options: any[];
    defaultText?: string;
    name: string;
    label: string;
    value: string;
    handleChange: any;
    setTouched?: any;
    size?: 'small' | 'medium';
    customClass?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    touched?: boolean;
    errors?: string;
    onChange?: any;
    shrink?: boolean;
};
const Select = ({
    options,
    defaultText,
    name,
    label,
    value,
    handleChange,
    setTouched,
    size,
    customClass,
    fullWidth = true,
    disabled,
    touched,
    errors,
    onChange,
    shrink = undefined
}: SelectProps) => {
    const classes = useFormFieldStyles({
        error: !!errors
    });

    return (
        <FormControl
            variant="outlined"
            className={classNames(
                classes.formControl,
                classes.formField,
                ...new Array(customClass)
            )}
            fullWidth={fullWidth}
            size={size}
            disabled={disabled}
            error={!!(touched && errors)}
        >
            <InputLabel shrink={shrink}>{label}</InputLabel>
            <MuiSelect
                name={name}
                value={value}
                onChange={(e) => {
                    handleChange(e);

                    if (onChange) {
                        onChange(e);
                    }
                }}
                onBlur={() => {
                    if (setTouched) {
                        setTouched(name, true);
                    }
                }}
                label={label}
                fullWidth={fullWidth}
                displayEmpty={!!defaultText}
                disabled={disabled}
            >
                {defaultText && (
                    <MenuItem value="" disabled>
                        <em>{defaultText}</em>
                    </MenuItem>
                )}
                {options.map(({ id, name, disabled }) => (
                    <MenuItem
                        key={id}
                        value={id}
                        className={classes.selectItem}
                        disabled={disabled}
                    >
                        {name}
                    </MenuItem>
                ))}
            </MuiSelect>
            {!!(touched && errors) && (
                <FormHelperText
                    classes={{
                        root: classes.errorText
                    }}
                >
                    {errors}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default Select;
