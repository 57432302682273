import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';

const useAppStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
        background: 'rgb(203, 211, 227)'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(10),
        position: 'relative',
        maxWidth: '500px',

        [theme.breakpoints.down('xl')]: {
            width: '55%'
        },
        [theme.breakpoints.down('xl')]: {
            width: '65%'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '375px',
            width: '90%'
        }
    },
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        marginTop: theme.spacing(5),

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        },
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(7)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '14px',
            padding: theme.spacing(3)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            padding: theme.spacing(0)
        }
    },
    formLoading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(255, 255, 255, 0.7)',
        zIndex: 5
    },
    oauthBlock: {
        marginTop: theme.spacing(5)
    },
    oauthBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
        padding: theme.spacing(1),
        borderRadius: 4,
        height: '3rem',
        transition: 'transform 200ms ease-in-out',

        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.05)'
        }
    },
    okBtn: {
        backgroundColor: '#F68634'
    },
    vkBtn: {
        backgroundColor: '#4680C2'
    },
    fbBtn: {
        backgroundColor: '#1877F2'
    }
}));

export default useAppStyles;
