import { List, ListItem, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CODE_TYPES, CODE_CATEGORY, promocodeTypes } from './Promocodes.schema';
import useApplicationsStyles from '../../pages/dashboardPage/sections/applicationsPage/styles';

const PromocodesTooltip = ({ promocode }) => {
    const classes = useApplicationsStyles();
    return (
        <Tooltip
            title={
                <span>
                    <Typography variant="h6">Промокод: {promocode.code}</Typography>
                    <List variant="h5">
                        <ListItem>Категория: {CODE_CATEGORY[promocode.category]}</ListItem>
                        <ListItem>Тип: {CODE_TYPES[promocode.code_type]}</ListItem>
                        <ListItem>Размер скидки: {promocode.discount || 'без скидки'}</ListItem>
                        <ListItem>
                            Действует с: {new Date(promocode.start_date).toLocaleDateString()}
                        </ListItem>
                        <ListItem>
                            Действует до: {new Date(promocode.end_date).toLocaleDateString()}
                        </ListItem>
                        {promocode.onetime ? (
                            <ListItem>Можно использовать только 1 раз</ListItem>
                        ) : (
                            <>
                                <ListItem>
                                    Максимальное количество применений:{' '}
                                    {promocode.max_use_number || 0}
                                </ListItem>
                                <ListItem>
                                    Количество применений: {promocode.number_of_uses || 0}
                                </ListItem>
                            </>
                        )}
                        {!!promocode.product && (
                            <ListItem>Можно применить для: {promocode.product}</ListItem>
                        )}
                    </List>
                </span>
            }
            placement="right"
            classes={{
                tooltip: classes.tooltip
            }}
        >
            <InfoIcon color="primary" fontSize="large" cursor="help" />
        </Tooltip>
    );
};

PromocodesTooltip.propTypes = {
    promocode: promocodeTypes
};

export default PromocodesTooltip;
