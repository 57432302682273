import { useEffect, useState } from 'react';

export default function useCreateToastControls(toastRef) {
    const [toastControls, setToastControls] = useState({});

    useEffect(() => {
        if (toastRef.current) {
            setToastControls({
                addInfoToast: (message, autoCloseTime) =>
                    toastRef.current.addMessage({
                        mode: 'info',
                        message,
                        autoCloseTime
                    }),
                addErrorToast: (message, autoCloseTime) =>
                    toastRef.current.addMessage({
                        mode: 'error',
                        message,
                        autoCloseTime
                    }),
                addSuccessToast: (message, autoCloseTime) =>
                    toastRef.current.addMessage({
                        mode: 'success',
                        message,
                        autoCloseTime
                    }),
                addWarningToast: (message, autoCloseTime) =>
                    toastRef.current.addMessage({
                        mode: 'warning',
                        message,
                        autoCloseTime
                    })
            });
        }

        /* eslint-disable-next-line */
    }, [toastRef.current]);

    return toastControls;
}
