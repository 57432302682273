import { Navigate, Outlet } from 'react-router-dom';

import config from '../../config';
import { useAdminDataStore } from '../../hooks/useStore';

function PrivateRoute() {
    const adminDataStore = useAdminDataStore();
    return adminDataStore.access ? <Outlet /> : <Navigate to={config.routes.HOME} />;
}

export default PrivateRoute;
