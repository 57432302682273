import { makeAutoObservable } from 'mobx';
import StageClass from './Classes/Stage.class';
import TournamentTableStoreApi from './tournament.api';

const DEFAULT_VALUES = {
    participants: null,
    stages: null
};

export default class TournamentTableStore {
    participantsLoading = true;

    constructor(rootStore, tournamentId) {
        this.rootStore = rootStore;
        this.tournamentId = tournamentId;

        Object.entries(DEFAULT_VALUES).forEach(([key, value]) => {
            this[key] = value;
        });

        this.api = new TournamentTableStoreApi({
            rootStore,
            tournamentId
        });

        this.initialLoad();

        makeAutoObservable(this);
    }

    get stagesList() {
        return this.stages.filter((stage) => stage.id);
    }

    loadParticipants = async () => {
        this.participantsLoading = true;

        const participants = await this.api.getTournamentPlayers();
        this.participants = participants.filter((participant) => participant.team === 'main');

        this.participantsLoading = false;
        return this.participants;
    };

    loadStages = async () => {
        const stagesData = await this.api.getTournamentStages();
        this.stages = stagesData.map((data) => new StageClass(this.api, data, this));
        return this.stages;
    };

    deleteStage = (id) => {
        this.stages = [...this.stages.filter((stage) => stage.id !== id)];
    };

    addStage = (stage) => {
        this.stages = [...this.stages, stage].sort((a, b) => a.id - b.id);
    };

    newStage = (stageData = {}) => {
        const newStage = new StageClass(this.api, stageData, this);
        this.addStage(newStage);
        return newStage;
    };

    initialLoad = async () => {
        await this.loadParticipants();
        this.loadStages();
    };

    // autoProcess = async () => {
    //     const autoProcess = await this.api.handleAutoProcessStage();
    //     if (autoProcess) {
    //         //
    //     }
    // };

    clearStore = () => {
        Object.entries(DEFAULT_VALUES).forEach(([key, value]) => {
            this.updateField(key, value);
        });
    };
}
