import { useState, useTransition } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { TextFieldProps } from '@mui/material';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export type TextProps = TextFieldProps & {
    withTransition?: boolean;
    showLimits?: boolean;
    touched?: boolean;
    errors?: any;
    customClass?: string[];
    maxLength?: number;
    // eslint-disable-next-line no-unused-vars
    handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    // eslint-disable-next-line no-unused-vars
    handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    startAdornmentIcon?: React.ReactNode;
};

const Text = ({
    touched,
    errors,
    label,
    name,
    type,
    handleChange,
    handleBlur,
    value,
    startAdornmentIcon,
    fullWidth = true,
    customClass,
    onChange,
    maxLength = 100,
    showLimits = false,
    withTransition = false,
    ...other
}: TextProps) => {
    const classes = useFormFieldStyles({ error: !!(touched && errors) });

    const [limitUsed, setLimitUsed] = useState((value as string | undefined)?.length);
    const [, startTransition] = useTransition();

    const onChangeAction = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLimitUsed(e?.target?.value?.length);

        if (handleChange) {
            handleChange(e);
        }

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <TextFieldStyled
            label={label}
            name={name}
            type={type}
            className={classNames(classes.formField, ...new Array(customClass))}
            InputProps={{
                className: classes.input,
                endAdornment: !!(touched && errors) && (
                    <InputAdornment position="end">
                        <CloseIcon color="primary" className={classes.errorIcon} />
                    </InputAdornment>
                ),
                startAdornment: startAdornmentIcon && (
                    <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
                ),
                inputProps: {
                    maxLength
                }
            }}
            InputLabelProps={{
                className: classes.input
            }}
            FormHelperTextProps={{
                className: classes.errorText
            }}
            defaultValue={withTransition ? value : undefined}
            value={withTransition ? undefined : value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                withTransition ? startTransition(() => onChangeAction(e)) : onChangeAction(e)
            }
            onBlur={handleBlur}
            error={!!(touched && errors)}
            helperText={
                <>
                    {showLimits && `${limitUsed}/${maxLength}`}
                    {!!(touched && errors) && (showLimits ? ` (${errors})` : errors)}
                </>
            }
            fullWidth={fullWidth}
            {...other}
        />
    );
};

export default Text;
