import PropTypes from 'prop-types';
import { Avatar as MuiAvatar } from '@mui/material';
import classNames from 'classnames';
import translit from 'translit-en';

import { useEffect, useRef } from 'react';
import { generateAvatarFromEmail, getPlayerFullName } from '../../utils/helpers';
import config from '../../config';

import useAvatarStyles from './styles';

const Avatar = ({ user, size }) => {
    const classes = useAvatarStyles();
    const rootRef = useRef(null);
    const { color, colorDarker } = generateAvatarFromEmail(
        user?.email || translit(user.name ? user.name : getPlayerFullName(user))
    );

    useEffect(() => {
        if (rootRef.current && user.role === 'player') {
            rootRef.current.href = config.references.getPlayerProfileLink(user.id);
            rootRef.current.target = '_blank';
        }
    }, []);

    return (
        <MuiAvatar
            src={user?.avatar || null}
            className={classNames(classes.root, {
                [classes.small]: size === 'small',
                [classes.medium]: size === 'medium',
                [classes.large]: size === 'large'
            })}
            style={{ background: `linear-gradient(135deg, ${color} 0%, ${colorDarker} 96.52%)` }}
            component="a"
            ref={rootRef}
        >
            {user?.first_name?.charAt(0)}
        </MuiAvatar>
    );
};

Avatar.propTypes = {
    user: PropTypes.object.isRequired,
    size: PropTypes.string
};

Avatar.defaultProps = {
    size: 'small'
};

export default Avatar;
