import { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import MultipleAutoComplete, { MultipleAutoCompleteProps } from './MultipleAutoComplete';

export interface AsyncMultipleAutoCompleteProps extends Omit<MultipleAutoCompleteProps, 'options'> {
    request: any;
}

const AsyncMultipleAutoComplete = ({
    // options,
    value,
    request,
    ...rest
}: AsyncMultipleAutoCompleteProps) => {
    const [asyncOptions, setAsyncOptions] = useState(value ? [value] : []);
    const [currentSearch, setCurrentSearch] = useState('');

    const loadOptions = (search: string) => request(null, null, { search });

    const getOptions = async (value: string) => {
        const { results } = await loadOptions(value);
        setCurrentSearch(value);
        setAsyncOptions(results);
    };

    const delayDebounceFn = useRef<NodeJS.Timeout | null>(null);
    const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setCurrentSearch(value);
        clearTimeout(delayDebounceFn.current as unknown as number);
        if (value !== currentSearch) {
            delayDebounceFn.current = setTimeout(async () => {
                getOptions(value);
            }, 500);
        }
    };

    useEffectOnce(() => {
        getOptions('');

        return () => clearTimeout(delayDebounceFn.current as unknown as number);
    });

    return (
        <MultipleAutoComplete value={value} options={asyncOptions} onInput={onInput} {...rest} />
    );
};

export default AsyncMultipleAutoComplete;
