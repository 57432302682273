import makeStyles from '@mui/styles/makeStyles';

const useAvatarStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        textDecoration: 'none'
    },
    small: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        fontSize: '0.9rem'
    },
    medium: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        fontSize: '1.2rem'
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        fontSize: '1.5rem'
    }
}));

export default useAvatarStyles;
