import { debounce, max, trim } from 'lodash';
import { v4 as uuid } from 'uuid';
import { makeAutoObservable } from 'mobx';
import differenceBetweenObjects from '../../../utils/helpers/differenceBetweenObjects';
import SetClass from './Set.class';

const SCHEMA = {
    id: null,
    is_active_live: false,
    is_technical_defeat: null,
    order: null,
    request_1: null,
    request_2: null,
    tournament_stage: null,
    tournament_substage: null,
    winner: null
};

const DEFAULT_VALUES = {
    ...SCHEMA
};

const validateInputFormat = (value) => {
    if (/^[0-9]+\/[0-9]+$/i.test(value)) {
        return 'set';
    }
    if (/^отк\.$/i.test(value)) {
        return 'deny';
    }
    if (/^поб\.$/i.test(value)) {
        return 'win';
    }
    return false;
};

const serializeMatchPayload = (payload) => {
    const serialized = { ...payload };

    if (payload.winner) {
        serialized.winner = payload.winner.id;
    }
    if (payload.request_1) {
        serialized.request_1 = payload.request_1.id;
    }
    if (payload.request_2) {
        serialized.request_2 = payload.request_2.id;
    }

    return serialized;
};

const mirrorValue = (value) => {
    switch (value.type) {
        case 'set':
            return trim(value.data).split('/').reverse().join('/');

        case 'deny':
            return 'поб.';

        case 'win':
            return 'отк.';

        default:
            return '';
    }
};

export default class MatchClass {
    sets = [];

    inputValue = '';

    fake_id = uuid();

    constructor(api, values = {}) {
        if (!values.id) {
            console.warn('Ошибка матча, нет id матча (constructor)');
        }
        this.api = api;

        const initialValues = { ...SCHEMA, ...values };
        this.initialValues = initialValues;

        Object.entries({ ...DEFAULT_VALUES, ...initialValues }).forEach(([key, value]) => {
            if (key === 'sets') {
                this.sets = value
                    .sort((a, b) => a.id - b.id)
                    .map((props) => new SetClass(this.api, this, props));
            } else {
                this[key] = value;
            }
        });

        makeAutoObservable(this);
    }

    uploadSets = async () => {
        try {
            // тут важен порядок, поэтому шлем последовательно
            // eslint-disable-next-line no-restricted-syntax
            for (const set of this.sets) {
                // eslint-disable-next-line no-await-in-loop
                await set.uploadSet();
            }
        } finally {
            this.updateInputValueBySets();
        }
    };

    assignWithPlayers = (request_1, request_2, winner) => {
        this.request_1 = request_1;
        this.request_2 = request_2;
        this.winner = winner;
        this.initialValues = { ...this.initialValues, request_1, request_2, winner };

        this.fixXMatches();
        this.updateInputValueBySets();
    };

    addSet = ({ p1_score, p2_score }) => {
        const newSet = new SetClass(this.api, this, { id: null, p1_score, p2_score });
        this.sets = [...this.sets, newSet];
    };

    updateInputValueBySets = () => {
        const bySets = this.sets.reduce(
            (acc, curr, index) => `${acc}${index > 0 ? ', ' : ''}${curr.p1_score}/${curr.p2_score}`,
            ''
        );

        let byTechnicalDefeat = '';

        if (this.is_technical_defeat) {
            if (this.request_1) {
                if (this.winner === this.request_1) {
                    byTechnicalDefeat = 'поб.';
                } else {
                    byTechnicalDefeat = 'отк.';
                }
            } else {
                byTechnicalDefeat = 'поб.';
            }
        }

        this.inputValue =
            bySets + (bySets.length && byTechnicalDefeat.length ? ', ' : '') + byTechnicalDefeat;
    };

    inputOnChange = (ev, value = '') => {
        this.inputValue = value || ev?.target.value || '';
        this.parseResults();
    };

    inputOnChangeMirror = (ev, value = '') => {
        this.inputValue = value || ev?.target.value || '';
        this.parseResults(true);
    };

    parseResults = (asMirror = false) => {
        if (this.isInputValid) {
            const value = this.parsedInput;

            const isTechnicalDefeat = !!value.find((el) => ['deny', 'win'].includes(el.type));
            this.is_technical_defeat = isTechnicalDefeat;

            for (let i = 0; i < max([value.length, this.sets.length]); i += 1) {
                // удаляем сет, если в распаршенной строке его нет
                if (!value[i] || !value[i].type) {
                    this.sets[i].deleteSet();
                } else {
                    switch (value[i].type) {
                        case 'set':
                            {
                                const [p1_score, p2_score] = value[i].data
                                    .split('/')
                                    .map((s) => +s);
                                const payload = asMirror
                                    ? { p1_score: p2_score, p2_score: p1_score }
                                    : { p1_score, p2_score };
                                if (this.sets[i]) {
                                    this.sets[i].updateValues(payload);
                                } else {
                                    this.addSet(payload);
                                }
                            }
                            break;
                        case 'win':
                            this.winner = asMirror ? this.request_2 : this.request_1;
                            break;
                        case 'deny':
                            this.winner = asMirror ? this.request_1 : this.request_2;
                            break;
                        default:
                            break;
                    }
                }
            }

            if (!isTechnicalDefeat) {
                const { p1_wins, p2_wins } = this.matchWins;
                if (p1_wins > p2_wins) {
                    this.winner = this.request_1;
                } else if (p1_wins < p2_wins) {
                    this.winner = this.request_2;
                } else {
                    this.winner = null;
                }
            }

            this.synkBackDebounced(value);
        }
    };

    synkBackDebounced = debounce(
        async (value) => {
            try {
                this.sets = this.sets.slice(0, value.length);
                await this.uploadSets();
                this.uploadMatch();
            } catch (error) {
                //
            }
        },
        300,
        {
            leading: false,
            trailing: true
        }
    );

    uploadMatch = debounce(
        async () => {
            if (this.id) {
                const payload = differenceBetweenObjects(this.getData, this.initialValues);
                if (!Object.keys(payload).length) {
                    return this.getData;
                }

                try {
                    await this.api.patchMatch({
                        matchId: this.id,
                        payload: serializeMatchPayload(payload)
                    });
                    this.initialValues = { ...this.initialValues, ...payload };
                    return;
                } catch (error) {
                    this.setData({ ...this.initialValues, sets: this.sets });
                    this.updateInputValueBySets();
                    throw error;
                }
            }
            console.warn('Ошибка матча, нет id матча (uploadMatch)');
            return Promise.reject();
        },
        300,
        { leading: false, trailing: true }
    );

    updateField = (key, value) => {
        this[key] = value;
        return this[key];
    };

    setData = (data) => {
        Object.entries(data).forEach(([key, value]) => this.updateField(key, value));
        return this.getData;
    };

    swapReqest = () => {
        const { request_1, request_2 } = this;

        this.setData({ request_2: request_1, request_1: request_2 });
        this.inputOnChange(null, this.mirrorInputValue);
        this.fixXMatches();
        this.updateInputValueBySets();
    };

    getMirroredData = (data) =>
        data.reduce(
            (prev, curr, index) => `${prev}${index > 0 ? ', ' : ''}${mirrorValue(curr)}`,
            ''
        );

    fixXMatches = () => {
        const { request_1, request_2, is_technical_defeat, winner } = this;
        if (
            (request_1 || request_2) &&
            !(request_1 && request_2) &&
            (!winner || !is_technical_defeat)
        ) {
            this.setData({ winner: request_1 || request_2, is_technical_defeat: true });
            this.uploadMatch();
        }
    };

    get matchWins() {
        const wins = this.sets.reduce(
            (acc, cur) => {
                acc.p1_wins += +(cur.p1_score > cur.p2_score);
                acc.p2_wins += +(cur.p1_score < cur.p2_score);
                return acc;
            },
            { p1_wins: 0, p2_wins: 0 }
        );

        return wins;
    }

    get matchScore() {
        const score = this.sets.reduce(
            (acc, cur) => {
                acc.p1_score += cur.p1_score;
                acc.p2_score += cur.p2_score;
                return acc;
            },
            { p1_score: 0, p2_score: 0 }
        );

        return score;
    }

    get mirrorInputValue() {
        const value = this.parsedInput;

        return this.getMirroredData(value);
    }

    get parsedInput() {
        const value = this.inputValue
            .split(',')
            .map((data) => ({ data, type: validateInputFormat(trim(data)) }))
            .filter((v) => !!v.type);

        return value;
    }

    get isInputValid() {
        if (this.inputValue === '') {
            return true;
        }
        const value = this.inputValue.split(',').map((v) => validateInputFormat(trim(v)));
        return !!value.reduce((prev, curr) => prev && curr);
    }

    get getData() {
        return Object.keys(SCHEMA).reduce((acc, curr) => {
            acc[curr] = this[curr];
            return acc;
        }, {});
    }
}
