import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';
import { indigo } from '@mui/material/colors';

const useApplicationsStyles = makeStyles((theme) => ({
    root: {
        height: 'max-content',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box'
    },
    applicationsStatusSelect: {
        margin: 0
    },
    closeButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: tinycolor(theme.palette.primary.main).lighten(45).toString(),
        marginLeft: theme.spacing(2),
        width: 30,
        height: 30,
        borderRadius: 5,

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(40).toString()
        }
    },
    editButton: {
        color: theme.palette.primary.main,
        backgroundColor: tinycolor(theme.palette.primary.main).lighten(45).toString(),
        marginLeft: theme.spacing(2),
        width: 30,
        height: 30,
        borderRadius: 5,

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(40).toString()
        }
    },
    emptyTable: {
        '&:hover': {
            background: 'transparent !important',
            cursor: 'default !important'
        }
    },
    pageContent: {
        width: '90%'
    },
    card: {
        padding: '1rem',
        backgroundColor: 'ghostwhite'
    },
    cardItem: {
        marginBottom: theme.spacing(3)
    },
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(5),
        fontSize: '0.9rem',
        fontWeight: 500,

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        }
    },
    tooltip: {
        fontSize: '0.6rem'
    }
}));

export default useApplicationsStyles;
