import { useMemo, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    useMediaQuery
} from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import { useBoolean } from 'usehooks-ts';
import { format } from 'date-fns';
import { first, isDate } from 'lodash';
import { DateRange } from 'react-date-range';

import { useAdminDataStore } from '../../hooks/useStore';
import useHomeStyles from '../../pages/dashboardPage/sections/homePage/styles';
import { useAxios } from '../../hooks';
import config from '../../config';
import downloadBlob from '../../utils/helpers/downloadBlob';
import Popup from '../Popup/Popup';

import styles from './TournamentsReport.module.scss';

const DEFAULT_RANGES = [{ startDate: null, endDate: null, key: 'dates' }];

const TournamentsReport = () => {
    const classes = useHomeStyles();
    const adminDataStore = useAdminDataStore();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xl'));

    const [ranges, setRanges] = useState(DEFAULT_RANGES);

    const params = useMemo(() => {
        const range = first(ranges);
        return {
            date_from: isDate(range?.startDate) ? format(range.startDate, 'yyyy-MM-dd') : null,
            date_to: isDate(range?.endDate) ? format(range.endDate, 'yyyy-MM-dd') : null
        };
    }, [ranges]);

    const { request: getReport } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.exportTournaments.uri}`
    });

    const getReportHandle = async (p) => {
        const doc = await getReport(null, null, p);
        downloadBlob(
            doc,
            `amatour-tournaments-${p.date_from ? `from-${p.date_from}` : ''}${
                p.date_to ? `-to-${p.date_to}` : ''
            }.csv`,
            'text/csv;charset=utf-8;'
        );
    };

    const {
        value: visible,
        setTrue: setVisibleTrue,
        setFalse: setVisibleFalse,
        setValue: setVisible
    } = useBoolean(false);

    const onOk = () => {
        getReportHandle(params);
        setRanges(DEFAULT_RANGES);
        setVisibleFalse();
    };

    const onCancel = () => {
        setRanges(DEFAULT_RANGES);
        setVisibleFalse();
    };

    if (!adminDataStore.isAdmin) {
        return null;
    }

    return (
        <Grid item lg={6} xs={12}>
            <Card className={classes.root} elevation={3} style={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Отчет по турнирам
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button className={classes.btn} onClick={setVisibleTrue}>
                        Скачать отчет в .csv
                    </Button>
                    <Popup visible={visible} setVisible={setVisible} title="Формирование отчета">
                        <div className={styles.popupGrid}>
                            <DateRange
                                ranges={ranges}
                                onChange={(r) => setRanges([r.dates])}
                                locale={ruLocale}
                                // scroll={{
                                //     enabled: true
                                // }}
                                showSelectionPreview
                                moveRangeOnFirstSelection={false}
                                months={isMobile ? 1 : 2}
                                direction="horizontal"
                                startDatePlaceholder="Дата с"
                                endDatePlaceholder="Дата до"
                            />
                            <div className={styles.popupGrid_row}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={onCancel}
                                >
                                    Отмена
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={onOk}
                                >
                                    Скачать
                                </Button>
                            </div>
                        </div>
                    </Popup>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default TournamentsReport;
