import { compact } from 'lodash';

export interface GetPlayerFullNameProps {
    last_name?: string;
    first_name?: string;
    middle_name?: string;
}

export default function getPlayerFullName(props: GetPlayerFullNameProps) {
    if (props && ('last_name' in props || 'first_name' in props || 'middle_name' in props)) {
        const { last_name, first_name, middle_name } = props;
        return compact([last_name, first_name, middle_name]).join(' ');
    }
    return '';
}
