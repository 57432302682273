import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export default function useToastPortal() {
    const [loaded, setLoaded] = useState(false);
    const [portalId] = useState(`toast-portal-${uuid()}`);

    useEffect(() => {
        const div = document.createElement('div');

        div.id = portalId;
        div.style = 'position: fixed; top: 10px; right: 10px; z-index: 1500;';
        document.body.prepend(div);

        setLoaded(true);

        return () => document.body.removeChild(div);
    }, [portalId]);

    return {
        loaded,
        portalId
    };
}
