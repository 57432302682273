import { useLayoutEffect, useState } from 'react';
import { useIsMounted } from 'usehooks-ts';
import format from 'date-fns/format';

import config from '../../config';
import { useAxios } from '../../hooks';

const getPayload = ({
    discount,
    code_type,
    start_date,
    end_date,
    onetime,
    category,
    product,
    max_use_number
}) => {
    let payload = {};

    switch (code_type) {
        case 'discount':
            payload = {
                category,
                code_type,
                start_date: format(start_date, 'yyyy-MM-dd'),
                end_date: format(end_date, 'yyyy-MM-dd'),
                onetime,
                discount
            };
            if (!onetime) {
                payload.max_use_number = max_use_number;
            }
            if (product?.id) {
                payload.product = product.id;
            }

            break;
        case 'referral':
            payload = {
                // category,
                code_type,
                start_date: format(start_date, 'yyyy-MM-dd'),
                end_date: format(end_date, 'yyyy-MM-dd'),
                onetime: true
            };
            break;

        default:
            break;
    }

    return payload;
};

const usePromocodesLogic = () => {
    const isMounted = useIsMounted();
    const [promocodes, setPromocodes] = useState([]);

    const { request: getPromocodes, loading: promocodesLoading } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.promocodes.uri}`
    });

    const { request: deletePromocode } = useAxios({
        method: 'delete'
    });

    const { request: postPromocode } = useAxios({
        method: 'post',
        url: `${config.server.serviceHost}${config.server.api.promocodes.uri}`
    });

    // TODO: будет работать только с рекапчей
    // const { request: checkPromocode } = useAxios({
    //     method: 'get',
    //     url: `${config.server.serviceHostPublic}${config.server.api.checkPromocodes.uri}`
    // });

    const getPromocodesHandler = (is_active) =>
        getPromocodes(null, null, [0, 1].includes(is_active) ? { is_active } : null);

    const getPromocodeHandler = (id) =>
        getPromocodes(
            null,
            null,
            null,
            `${config.server.serviceHost}${config.server.api.promocodes.uri}${id}/`
        );

    const deletePromocodeHandler = async (id) => {
        try {
            await deletePromocode(
                null,
                null,
                null,
                `${config.server.serviceHost}${config.server.api.promocodes.uri}${id}/`
            );
            const newPromocodes = [...promocodes];
            const disactivated = newPromocodes.find((promocode) => promocode.id === id);
            disactivated.is_active = false;
            if (isMounted) {
                setPromocodes(
                    newPromocodes
                        .sort((a, b) => b.id - a.id)
                        .sort((a, b) => b.is_active - a.is_active)
                );
            }
        } catch (error) {
            //
        }
    };

    const postPromocodeHandler = async (data) => {
        const payload = getPayload(data);
        const newPromocode = await postPromocode(payload);

        if (isMounted) {
            setPromocodes([newPromocode, ...promocodes]);
        }
    };

    useLayoutEffect(() => {
        const fetchData = async () => {
            const codes = await getPromocodesHandler();
            if (isMounted) {
                setPromocodes(
                    codes.sort((a, b) => b.id - a.id).sort((a, b) => b.is_active - a.is_active)
                );
            }
        };
        fetchData();
    }, []);

    return {
        getPromocodesHandler,
        promocodesLoading,
        promocodes,
        getPromocodeHandler,
        deletePromocodeHandler,
        postPromocodeHandler
    };
};

export default usePromocodesLogic;
