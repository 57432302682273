import { makeAutoObservable } from 'mobx';

export default class ReferencesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    sportCategories = [];

    tournamentCategories = [];

    courtCoverages = [];

    clubs = [];

    cities = [];

    regions = [];

    countries = [];

    getReference = (reference) => this[reference];

    setReference = (reference, data) => {
        this[reference] = data;
    };

    updateReferenceItem = (reference, id, value) => {
        const referenceItemIndex = this[reference].findIndex((item) => item.id === id);
        this[reference][referenceItemIndex] = value;
    };

    removeReferenceItem = (reference, id) => {
        this[reference] = this[reference].filter((item) => item.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        [
            'sportCategories',
            'tournamentCategories',
            'courtCoverages',
            'clubs',
            'cities',
            'regions',
            'countries'
        ].forEach((key) => {
            this[key] = [];
        });
        console.warn('ReferencesStore clear');
    };
}
