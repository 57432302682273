import compareAsc from 'date-fns/compareAsc';

const removeTime = (date) => {
    const clearedDate = new Date(date);
    clearedDate.setHours(0);
    clearedDate.setMinutes(0);
    clearedDate.setSeconds(0);
    clearedDate.setMilliseconds(0);
    return clearedDate;
};

export default class Calendar {
    static compareDates(date1, date2) {
        /*
         * -1 - первая меньше второй
         * 0 - равные даты
         * 1 - первая больше второй
         */

        try {
            const firstDate = removeTime(date1);
            const secondDate = removeTime(date2);

            return compareAsc(firstDate, secondDate);
        } catch (error) {
            return false;
        }
    }

    static compareTime(date1, date2) {
        /*
         * -1 - первая меньше второй
         * 0 - равные даты
         * 1 - первая больше второй
         */
        try {
            const [firstDateHours, firstDateMinutes] = [
                new Date(date1).getHours(),
                new Date(date1).getMinutes()
            ];
            const [secondDateHours, secondDateMinutes] = [
                new Date(date2).getHours(),
                new Date(date2).getMinutes()
            ];

            return compareAsc(
                new Date(date1).setHours(firstDateHours, firstDateMinutes),
                new Date(date2).setHours(secondDateHours, secondDateMinutes)
            );
        } catch (error) {
            return false;
        }
    }

    static getNewYear(currentYear, yearsAhead) {
        return new Date(currentYear.getFullYear() + yearsAhead, currentYear.getMonth(), 1);
    }
}
