import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import useStore from './useStore';

import { ToastContext } from '../utils/context';
import config from '../config';

const useOnError = () => {
    const rootStore = useStore();
    const navigate = useNavigate();
    const {
        adminDataStore,
        applicationsStore,
        tournamentsStore,
        usersStore,
        referencesStore,
        partnersStore,
        photoAlbumsStore,
        videosStore,
        publicationsStore,
        promotionsStore
    } = rootStore;

    const toastFunctions = useContext(ToastContext);

    const onUnauthorized = async (isRetry = false, request = () => {}, requestOptions = []) => {
        const stores = [
            adminDataStore,
            applicationsStore,
            tournamentsStore,
            usersStore,
            referencesStore,
            partnersStore,
            photoAlbumsStore,
            videosStore,
            publicationsStore,
            promotionsStore
        ];
        if (!isRetry) {
            const [payload, customHeaders, customParams, customUrl] = requestOptions;
            const data = adminDataStore.unauthorizedHandler(() =>
                request(payload, customHeaders, customParams, customUrl, true)
            );
            return data;
        }
        stores.forEach((store) => store.clearStore());
        navigate('/', { replace: true });
        return null;
    };

    const onError = (
        error,
        isRetry = false,
        request = null,
        requestOptions = [],
        displayErrors = true,
        unauthorized = false
    ) => {
        if ((error?.response?.status === 401 || unauthorized) && request) {
            return onUnauthorized(isRetry, request, requestOptions);
        }

        if (error?.response?.status === 404) {
            throw error;
        } else if (error?.response?.data?.detail && displayErrors) {
            const detail = error?.response?.data?.detail;

            toastFunctions?.addErrorToast(
                typeof detail === 'string' ? detail : JSON.stringify(error?.response?.data?.detail)
            );
        } else if (displayErrors) {
            toastFunctions?.addErrorToast(config.references.serverError);
        }

        throw error;
    };
    return { onError };
};

export default useOnError;
