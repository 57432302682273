import { CircularProgress, Modal } from '@mui/material';
import { useContext } from 'react';
import GlobalLoadingModalContext from './GlobalLoadingModal.context';

import styles from './GlobalLoadingModal.module.scss';

const GlobalLoadingModal = () => {
    // const [locked, setLocked] = useLockedBody();

    const { loading } = useContext(GlobalLoadingModalContext);

    // useEffect(() => {
    //     setLocked(loading);
    //     return () => setLocked(false);
    // }, [loading]);

    return (
        <Modal open={loading}>
            <div className={styles.globalLoadingModal}>
                <CircularProgress />
            </div>
        </Modal>
    );
};

GlobalLoadingModal.propTypes = {};

export default GlobalLoadingModal;
