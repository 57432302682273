import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';

const renderChip = (config) => {
    const StyledChip = withStyles((theme) => ({
        outlined: {
            width: config.width || 120,
            color: config.color,
            fontWeight: 600,
            letterSpacing: theme.spacing(0.1),
            border: `1px solid ${config.color}`,
            backgroundColor: 'transparent !important',
            cursor: 'pointer'
        },
        filled: {
            width: config.width || 120,
            color: 'black',
            fontWeight: 600,
            letterSpacing: theme.spacing(0.1),
            border: `1px solid ${config.color}`,
            backgroundColor: config.color,
            cursor: 'pointer'
        },
        icon: {
            color: config.variant === 'outlined' ? config.color : 'white'
        },
        deleteIcon: {
            color: config.variant === 'outlined' ? config.color : 'white'
        }
    }))(Chip);

    return <StyledChip {...{ ...config, color: 'primary' }} />;
};

export default renderChip;
