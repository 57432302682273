import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';
import { indigo, green } from '@mui/material/colors';

const useReferenceBlockStyles = makeStyles((theme) => ({
    root: {
        height: 420,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: theme.spacing(5)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        color: 'inherit',
        fontSize: '1.2rem',
        marginBottom: theme.spacing(2)
    },
    pos: {
        marginBottom: 12
    },
    list: {
        overflow: 'auto'
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '&:hover': {
            background: 'rgba(203, 211, 227, 0.2)',
            cursor: 'pointer'
        }
    },
    emptyListItem: {
        justifyContent: 'center',

        '&:hover': {
            background: 'none',
            cursor: 'default'
        }
    },
    listItemHeading: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    listItemText: {
        fontSize: '1rem'
    },
    listItemDescription: {
        fontSize: '0.6rem',
        opacity: 0.7
    },
    editButton: {
        minWidth: 35,
        width: 35,
        height: 30,
        color: theme.palette.primary.contrastText,
        backgroundColor: indigo[500],
        marginRight: theme.spacing(2),

        '&:hover': {
            backgroundColor: indigo[400]
        }
    },
    deleteButton: {
        minWidth: 35,
        width: 35,
        height: 30,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(8).toString()
        }
    },
    confirmButton: {
        minWidth: 35,
        width: 35,
        height: 30,
        color: theme.palette.primary.contrastText,
        backgroundColor: green[500],
        marginRight: theme.spacing(2),

        '&:hover': {
            backgroundColor: green[400]
        }
    },
    inlineConfirmButton: {
        marginLeft: theme.spacing(2)
    },
    icon: {
        fontSize: 15
    },
    form: {
        flex: 1,
        marginRight: theme.spacing(5)
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    addBtn: {
        background: indigo[500],
        color: theme.palette.primary.contrastText,

        '&:hover': {
            background: indigo[400]
        }
    },
    inlineForm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    inlineInput: {
        marginTop: 0
    }
}));

export default useReferenceBlockStyles;
