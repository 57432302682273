import axios from 'axios';
import config from '../../config';
// import storageHelper from '../../utils/helpers/storage.helper';

const { serviceHost, api } = config.server;

class TournamentTableStoreApi {
    onError = () => {};

    constructor({ rootStore, tournamentId }) {
        this.rootStore = rootStore;
        // this.token = token;
        this.tournamentId = tournamentId;
    }

    setOnError = (handler) => {
        this.onError = handler;
    };

    #fetchConfig = (url, method, params, payload) => {
        const config = {
            url,
            method,
            params,
            data: payload,
            headers: {
                ...(this.rootStore.adminDataStore.access && {
                    Authorization: `Bearer ${this.rootStore.adminDataStore.access}`
                }),
                ...(this.rootStore.adminDataStore.token && {
                    'X-Requested-With': this.rootStore.adminDataStore.token
                })
            }
        };
        return config;
    };

    #fetchData = async ({ url, method = 'get', params = {}, payload = {} }) => {
        try {
            const { data } = await axios.request(this.#fetchConfig(url, method, params, payload));
            return data;
        } catch (error) {
            // TODO: рефрешит, но не подхватывается сразу
            if (error?.response?.status === 401) {
                return this.onError(
                    error,
                    false,
                    axios.request,
                    [this.#fetchConfig(url, method, params, payload)],
                    false,
                    true
                );
            }
            this.onError(error);
            // throw error;
        }
        return null;
    };

    getTournamentPlayers = () =>
        this.#fetchData({
            url: `${serviceHost}${api.tournamentPlayers(this.tournamentId)}`
        });

    getTournamentStages = () =>
        this.#fetchData({
            url: `${serviceHost}${api.stage.uri}`,
            params: { tournament_id: this.tournamentId }
        });

    getTournamentStage = (stageId) =>
        this.#fetchData({
            url: `${serviceHost}${api.stage.uri}`,
            params: { stage_id: stageId }
        });

    createTournamentStage = ({
        has_third_place_match,
        name,
        players,
        rating_stage,
        stage_category,
        stage_type,
        tournament
    }) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.stage.uri}`,
            payload: {
                has_third_place_match,
                name,
                players,
                rating_stage,
                stage_category,
                stage_type,
                tournament
            }
        });

    patchStage = ({ stageId, payload }) =>
        this.#fetchData({
            method: 'patch',
            url: `${serviceHost}${api.stage.uri}${stageId}/`,
            payload
        });

    deleteStage = (stageId) =>
        this.#fetchData({
            method: 'delete',
            url: `${serviceHost}${api.stage.uri}${stageId}/`
        });

    getStageSubstages = (stageId) =>
        this.#fetchData({
            url: `${serviceHost}${api.stageSubstages(stageId)}`
        });

    postStageSubstage = ({ stageId, payload }) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.stageSubstages(stageId)}`,
            payload
        });

    getStageSubstage = ({ stageId, substageId }) =>
        this.#fetchData({
            url: `${serviceHost}${api.stageSubstages(stageId)}${substageId}/`
        });

    deleteStageSubstage = ({ stageId, substageId }) =>
        this.#fetchData({
            method: 'delete',
            url: `${serviceHost}${api.stageSubstages(stageId)}${substageId}/`
        });

    patchStageSubstage = ({ stageId, substageId, payload }) =>
        this.#fetchData({
            method: 'patch',
            url: `${serviceHost}${api.stageSubstages(stageId)}${substageId}/`,
            payload
        });

    getMatches = () =>
        this.#fetchData({
            url: `${serviceHost}${api.matches.uri}`,
            params: {
                tournament: this.tournamentId
            }
        });

    getSubstageMatches = ({ substageId }) =>
        this.#fetchData({
            url: `${serviceHost}${api.matches.uri}`,
            params: { substage: substageId }
        });

    getPlaces = ({ params }) =>
        this.#fetchData({
            url: `${serviceHost}${api.placesInfo.uri}`,
            params
        });

    getPlace = (placeId) =>
        this.#fetchData({
            url: `${serviceHost}${api.placesInfo.uri}${placeId}/`
        });

    putPlace = ({ placeId, payload }) =>
        this.#fetchData({
            method: 'put',
            url: `${serviceHost}${api.placesInfo.uri}${placeId}/`,
            payload
        });

    // TODO: проверить params: { tournament: this.tournamentId }

    createMatch = ({ payload }) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.matches.uri}`,
            params: { tournament: this.tournamentId },
            payload
        });

    patchMatch = ({ matchId, payload }) =>
        this.#fetchData({
            method: 'patch',
            url: `${serviceHost}${api.matches.uri}${matchId}/`,
            payload
        });

    deleteMatch = (matchId) =>
        this.#fetchData({
            method: 'delete',
            url: `${serviceHost}${api.matches.uri}${matchId}/`
        });

    postSet = (matchId, p1_score, p2_score) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.matchSets(matchId)}`,
            payload: { p1_score, p2_score } // TODO: c бека уберем match
        });

    patchSet = (matchId, setId, payload) =>
        this.#fetchData({
            method: 'patch',
            url: `${serviceHost}${api.matchSets(matchId)}${setId}/`,
            payload
        });

    deleteSet = (matchId, setId) =>
        this.#fetchData({
            method: 'delete',
            url: `${serviceHost}${api.matchSets(matchId)}${setId}/`
        });

    handleAutoProcessTournament = () =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.autoProcessTournament(this.tournamentId)}`
        });

    handleAutoProcessStage = (stageId, payload = {}) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.autoProcessStage(this.tournamentId, stageId)}`,
            payload
        });

    handleDeterminePlaces = (stageId) =>
        this.#fetchData({
            method: 'post',
            url: `${serviceHost}${api.determineStagePlaces(this.tournamentId, stageId)}`
        });
}

export default TournamentTableStoreApi;
