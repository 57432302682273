import { useState } from 'react';
import PropTypes from 'prop-types';
import { useUpdateEffect } from 'usehooks-ts';
import { noop } from 'lodash';

import { Button } from '@mui/material';

import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const ButtonWithConfirm = ({
    children,
    onClick,
    confirmationProps,
    swapPreferredActions,
    onOpen,
    ...rest
}) => {
    const [confirmVisible, setConfirmVisible] = useState(false);

    useUpdateEffect(() => {
        if (confirmVisible) {
            onOpen();
        }
    }, [confirmVisible]);

    return (
        <>
            <Button {...rest} onClick={() => setConfirmVisible(true)}>
                {children}
            </Button>
            {confirmVisible && (
                <ConfirmationDialog
                    onOk={() => {
                        setConfirmVisible(false);
                        onClick();
                    }}
                    open={confirmVisible}
                    handleClose={() => {
                        setConfirmVisible(false);
                    }}
                    swapPreferredActions={swapPreferredActions}
                    {...confirmationProps}
                />
            )}
        </>
    );
};

ButtonWithConfirm.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    confirmationProps: PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        maxWidth: PropTypes.string
    }),
    onClick: PropTypes.func.isRequired,
    swapPreferredActions: PropTypes.bool,
    onOpen: PropTypes.func
};

ButtonWithConfirm.defaultProps = {
    swapPreferredActions: false,
    onOpen: noop
};

export default ButtonWithConfirm;
