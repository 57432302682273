const STORAGES = { local: localStorage, session: sessionStorage };
class StorageHelperClass {
    save = (key, value, storage = 'local', prefix = 'am') => {
        if (value) {
            STORAGES[storage].setItem(`${prefix}_${key}`, JSON.stringify(value));
        } else {
            STORAGES[storage].removeItem(`${prefix}_${key}`);
        }
    };

    get = (key, storage = 'local', prefix = 'am') =>
        JSON.parse(STORAGES[storage].getItem(`${prefix}_${key}`));
}
export default new StorageHelperClass();
