import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';
import { indigo } from '@mui/material/colors';

const useReferencesFormsStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        marginTop: theme.spacing(4),
        padding: theme.spacing(6, 5),
        fontSize: '0.9rem',

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        }
    },
    colorBlock: {
        marginTop: theme.spacing(2)
    },
    colorTitle: {
        marginBottom: theme.spacing(2)
    },
    deleteButton: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(8).toString()
        }
    },
    pointsTable: {
        marginTop: theme.spacing(2)
    },
    addPointsRow: {
        background: indigo[50],

        '&:hover': {
            background: tinycolor(indigo[50]).darken(2),
            cursor: 'pointer'
        }
    },
    addPointsButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem'
    }
}));

export default useReferencesFormsStyles;
