import { isEqual } from 'lodash';

export default function shouldFastFieldRerender(nextProps, prevProps, getIn) {
    return (
        nextProps.name !== prevProps.name ||
        getIn(nextProps.formik.values, prevProps.name) !==
            getIn(prevProps.formik.values, prevProps.name) ||
        getIn(nextProps.formik.errors, prevProps.name) !==
            getIn(prevProps.formik.errors, prevProps.name) ||
        getIn(nextProps.formik.touched, prevProps.name) !==
            getIn(prevProps.formik.touched, prevProps.name) ||
        Object?.keys(prevProps)?.length !== Object?.keys(nextProps)?.length ||
        nextProps.formik.isSubmitting !== prevProps.formik.isSubmitting ||
        nextProps?.options?.length !== prevProps?.options?.length ||
        !isEqual(nextProps?.options, prevProps?.options) ||
        nextProps?.optionsLoading !== prevProps?.optionsLoading
    );
}
