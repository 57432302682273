import { useContext } from 'react';
import { isEqual } from 'lodash';
import format from 'date-fns/format';
// import Cookies from 'js-cookie';

import config from '../../config';
import isValidDate from '../../utils/helpers/isValidDate';
import { useUsersStore } from '../../hooks/useStore';
// import UserValidationSchema from './validationSchema';
import { useAxios } from '../../hooks';
import { ToastContext } from '../../utils/context';

const useLogic = ({ userToEdit, setVisible }) => {
    const usersStore = useUsersStore();

    const { addSuccessToast } = useContext(ToastContext);

    const { request: patchUser } = useAxios({
        method: 'patch',
        url: `${config.server.serviceHost}${config.server.api.allUsers.uri}${userToEdit?.id}/`
    });
    const { request: loadUser } = useAxios({
        url: `${config.server.serviceHost}${config.server.api.allUsers.uri}${userToEdit?.id}/`
    });

    const submitEditForm = async (newUserData, originalUserData) => {
        const updatedFields = {};

        if (!newUserData || !originalUserData) {
            return;
        }

        Object.keys(newUserData).forEach((key) => {
            if (!isEqual(newUserData[key], originalUserData[key])) {
                updatedFields[key] = newUserData[key];
            }
        });

        const updatedUserData = {};

        Object.keys(updatedFields).forEach((key) => {
            if (typeof updatedFields[key] === 'object' && !isValidDate(updatedFields[key])) {
                updatedUserData[key] = updatedFields[key]?.id ?? null;
            } else if (Array.isArray(updatedFields[key])) {
                updatedUserData[key] = updatedFields[key].map((item) => item?.id ?? null);
            } else if (key === 'birthdate') {
                updatedUserData[key] = format(updatedFields[key], 'yyyy-MM-dd');
            } else if (key === 'phone') {
                updatedUserData[key] = updatedFields[key]?.replace(/\s|\(|\)|\+|-/gi, '') ?? '';
            } else {
                updatedUserData[key] = updatedFields[key];
            }
        });

        if (Object.keys(updatedUserData).length) {
            try {
                await patchUser(updatedUserData);

                const updatedUser = await loadUser();

                if (updatedUser) {
                    usersStore.updateUser(updatedUser.id, updatedUser);
                }
            } catch (err) {
                console.error(err);
            }
        }

        setVisible(false);
        addSuccessToast('Пользователь был успешно отредактирован!');
    };

    return {
        submitEditForm
    };
};

export default useLogic;
