import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { TextFieldProps } from '@mui/material';
import config from '../../config';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export type NumberProps = TextFieldProps & {
    min?: number;
    max?: number;
    startAdornmentIcon?: React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // eslint-disable-next-line no-unused-vars
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    touched?: boolean;
    errors?: any[];
};

const Number = ({
    touched,
    errors,
    label,
    name,
    handleChange,
    handleBlur,
    value,
    startAdornmentIcon,
    fullWidth = true,
    min = 0,
    max,
    ...other
}: NumberProps) => {
    const classes = useFormFieldStyles({
        error: !!touched && !!errors
    });

    const onPaste = (e: React.ClipboardEvent) => e.preventDefault();

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.charCode && [44, 45, 46].indexOf(e.charCode) !== -1) {
            e.preventDefault();
            return false;
        }
    };

    const onWheel = (e: React.WheelEvent) => (e.target as HTMLInputElement).blur();

    return (
        <TextFieldStyled
            label={label}
            name={name}
            type="number"
            className={classes.formField}
            InputProps={{
                inputProps: {
                    min,
                    max: max || config.references.maxIntValue,
                    maxLength: 9
                },
                className: classes.input,
                endAdornment: !!(touched && errors) && (
                    <InputAdornment position="end">
                        <CloseIcon color="primary" />
                    </InputAdornment>
                ),
                startAdornment: startAdornmentIcon && (
                    <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
                )
            }}
            InputLabelProps={{
                className: classes.input
            }}
            FormHelperTextProps={{
                className: classes.errorText
            }}
            value={value}
            onChange={handleChange}
            onPaste={onPaste}
            onKeyPress={onKeyPress}
            onBlur={handleBlur}
            error={!!(touched && errors)}
            helperText={touched && errors}
            fullWidth={fullWidth}
            onWheel={onWheel}
            {...other}
        />
    );
};

export default Number;
