import { useMemo, useRef, useState } from 'react';
import { compact, debounce, isArray, isEqual, uniqWith } from 'lodash';
import AutoComplete, { AutoCompleteProps } from './AutoComplete';

export interface AsyncAutoCompleteProps extends Omit<AutoCompleteProps, 'options'> {
    request: Function;
    customRequestParams?: any;
    searchKey?: string;
}

const AsyncAutoComplete = ({
    // options,
    value,
    request,
    customRequestParams = {},
    searchKey = 'search',
    ...rest
}: AsyncAutoCompleteProps) => {
    const [asyncOptions, setAsyncOptions] = useState(value ? [value] : []);

    const currentSearch = useRef('');

    const loadOptions = (search: string) =>
        request(null, null, { ...customRequestParams, [searchKey]: search || null });

    const getOptions = async (search: string) => {
        const data = await loadOptions(search);
        currentSearch.current = search;
        setAsyncOptions(
            uniqWith(
                compact([
                    ...(isArray(value) ? value : [value]),
                    ...('results' in data ? data.results : data)
                ]),
                (obj, oth) => ('id' in obj && 'id' in oth ? obj.id === oth.id : isEqual(obj, oth))
            )
        );
    };

    const onInput = useMemo(
        () =>
            debounce(
                (e) => {
                    const { value } = e.target;
                    if (value !== currentSearch.current) {
                        getOptions(value);
                    }
                },
                500,
                { trailing: true, leading: true }
            ),
        []
    );

    return (
        <AutoComplete
            value={value}
            options={asyncOptions}
            onInput={onInput}
            onOpen={() => getOptions('')}
            {...rest}
        />
    );
};

export default AsyncAutoComplete;
