import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import parse from 'date-fns/parse';

import config from '../../config';
import FormField from '../FormField';
import Popup from '../Popup/Popup';
import useLogic from './useLogic';
import { useAxios } from '../../hooks';
import validationSchema from './validationSchema';
import ButtonWithConfirm from '../ButtonWithConfirm/ButtonWithConfirm';

import useStyles from './styles';

const EditUserForm = ({ userToEdit, visible, setVisible, closeEditUser }) => {
    const classes = useStyles();
    const { submitEditForm } = useLogic({ userToEdit, setVisible });
    const [disableValidation, setDisableValidation] = useState(false);
    const {
        birthdate,
        email,
        first_name,
        last_name,
        middle_name,
        phone,
        role,
        status,
        staff_phone,
        staff_email,
        sex,
        city,
        is_phone_confirm,
        ntrp
    } = userToEdit;

    const initialValues = useMemo(
        () => ({
            birthdate: birthdate ? parse(birthdate, 'yyyy-MM-dd', new Date()) : '',
            email: email || '',
            first_name: first_name || '',
            last_name: last_name || '',
            middle_name: middle_name || '',
            role: role || '',
            status: status || '',
            sex: sex || '',
            city: city || null,
            phone: phone || '',
            staff_phone: staff_phone || '',
            staff_email: staff_email || '',
            is_phone_confirm: is_phone_confirm || false,
            ntrp: ntrp || ''
        }),
        [userToEdit]
    );

    const { request: getCities } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.cities.uri}`
    });

    const confirmPhone = () => {
        submitEditForm({ is_phone_confirm: true }, { is_phone_confirm });
    };

    return (
        <Popup
            setVisible={setVisible}
            title="Редактирование данных пользователя"
            visible={visible}
            onClose={closeEditUser}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => submitEditForm(values, initialValues)}
                validationSchema={disableValidation ? undefined : validationSchema}
                enableReinitialize
                validateOnChange
                validateOnMount
                validateOnBlur
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    isValid,
                    setFieldValue,
                    validateForm,
                    setFieldTouched
                }) => (
                    <Form autoComplete="off">
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={disableValidation}
                                            onChange={(e) => {
                                                setDisableValidation(e.target.checked);
                                                setTimeout(validateForm);
                                            }}
                                            name="disableValidation"
                                            color="primary"
                                        />
                                    }
                                    label="Отключить проверки"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <FormField.Text
                                    label="Фамилия"
                                    name="last_name"
                                    type="text"
                                    value={values.last_name}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched
                                    errors={errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Text
                                    label="Имя"
                                    name="first_name"
                                    type="text"
                                    value={values.first_name}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched
                                    errors={errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Text
                                    label="Отчество"
                                    name="middle_name"
                                    type="text"
                                    value={values.middle_name}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched
                                    errors={errors.middle_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.RadioGroup
                                    name="sex"
                                    handleChange={handleChange}
                                    value={values.sex}
                                    touched
                                    errors={errors.sex}
                                    setTouched={setFieldTouched}
                                    options={[
                                        { id: 'male', name: 'Мужской' },
                                        { id: 'female', name: 'Женский' }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Select
                                    label="Роль"
                                    name="role"
                                    handleChange={handleChange}
                                    options={config.references.roleOptions}
                                    value={values.role}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.AsyncAutoComplete
                                    label="Город"
                                    name="city"
                                    keysToLabel={['region']}
                                    value={values.city}
                                    setFieldValue={setFieldValue}
                                    setTouched={setFieldTouched}
                                    touched
                                    errors={errors.city}
                                    // options={cities}
                                    request={getCities}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Select
                                    label="Статус"
                                    name="status"
                                    touched
                                    errors={errors.status}
                                    handleChange={handleChange}
                                    options={config.references.statusOptions}
                                    value={values.status}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Select
                                    label="Рейтинг NTRP"
                                    name="ntrp"
                                    touched
                                    errors={errors.ntrp}
                                    handleChange={handleChange}
                                    options={config.references.ntrp}
                                    value={values.ntrp}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Text
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched
                                    errors={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Text
                                    label="Staff e-mail"
                                    name="staff_email"
                                    type="email"
                                    value={values.staff_email}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched
                                    disabled={!['director', 'admin'].includes(values.role)}
                                    errors={errors.staff_email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Phone
                                    label="Телефон"
                                    name="phone"
                                    value={values.phone}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    touched
                                    errors={errors.phone}
                                />
                                {values.is_phone_confirm
                                    ? '*Телефон подтвержден'
                                    : '*Номер требует подтверждения'}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Phone
                                    label="Staff телефон"
                                    name="staff_phone"
                                    value={values.staff_phone}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    touched
                                    disabled={!['director', 'admin'].includes(values.role)}
                                    errors={errors.staff_phone}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField.Date
                                    label="Дата рождения"
                                    name="birthdate"
                                    value={values.birthdate}
                                    touched
                                    errors={errors.birthdate}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid container spacing={5} item xs={12}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={!isValid}
                                        className={classes.btn}
                                        fullWidth
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                {is_phone_confirm ? null : (
                                    <Grid item xs={12} md={6}>
                                        <ButtonWithConfirm
                                            variant="outlined"
                                            disabled={!isValid}
                                            className={classes.btn}
                                            onClick={confirmPhone}
                                            fullWidth
                                            confirmationProps={{
                                                title: 'Подтвердить номер телефона',
                                                text: (
                                                    <span style={{ color: 'red' }}>
                                                        Внимание, это действие отменить нельзя!
                                                        <br />
                                                        Перед подтверждением убедитесь, что
                                                        указанный номер действительно принадлежит
                                                        этому пользователю и заполнен правильно
                                                    </span>
                                                )
                                            }}
                                        >
                                            Подтвердить номер телефона
                                        </ButtonWithConfirm>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Popup>
    );
};

EditUserForm.propTypes = {
    userToEdit: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
    closeEditUser: PropTypes.func.isRequired
};

export default EditUserForm;
