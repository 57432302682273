import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';

const useHomeStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    pos: {
        marginBottom: 12
    },
    list: {
        fontSize: '1rem'
    },
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontSize: 12,
        padding: theme.spacing(2),

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        }
    }
}));

export default useHomeStyles;
