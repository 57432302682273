export default function isImageDimensionsValid(file, width, height) {
    return new Promise((resolve) => {
        const image = new Image();

        image.addEventListener('load', () => {
            let isValid = true;

            if (width || height) {
                isValid = !(image.width < width || image.height < height);
            }

            resolve(isValid);
        });

        image.src = URL.createObjectURL(file);
    });
}
