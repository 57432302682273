import makeStyles from '@mui/styles/makeStyles';

const useListItemLinkStyles = makeStyles((theme) => ({
    listItemText: {
        fontSize: '1rem'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    icon: {
        minWidth: 35
    },
    bage: {
        '& .MuiBadge-badge': {
            right: -3,
            top: 0,
            border: `1px solid white`,
            padding: '0 4px',
            minWidth: '18px',
            width: '18px',
            height: '18px'
        },
        '& .MuiSvgIcon-root': {
            transform: 'scale(1.3)'
        }
    }
}));

export default useListItemLinkStyles;
