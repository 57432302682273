import makeStyles from '@mui/styles/makeStyles';

const useToastPortalStyles = makeStyles((theme) => ({
    toastContainer: {
        position: 'fixed',
        top: theme.spacing(2),
        right: theme.spacing(5),
        gap: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column'
    },
    toast: {
        position: 'static'
    }
}));

export default useToastPortalStyles;
