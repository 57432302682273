import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-invisible-recaptcha';
import config from '../../config';
import RecaptchaContext from '../../utils/context/RecaptchaContext';

// const IS_DEV = process.env.REACT_APP_ENV === 'dev';

// eslint-disable-next-line react/display-name
const RecaptchaRoot = forwardRef(({ children }, ref) => {
    const [loading, setLoading] = useState(false);
    const [resolving, setResolving] = useState(false);
    // const [expired, setExpired] = useState(false);
    const [error, setError] = useState(true);
    // const [storedToken, setStoredToken] = useState(null);

    const onResolve = useCallback(() => {}, []);
    const onError = useCallback((er) => {
        setError(er);
    }, []);
    const onExpired = useCallback(() => {
        // setExpired(true);
    }, []);
    const onLoaded = useCallback(() => {
        setLoading(false);
    }, []);

    const execute = useCallback(async () => {
        // if (IS_DEV) {
        //     return 'FAKE_RECAPTCHA_TOKEN';
        // }
        setResolving(true);
        try {
            const token = await ref.current.execute();
            // setStoredToken(token);
            setResolving(false);
            return token;
        } catch (error) {
            setError(error);
            setResolving(false);

            const promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve(execute());
                }, 300);
            });

            return promise;
        }
    }, [ref]);

    const getToken = useCallback(async () => {
        // if (storedToken) {
        //     setStoredToken(null);
        //     execute();
        //     return storedToken;
        // }
        const token = await execute();
        // execute();
        return token;
    }, [execute]);

    // useEffect(() => {
    //     if (!loading || expired) {
    //         execute();
    //         setExpired(false);
    //     }
    // }, [loading, expired]);

    return (
        <>
            {/* {!IS_DEV && ( */}
            <Recaptcha
                ref={ref}
                sitekey={config.references.captcha}
                style={{
                    visibility: 'hidden',
                    display: 'none'
                }}
                onResolve={onResolve}
                onError={onError}
                onExpired={onExpired}
                onLoaded={onLoaded}
            />
            {/* )} */}
            <RecaptchaContext.Provider value={{ getToken, loading, error, resolving }}>
                {children}
            </RecaptchaContext.Provider>
        </>
    );
});

RecaptchaRoot.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default RecaptchaRoot;
