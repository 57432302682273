import { createContext } from 'react';
import PropTypes from 'prop-types';

import { rootStore } from '../../stores/rootStore';

export const StoreContext = createContext(rootStore);
StoreContext.displayName = 'StoreContext';

export const StoreProvider = ({ children }) => (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
);

StoreProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
