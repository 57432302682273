import { isDate, isMatch, isValid } from 'date-fns';
import { isNaN } from 'lodash';
import Calendar from './Calendar';
import config from '../../config';
import isValidDate from './isValidDate';

export default class Validator {
    static isValidDate = (date) => isValidDate(date);

    static isValidTime = (time) => isMatch(time, 'HH:mm');

    static validateForm = (values) => {
        const errors = {};

        this.performValidation({ values, errors });

        return errors;
    };

    static performValidation = ({ values, errors }) => {
        const rules = {
            email: (errors, value) => {
                if (!value) {
                    errors.email = 'Необходимо указать email адрес';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
                    errors.email = 'Некорректный email адрес';
                }
            },
            staff_email: (errors, value) => {
                if (!value) {
                    errors.staff_email = 'Необходимо указать email адрес (STAFF)';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
                    errors.staff_email = 'Некорректный email адрес';
                }
            },
            password: (errors, value) => {
                if (!value) {
                    errors.password = 'Необходимо указать пароль';
                } else if (value.length < 8) {
                    errors.password = 'Минимальная длина пароля - 8 символов';
                }
            },
            first_name: (errors, value) => {
                if (!value) {
                    errors.first_name = 'Необходимо указать имя';
                }
            },
            middle_name: (errors, value) => {
                if (!value) {
                    errors.middle_name = 'Необходимо указать отчество';
                }
            },
            last_name: (errors, value) => {
                if (!value) {
                    errors.last_name = 'Необходимо указать фамилию';
                }
            },
            phone: (errors, value) => {
                if (!value?.length) {
                    errors.phone = 'Необходимо указать номер телефона';
                } else if (value?.length !== 18) {
                    errors.phone = 'Указан некорректный номер телефона';
                }
            },
            staff_phone: (errors, value) => {
                if (!value?.length) {
                    errors.staff_phone = 'Необходимо указать номер телефона (STAFF)';
                } else if (value?.length !== 18) {
                    errors.staff_phone = 'Указан некорректный номер телефона';
                }
            },
            birthdate: (errors, value) => {
                if (!value) {
                    errors.birthdate = 'Необходимо указать дату рождения';
                } else if (!this.isValidDate(value)) {
                    errors.birthdate = 'Некорректный формат даты';
                } else if (value.getDate() > new Date().getDate()) {
                    errors.birthdate = 'Нельзя родиться в будущем 🙃️';
                } else if (value < new Date('1900-01-01')) {
                    errors.birthdate = 'Кое кто не плохо сохранился, но вампиров не существует 🤪️';
                }
            },
            city: (errors, value) => {
                if (!value) {
                    errors.city = 'Необходимо указать город';
                }
            },
            director: (errors, value) => {
                if (!value) {
                    errors.director = 'Необходимо указать директора';
                }
            },
            name: (errors, value) => {
                if (!value) {
                    errors.name = 'Необходимо указать полное название турнира';
                }
            },
            address: (errors, value) => {
                if (!value) {
                    errors.address = 'Необходимо указать адрес места проведения';
                }
            },
            start_date: (errors, value) => {
                if (!value) {
                    errors.start_date = 'Необходимо указать дату начала';
                } else if (!this.isValidDate(value)) {
                    errors.start_date = 'Некорректный формат даты';
                } else if (Calendar.compareDates(value, new Date()) < 0) {
                    errors.start_date = 'Турнир не может начаться в прошлом ☹️';
                }

                if (values.end_date && Calendar.compareDates(values.end_date, value) < 0) {
                    errors.end_date = 'Турнир не может закончиться раньше, чем начался ☹️';
                }
            },
            end_date: (errors, value) => {
                if (!value) {
                    errors.end_date = 'Необходимо указать дату завершения';
                } else if (!this.isValidDate(value)) {
                    errors.end_date = 'Некорректный формат даты';
                } else if (!values.start_date && Calendar.compareDates(value, new Date()) < 0) {
                    errors.end_date = 'Турнир не может закончиться в прошлом ☹️';
                } else if (Calendar.compareDates(value, values?.start_date) < 0) {
                    errors.end_date = 'Турнир не может закончиться раньше, чем начался ☹️';
                }
            },
            start_time: (errors, value) => {
                if (!value) {
                    errors.start_time = 'Необходимо указать время начала';
                } else if (!this.isValidTime(value)) {
                    errors.start_time = 'Некорректный формат времени';
                }

                if (Calendar.compareDates(values.start_date, new Date()) === 0) {
                    if (Calendar.compareTime(value, new Date()) < 0) {
                        errors.start_time = 'Турнир не может начаться в прошлом ☹️';
                    }
                }
            },
            end_time: (errors, value) => {
                if (!this.isValidTime(value)) {
                    errors.end_time = 'Некорректный формат времени';
                }

                if (Calendar.compareDates(values.start_date, values.end_date) === 0) {
                    if (Calendar.compareTime(value, values.start_time) < 0) {
                        errors.end_time = 'Турнир не может закончиться раньше, чем начался ☹️';
                    }
                }
            },
            amount: (errors, value) => {
                if (typeof value !== 'number') {
                    errors.amount = 'Необходимо указать размер взноса';
                } else if (value < 0) {
                    errors.amount = 'Размер взноса должен быть больше нуля';
                }
            },
            category: (errors, value) => {
                if (!value) {
                    errors.category = 'Необходимо указать категорию';
                }
            },
            court_coverage: (errors, value) => {
                if (!value) {
                    errors.court_coverage = 'Необходимо указать покрытие корта';
                }
            },
            sports_category: (errors, value) => {
                if (!value) {
                    errors.sports_category = 'Необходимо указать разряд';
                }
            },
            official_ball_of_tournament: (errors, value) => {
                if (!value) {
                    errors.official_ball_of_tournament =
                        'Необходимо указать официальный мяч турнира';
                }
            },
            tournament_system: (errors, value) => {
                if (!value) {
                    errors.tournament_system = 'Необходимо указать турнирную систему';
                }
            },
            prize_pool: (errors, value) => {
                if (typeof value !== 'number') {
                    errors.prize_pool = 'Необходимо указать призовой фонд';
                } else if (value < 0) {
                    errors.prize_pool = 'Значение призового фонда должно быть больше нуля';
                } else if (value > config.references.maxPrizePoolValue) {
                    errors.prize_pool = `Значение призового фонда не может превышать ${config.references.maxPrizePoolValue}`;
                }
            },
            is_pair: (errors, value) => {
                if (!value) {
                    errors.is_pair = 'Необходимо указать парность турнира';
                }
            },
            discount: (errors, value) => {
                if (values.with_discount) {
                    if (typeof value !== 'number') {
                        errors.discount = 'Необходимо указать скидку';
                    } else if (value <= 0) {
                        errors.discount = 'Значение скидки должно быть больше нуля';
                    }
                }
            }
            // discount_description: (errors, value) => {
            //     if (values.with_discount) {
            //         if (!value) {
            //             errors.discount_description = 'Необходимо указать описание скидки';
            //         }
            //     }
            // }
        };

        Object.keys(values).forEach((key) => rules[key] && rules[key](errors, values[key]));
    };
}
