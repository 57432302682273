// @index('./*', f => `import ${f.name} from '${f.path}';`)
import AsyncAutoComplete from './AsyncAutoComplete';
import AsyncMultipleAutoComplete from './AsyncMultipleAutoComplete';
import AutoComplete from './AutoComplete';
import Checkbox from './Checkbox';
import DateField from './DateField';
import MultipleAutoComplete from './MultipleAutoComplete';
import Number from './Number';
import Phone from './Phone';
import Radio from './Radio';
import RadioGroup from './RadioGroup';
import Select from './Select';
import TextFieldStyled from './TextFieldStyled';
import Text from './Text';
import TimeField from './TimeField';
// @endindex

export default {
    AsyncAutoComplete,
    AsyncMultipleAutoComplete,
    AutoComplete,
    Checkbox,
    Date: DateField,
    MultipleAutoComplete,
    Number,
    Phone,
    Radio,
    RadioGroup,
    Select,
    StyledTextField: TextFieldStyled,
    Text,
    Time: TimeField
};
