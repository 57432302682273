import { makeAutoObservable } from 'mobx';

export default class PromotionsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    promotions = [];

    // get getPromotions() {
    //     return this.promotions;
    // }

    getPromotionBy = (key, value) => this.promotions.find((promotion) => promotion[key] === value);

    setPromotions = (promotions) => {
        this.promotions = promotions;
    };

    updatePromotion = (id, newPromotionValues) => {
        const promotionIndex = this.promotions.findIndex((promotion) => promotion.id === id);

        this.promotions[promotionIndex] = {
            ...newPromotionValues,
            orderNumber: this.promotions[promotionIndex].orderNumber
        };
    };

    updatePromotionField = (id, value, field) => {
        const promotionIndex = this.promotions.findIndex((promotion) => promotion.id === id);
        this.promotions[promotionIndex] = {
            ...this.promotions[promotionIndex],
            [field]: value
        };
    };

    addPromotion = (promotionToAdd) => {
        const oldPromotions = this.promotions.map((promotion) => ({
            ...promotion,
            orderNumber: promotion.orderNumber + 1
        }));

        this.promotions = [promotionToAdd, ...oldPromotions];
    };

    removePromotion = (id) => {
        this.promotions = this.promotions.filter((promotion) => promotion.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['promotions'].forEach((key) => {
            this[key] = [];
        });
        console.warn('PromotionsStore clear');
    };
}
