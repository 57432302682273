import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';

const TextFieldStyled = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: ({ error }) =>
                    error ? theme.palette.primary.main : theme.palette.primary.dark
            }
        },
        '& .MuiInputLabel-root': {
            '&.MuiInputLabel-shrink': {
                color: ({ error }) =>
                    error ? theme.palette.primary.main : theme.palette.primary.dark
            }
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '1rem !important'
        }
    }
}))(TextField);

export default TextFieldStyled as typeof TextField;
