import { makeAutoObservable } from 'mobx';

export default class UsersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    users = [];

    get getUsers() {
        return this.users;
    }

    setUsers = (users) => {
        this.users = users;
    };

    updateUserField = (id, value, field) => {
        const userIndex = this.users.findIndex((user) => user.id === id);
        this.users[userIndex] = {
            ...this.users[userIndex],
            [field]: value
        };
    };

    updateUser = (id, newUserValues) => {
        const userIndex = this.users.findIndex((user) => user.id === id);
        this.users[userIndex] = {
            ...newUserValues,
            orderNumber: this.users[userIndex].orderNumber
        };
    };

    removeUser = (id) => {
        this.users = this.users.filter((user) => user.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['users'].forEach((key) => {
            this[key] = [];
        });
        console.warn('UsersStore clear');
    };
}
