import { makeAutoObservable } from 'mobx';

export default class PartnersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    partners = [];

    get getPartners() {
        return this.partners;
    }

    setPartners = (partners) => {
        this.partners = partners;
    };

    updatePartner = (id, value, field) => {
        const partnerIndex = this.partners.findIndex((partner) => partner.id === id);
        this.partners[partnerIndex] = {
            ...this.partners[partnerIndex],
            [field]: value
        };
    };

    removePartner = (id) => {
        this.partners = this.partners.filter((partner) => partner.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['partners'].forEach((key) => {
            this[key] = [];
        });
        console.warn('PartnersStore clear');
    };
}
