import { useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ruLocale from 'date-fns/locale/ru';

import config from './config';
import { PrivateRoute, ToastPortal } from './components';
import { AuthPage } from './pages';
import { useCreateToastControls } from './hooks';
import { ToastContext } from './utils/context';
import GlobalLoadingModal from './components/GlobalLoadingModal/GlobalLoadingModal';
import GlobalLoadingModalContext from './components/GlobalLoadingModal/GlobalLoadingModal.context';
import RecaptchaRoot from './components/RecaptchaRoot/RecaptchaRoot';

const DashboardPage = lazy(() => import('./pages/dashboardPage/dashboardPage'));

function App() {
    const toastRef = useRef(null);
    const toastControls = useCreateToastControls(toastRef);

    const [loading, setLoading] = useState(false);

    const value = useMemo(() => ({ ...toastControls }), [toastControls]);

    useEffect(() => {
        window.amToastControls = { ...value };
        return () => delete window.amToastControls;
    }, [toastControls]);

    const recaptchaRef = useRef(null);

    return (
        <div className="App">
            <RecaptchaRoot ref={recaptchaRef}>
                <ToastContext.Provider value={value}>
                    <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
                        <GlobalLoadingModalContext.Provider value={{ loading, setLoading }}>
                            <Suspense>
                                <Routes>
                                    <Route path={config.routes.HOME} element={<AuthPage />} />
                                    <Route
                                        path={`${config.routes.DASHBOARD_HOME}/*`}
                                        element={<PrivateRoute />}
                                    >
                                        <Route path="*" element={<DashboardPage />} />
                                    </Route>
                                </Routes>
                            </Suspense>
                            <GlobalLoadingModal />
                        </GlobalLoadingModalContext.Provider>
                    </LocalizationProvider>
                    <ToastPortal ref={toastRef} autoCloseTime={3000} autoClose />
                </ToastContext.Provider>
            </RecaptchaRoot>
        </div>
    );
}

export default App;
