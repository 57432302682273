import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { red } from '@mui/material/colors';

const theme = responsiveFontSizes(
    createTheme(
        adaptV4Theme({
            spacing: 4,
            typography: {
                fontFamily: ['GT-Pressura-LC', 'sans-serif'].join(', '),
                fontSize: 10
            },
            palette: {
                primary: {
                    main: 'rgb(231, 30, 43)',
                    dark: 'rgb(59, 41, 66)',
                    contrastText: '#fff'
                },
                secondary: {
                    main: 'rgb(203, 211, 227)',
                    contrastText: 'rgb(59, 41, 66)'
                },
                error: {
                    main: red.A400
                },
                text: {
                    primary: 'rgb(59, 41, 66)'
                }
            },
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        html: {},
                        body: {
                            margin: 0
                        },
                        main: {
                            display: 'block',
                            boxSizing: 'border-box'
                        },
                        ul: {
                            margin: 0,
                            padding: 0
                        },
                        li: {
                            margin: 0,
                            padding: 0
                        }
                    }
                },
                MuiButton: {
                    root: {
                        textTransform: 'none',
                        fontSize: 16,
                        height: 40,
                        fontWeight: 100,

                        '&:hover': {
                            backgroundColor: 'none'
                        }
                        // [breakpoints.down('md')]: {
                        //     fontSize: 14
                        // }
                    },
                    textPrimary: {
                        paddingLeft: 20,
                        paddingRight: 20
                    }
                },
                MuiTextField: {
                    root: {
                        '& label.Mui-focused': {
                            color: 'rgb(59, 41, 66)'
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'rgb(59, 41, 66)'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgb(59, 41, 66)'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'rgb(59, 41, 66)'
                            }
                        }
                    }
                },
                MuiFilledInput: {
                    root: {
                        backgroundColor: 'rgb(203, 211, 227)'
                    },
                    underline: {
                        '&:after': {
                            borderBottomWidth: '2px'
                        },
                        '&:before': {
                            borderColor: '#000',
                            borderBottomWidth: '2px'
                        }
                    },
                    input: {
                        backgroundColor: 'rgb(245, 248, 250)'
                    }
                },
                MuiDialog: {
                    paper: {
                        borderRadius: 12
                    }
                },
                MuiDialogActions: {
                    root: {
                        marginBottom: 8
                    }
                },
                MuiDialogTitle: {
                    root: {
                        borderBottom: '1px solid rgb(204, 214, 221)',
                        marginBottom: 10,
                        padding: '10px 15px',
                        '& h2': {
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 800
                        },
                        '& button': {
                            padding: 8,
                            marginRight: 20
                        }
                    }
                }
                // MuiGrid: {
                //     container: {
                //         position: 'relative'
                //     }
                // }
            },
            props: {
                MuiCheckbox: {
                    disableRipple: true
                },
                MuiTextField: {
                    variant: 'outlined'
                }
            }
        }),
        ruRU
    )
);

export default theme;
