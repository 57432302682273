import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { useMatch, useResolvedPath } from 'react-router';
import config from '../../config';

import useHeaderStyles from './styles';

const Header = ({ open }) => {
    const classes = useHeaderStyles();
    const resolvePath = useResolvedPath('');
    const match = useMatch(`${resolvePath.pathname}/:sect/*`);
    const currentSection = config.references.sideBarSections[match?.params?.sect] || 'Главная';

    return (
        <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
                [classes.appBarShift]: open
            })}
            elevation={1}
        >
            <Toolbar>
                <Typography className={classes.title} variant="h6" noWrap>
                    {currentSection}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    open: PropTypes.bool.isRequired
};

export default Header;
