import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useFormFieldStyles from './styles';

export interface TimeFieldProps {
    touched?: boolean;
    errors?: any[];
    label: string;
    value: string;
    name: string;
    // eslint-disable-next-line no-unused-vars
    handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    // eslint-disable-next-line no-unused-vars
    setFieldValue: (field: string, value: any) => void;
    onChange?: () => void;
    fullWidth?: boolean;
    disabled?: boolean;
}

const TimeField = ({
    touched,
    errors,
    label,
    value,
    name,
    handleBlur,
    setFieldValue,
    onChange,
    fullWidth = true,
    disabled
}: TimeFieldProps) => {
    const classes = useFormFieldStyles({
        error: !!errors
    });
    const [selectedTime, setSelectedTime] = useState<string | null>(value);

    const handleTimeChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
        ev
    ) => {
        const { value } = ev.target;
        setSelectedTime(value || null);
    };

    useEffect(() => {
        setFieldValue(name, selectedTime);
    }, [selectedTime]);

    return (
        <TextField
            label={label}
            sx={{ width: 150 }}
            defaultValue={selectedTime}
            name={name}
            type="time"
            className={classes.formField}
            variant="outlined"
            FormHelperTextProps={{
                className: classes.errorText
            }}
            error={!!(touched && errors)}
            helperText={touched && errors}
            fullWidth={fullWidth}
            onChange={(ev) => {
                handleTimeChange(ev);
                if (onChange) {
                    onChange();
                }
            }}
            onBlur={handleBlur}
            disabled={disabled}
            InputProps={{
                // @ts-expect-error
                step: 300,
                className: classes.input,
                endAdornment: !!(touched && errors) && (
                    <InputAdornment position="end">
                        <CloseIcon color="primary" className={classes.errorIcon} />
                    </InputAdornment>
                )
            }}
            InputLabelProps={{
                className: classes.input,
                shrink: true
            }}
        />
    );
};

export default TimeField;
