import { isEqual, isObject, transform } from 'lodash';

export default function differenceBetweenObjects(object, base) {
    function changes(object, base) {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}
