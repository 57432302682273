import { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import ExitIcon from '@mui/icons-material/NoMeetingRoom';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import { ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import ListItemLink from '../ListItemLink/ListItemLink';
import useStore from '../../hooks/useStore';
import config from '../../config';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

import useSideMenuStyles from './styles';
import useSideMenuLogic from './SideMenu.logic';

const SideMenu = ({ open, setOpen }) => {
    const classes = useSideMenuStyles();
    const rootStore = useStore();
    const { adminDataStore } = rootStore;
    const navigate = useNavigate();
    const [confirmExitVisible, setConfirmExitVisible] = useState(false);

    const toggleDrawer = () => setOpen((prevOpen) => !prevOpen);

    const {
        tournamentsCount,
        promotionsCount,
        directorApplicationsCount,
        photoAlbumsCount,
        videosCount,
        postsCount
    } = useSideMenuLogic();

    const counterMap = useMemo(() => ({
        'Модерация турниров': tournamentsCount,
        'Модерация продвижений': promotionsCount,
        'Работа с заявками': directorApplicationsCount,
        Публикации: postsCount,
        Медиатека: photoAlbumsCount + videosCount
    }));

    const onExitOk = () => {
        adminDataStore.logOut();
        rootStore.clear();
        navigate('/', { replace: true });
    };

    return (
        <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            onClose={() => setOpen(false)}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={toggleDrawer} size="large">
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItemLink
                    open={open}
                    setOpen={setOpen}
                    text="Главная"
                    to="/dashboard"
                    icon={<HomeIcon />}
                />
            </List>
            <Divider />
            {config.references.sideBarGroups[adminDataStore.role]?.map((group) => (
                <Fragment key={group.reduce((acc, curr) => acc + curr.text, '')}>
                    <List>
                        {group.map((item) => (
                            <ListItemLink
                                key={item.text}
                                open={open}
                                setOpen={setOpen}
                                counter={counterMap?.[item.text] || 0}
                                {...item}
                            />
                        ))}
                    </List>
                    <Divider />
                </Fragment>
            ))}
            <ListItem button onClick={() => setConfirmExitVisible(true)}>
                <ListItemIcon className={classes.icon}>
                    <ExitIcon />
                </ListItemIcon>
                <ListItemText primary="Выйти" classes={{ primary: classes.listItemText }} />
            </ListItem>
            <Divider />
            {confirmExitVisible && (
                <ConfirmationDialog
                    onOk={onExitOk}
                    open={confirmExitVisible}
                    text="Вы уверены, что хотите выйти из панели администратора?"
                    title="Подтвердите выход из панели администратора"
                    handleClose={() => {
                        setConfirmExitVisible(false);
                    }}
                />
            )}
        </Drawer>
    );
};

SideMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default observer(SideMenu);
