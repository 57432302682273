import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useConfirmationDialogStyles from './styles';

const ConfirmationDialog = ({
    title,
    text,
    open,
    onOk,
    handleClose,
    maxWidth,
    swapPreferredActions
}) => {
    const classes = useConfirmationDialogStyles();

    return (
        <Dialog maxWidth={maxWidth} open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.text}>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant={swapPreferredActions ? 'contained' : 'outlined'}
                >
                    Отмена
                </Button>
                <Button
                    onClick={onOk}
                    color="primary"
                    variant={swapPreferredActions ? 'outlined' : 'contained'}
                >
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    swapPreferredActions: PropTypes.bool
};

ConfirmationDialog.defaultProps = {
    maxWidth: 'sm',
    swapPreferredActions: false
};

export default ConfirmationDialog;
