import { makeAutoObservable } from 'mobx';

export default class ApplicationsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    aggrigatedRequests = [];

    setAggrigatedRequests = (data) => {
        this.aggrigatedRequests = data.map((el) => ({
            ...el,
            requests: el.requests.map((req, i) => ({
                ...req,
                tournament: el.id,
                orderNumber: i + 1
            }))
        }));
    };

    get requests() {
        return this.aggrigatedRequests.reduce((acc, curr) => [...acc, ...curr.requests], []);
    }

    requestPath = (id) => {
        const request = this.findRequest(id);
        const tournamentIdx = this.aggrigatedRequests.findIndex(
            (el) => el.id === request.tournament
        );
        const requestIdx = this.aggrigatedRequests[tournamentIdx].requests.findIndex(
            (el) => el.id === request.id
        );

        return { tournamentIdx, requestIdx };
    };

    updateApplication = (id, value, field) => {
        const { tournamentIdx, requestIdx } = this.requestPath(id);

        const newLocal = this.aggrigatedRequests;
        newLocal[tournamentIdx].requests[requestIdx] = {
            ...this.aggrigatedRequests[tournamentIdx].requests[requestIdx],
            [field]: value
        };

        this.setAggrigatedRequests(newLocal);
    };

    removeApplication = (id) => {
        const { tournamentIdx } = this.requestPath(id);

        const newLocal = this.aggrigatedRequests;
        newLocal[tournamentIdx].requests = this.aggrigatedRequests[tournamentIdx].requests.filter(
            (application) => application.id !== id
        );
        this.setAggrigatedRequests(newLocal);
    };

    findRequest = (id) => this.requests.find((el) => el.id === id);

    addApplication = (application, orderNumber) => {
        const applicationToStore = {
            ...application,
            orderNumber: orderNumber || this.aggrigatedRequests[0]?.orderNumber || 1
        };

        const newLocal = this.aggrigatedRequests;

        const tournamentIdx = newLocal.findIndex((el) => el.id === applicationToStore.tournament);

        newLocal[tournamentIdx].requests = [
            applicationToStore,
            ...newLocal[tournamentIdx].requests
        ];
        this.setAggrigatedRequests(newLocal);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['applications'].forEach((key) => {
            this[key] = [];
        });
        console.warn('ApplicationsStore clear');
    };
}
