import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
// import Cookies from 'js-cookie';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Grid from '@mui/material/Grid';
// import classNames from 'classnames';
// import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
// import Recaptcha from 'react-google-invisible-recaptcha';

import { Validator } from '../../utils/helpers';
import { FormField } from '../../components';
import { useAdminDataStore } from '../../hooks/useStore';
import config from '../../config';
// import { ReactComponent as OKIcon } from '../../assets/icons/OK.svg';
// import { ReactComponent as VKIcon } from '../../assets/icons/VK.svg';
// import { ReactComponent as FBIcon } from '../../assets/icons/Facebook.svg';
import { useQuery } from '../../hooks';

import useAppStyles from './styles';
import useLogic from './useLogic';

function AuthPage() {
    const query = useQuery();
    const classes = useAppStyles();
    const adminDataStore = useAdminDataStore();
    const navigate = useNavigate();
    // const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const {
        AccessErrorSnack,
        loading,
        // setLoading,
        performOAuth,
        accessErrorSnackOpen,
        ErrorSnack,
        errorSnackOpen,
        submitAuthForm
    } = useLogic();

    useEffect(() => {
        if (query.has('from')) {
            performOAuth();
        }
    }, []);

    useEffect(() => {
        if (adminDataStore.access) {
            navigate(config.routes.DASHBOARD_HOME, { replace: true });
        }
    }, [adminDataStore, navigate]);

    return (
        <>
            <Container className={classes.root}>
                <Paper classes={{ root: classes.form }}>
                    <Typography variant="h2">Amatour Admin</Typography>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validate={(values) => Validator.validateForm(values)}
                        onSubmit={submitAuthForm}
                    >
                        {({ values, handleChange, handleBlur, errors, touched, isValid }) => (
                            <Form noValidate autoComplete="off">
                                <FormField.Text
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched.email}
                                    errors={errors.email}
                                    // size={!isLargeScreen && 'small'}
                                />
                                <FormField.Text
                                    label="Пароль"
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched.password}
                                    errors={errors.password}
                                    // size={!isLargeScreen && 'small'}
                                />
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!isValid}
                                    className={classes.btn}
                                    fullWidth
                                >
                                    Войти
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    {loading && (
                        <Paper className={classes.formLoading}>
                            <CircularProgress color="primary" />
                        </Paper>
                    )}
                </Paper>
            </Container>
            {errorSnackOpen && ErrorSnack}
            {accessErrorSnackOpen && AccessErrorSnack}
        </>
    );
}

export default AuthPage;
