import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { Badge, Tooltip, Typography, useMediaQuery } from '@mui/material';
import useListItemLinkStyles from './styles';

const RenderLink = forwardRef((itemProps, ref) => <RouterLink ref={ref} {...itemProps} />);
RenderLink.displayName = 'RenderLink';

const ListItemLink = ({ open, setOpen, icon, text, to, counter }) => {
    const classes = useListItemLinkStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ListItem
            button
            to={to}
            component={RenderLink}
            onClick={() => {
                if (open && matches) {
                    setOpen(false);
                }
            }}
        >
            <Tooltip
                title={<Typography>{text}</Typography>}
                arrow
                placement="right"
                disableHoverListener={open}
                disableTouchListener={open}
                disableFocusListener={open}
            >
                <ListItemIcon className={classes.icon}>
                    <Badge badgeContent={counter} color="primary" max={99} className={classes.bage}>
                        {icon || null}
                    </Badge>
                </ListItemIcon>
            </Tooltip>
            <ListItemText primary={text} classes={{ primary: classes.listItemText }} />
        </ListItem>
    );
};

ListItemLink.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.element,
    counter: PropTypes.number
};

ListItemLink.defaultProps = {
    counter: 0
};

export default ListItemLink;
