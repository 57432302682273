import makeStyles from '@mui/styles/makeStyles';
import config from '../../config';

const useSideMenuStyles = makeStyles((theme) => ({
    drawer: {
        width: config.references.drawerFullWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: config.references.drawerFullWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: config.references.drawerSmallWidth,
        [theme.breakpoints.up('sm')]: {
            width: config.references.drawerSmallWidth
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    listItemText: {
        fontSize: '1rem'
    },
    icon: {
        minWidth: 35
    }
}));

export default useSideMenuStyles;
