import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { filter, includes, isString, toLower } from 'lodash';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export interface MultipleAutoCompleteProps {
    label: string;
    name: string;
    // eslint-disable-next-line no-unused-vars
    setFieldValue: (field: string, value: any) => void;
    // eslint-disable-next-line no-unused-vars
    setTouched: (field: string, value: any) => void;
    options: any[];
    touched?: boolean;
    errors?: any;
    freeSolo?: boolean;
    disabled?: boolean;
    value?: any;
    // eslint-disable-next-line no-unused-vars
    onInput?: (event: any) => void;
}

const MultipleAutoComplete = ({
    label,
    name,
    setFieldValue,
    setTouched = () => {},
    options,
    touched,
    errors,
    freeSolo,
    disabled,
    value,
    onInput
}: MultipleAutoCompleteProps) => {
    const classes = useFormFieldStyles({
        error: !!touched && !!errors
    });

    return (
        <Autocomplete
            // @ts-ignore
            name={name}
            options={options.map((option) => option.name)}
            disabled={disabled}
            value={value}
            limitTags={4}
            onInput={onInput}
            classes={{
                root: classes.autocomplete,
                input: classes.input,
                inputFocused: classes.input,
                listbox: classes.input
            }}
            onChange={(_, newValue) => {
                const mappedNewValue = newValue.map((el) => el.inputValue || el);
                setFieldValue(name, mappedNewValue || '');
            }}
            filterOptions={(options, params) => {
                const { inputValue } = params;
                const addedOptions = [...new Set([...value, ...options])];

                const isExisting = addedOptions.some((option) => inputValue === option);

                const filtered = filter(addedOptions, (option) =>
                    includes(toLower(option), toLower(inputValue))
                );

                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Добавить новый тег: "${inputValue}"`
                    });
                }

                return filtered;
            }}
            onBlur={() => setTouched(name, true)}
            renderInput={(params) => (
                <TextFieldStyled
                    {...params}
                    label={label}
                    variant="outlined"
                    FormHelperTextProps={{
                        className: classes.errorText
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: disabled && (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        )
                    }}
                    error={!!(touched && errors)}
                    helperText={touched && errors}
                    fullWidth
                />
            )}
            getOptionLabel={(option) => {
                if (isString(option)) {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.title;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.title || option.inputValue || option}
                </li>
            )}
            freeSolo={freeSolo}
            modifiers={{
                fixed: true
            }}
            // popperOptions={{
            //     placement: 'fixed'
            // }}
            multiple
            disableCloseOnSelect
            // autoSelect
            openOnFocus
            disablePortal
        />
    );
};

export default MultipleAutoComplete;
