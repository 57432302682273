import { Grid, Hidden, ListItem, ListItemText } from '@mui/material';
import classNames from 'classnames';
import { compact } from 'lodash';
import { CODE_TYPES, promocodeTypes } from './Promocodes.schema';
import config from '../../config';
import PromocodesTooltip from './Promocodes.Tooltip';
import styles from './Promocodes.module.scss';
import PromocodesListitemActions from './Promocodes.listItem.Actions';

const getReferalLink = (code) => `${config.server.service}/referral?code=${code}`;

const PromocodesListitem = ({ promocode }) => (
    <ListItem
        className={classNames(styles.promocodesListitem, {
            [styles.promocodesListitem_inactive]: !promocode.is_active
        })}
        secondaryAction={
            <Hidden mdDown>
                <PromocodesListitemActions promocode={promocode} />
            </Hidden>
        }
    >
        <PromocodesTooltip promocode={promocode} />
        <Grid container>
            <Grid item md={12}>
                <ListItemText
                    primary={
                        promocode.code_type === 'referral'
                            ? getReferalLink(promocode.code)
                            : promocode.code
                    }
                    secondary={compact([
                        CODE_TYPES[promocode.code_type],
                        promocode.discount && `${promocode.discount}%`
                    ]).join(', ')}
                />
            </Grid>
            <Grid item md={12}>
                <Hidden mdUp>
                    <PromocodesListitemActions promocode={promocode} />
                </Hidden>
            </Grid>
        </Grid>
    </ListItem>
);

PromocodesListitem.propTypes = {
    promocode: promocodeTypes
};

export default PromocodesListitem;
