import tinycolor from 'tinycolor2';

const getCorrectIndexOfChar = (char) => {
    const number = char.charCodeAt(0);

    if (number > 255) {
        return 255;
    }

    if (number < 0) {
        return 0;
    }

    return number;
};

const generateAvatarFromEmail = (email) => {
    const [r, g, b] = email.substr(0, 3).split('').map(getCorrectIndexOfChar);

    return {
        color: tinycolor({ r, g, b }).lighten(30).saturate(50).toHexString(),
        colorDarker: tinycolor({ r, g, b }).saturate(50).toHexString()
    };
};

export default generateAvatarFromEmail;
