import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableRow, TableCell, Pagination } from '@mui/material';
import classNames from 'classnames';
import Hidden from '@mui/material/Hidden';
import { useUpdateEffect } from 'usehooks-ts';
import config from '../../../config';
import { useAdminDataStore } from '../../useStore';
import useAxios from '../../useAxios';
import useStyles from './useStyles';

/** @depricated */
export default function useTable({
    resource,
    headCells,
    queryParams,
    additionalQuery,
    storeArray,
    page_size = config.references.rowsPerPage
}) {
    const classes = useStyles();
    const adminDataStore = useAdminDataStore();
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const { request: getResourceData } = useAxios({
        method: 'get',
        url: resource,
        params: {
            page: page || 1,
            page_size: page_size || config.references.rowsPerPage,
            ...queryParams,
            ...additionalQuery
        }
    });

    const performRequest = useCallback(
        async (page) => {
            setLoading(true);

            try {
                const response = await getResourceData();

                const { results, count } = response;

                const records = results.map((record, recordIdx) => ({
                    orderNumber:
                        page === 1
                            ? recordIdx + 1
                            : (page_size || config.references.rowsPerPage) * (page - 1) +
                              (recordIdx + 1),
                    ...record
                }));

                setRecords(
                    records.map((record) => {
                        const { children } = record;

                        if (children) {
                            return {
                                ...record,
                                category:
                                    children.length === 1
                                        ? children[0].category
                                        : 'Несколько категорий'
                            };
                        }

                        return record;
                    })
                );

                setCount(count);
                setLoading(false);
            } catch (e) {
                setRecords([]);
                setCount(0);
                setPage(0);
                setLoading(false);
            }
        },
        [getResourceData]
    );

    useUpdateEffect(() => {
        setPage(1);
        performRequest(1);
    }, [queryParams]);

    useEffect(() => {
        performRequest(page);
    }, [page]);

    // TODO: wtf???
    const TblContainer = ({ children }) => <Table className={classes.table}>{children}</Table>;

    TblContainer.propTypes = {
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
            .isRequired
    };

    // TODO: wtf???
    const TblHead = () => (
        <TableHead>
            <TableRow>
                {headCells.map((cell) => {
                    if (
                        cell?.allowedRoles?.length &&
                        cell?.allowedRoles.indexOf(adminDataStore.role) === -1
                    ) {
                        return <TableCell key={cell.id} />;
                    }

                    const props = {};
                    if (cell.hidden) {
                        props[cell.hidden] = true;
                    }

                    return (
                        <Hidden {...props} key={cell.id}>
                            <TableCell
                                key={cell.id}
                                className={classNames({
                                    [classes.controls]: cell?.type === 'controls'
                                })}
                            >
                                {cell.label}
                            </TableCell>
                        </Hidden>
                    );
                })}
            </TableRow>
        </TableHead>
    );

    const handlePageChange = useCallback(
        (_, newPage) => {
            if (newPage !== page) {
                setPage(newPage);
            }
        },
        [page]
    );

    const TblPagination = () => (
        <Pagination
            className={classes.pagination}
            page={page}
            count={Math.ceil(count / page_size)}
            onChange={handlePageChange}
            color="primary"
        />
    );

    const updateRecords = useCallback(async () => {
        await performRequest(page);
    }, [page, performRequest]);

    return {
        TableContainer: TblContainer,
        TableHead: TblHead,
        TablePagination: TblPagination,
        recordsLoading: loading,
        records,
        isEmpty: storeArray?.length === 0,
        updateRecords
    };
}
