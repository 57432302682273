import { compact } from 'lodash';
import { makeAutoObservable, when } from 'mobx';
// import jwt_decode from 'jwt-decode';
// import { debounce } from 'lodash';
import storageHelper from '../../utils/helpers/storage.helper';
import AdminDataStoreApi from './adminData.store.api';

const DEFAULT_VALUES = {
    access: '',
    avatar: '',
    birthdate: '',
    city: '',
    country: '',
    email: '',
    first_name: '',
    id: '',
    director_id: '',
    last_name: '',
    middle_name: '',
    phone: '',
    refresh: '',
    refreshRequest: null,
    region: '',
    role: '',
    sex: '',
    status: '',
    token: '',
    stats: {
        active_announces_count: 0,
        past_tournament_count: 0,
        tournament_count: 0,
        upcoming_tournament_count: 0,
        waiting_requests_count: 0
    }
};

const SAVE_LOCAL = ['access', 'refresh', 'token'];

export default class AdminDataStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        Object.entries(DEFAULT_VALUES).forEach(([key, value]) => {
            this[key] = value;
        });

        SAVE_LOCAL.forEach((field) => {
            this.updateField(field, storageHelper.get(field));
        });

        if (storageHelper.get('access')) {
            when(
                () => !!this.access,
                () => this.loadAdminData()
            );
        }

        makeAutoObservable(this);
    }

    get getAdminData() {
        return Object.keys(DEFAULT_VALUES).map((key) => ({ [key]: this[key] }));
    }

    get isAdmin() {
        return this.role === 'admin';
    }

    get isDirector() {
        return this.role === 'director' && this.director_id;
    }

    get fullName() {
        const { last_name, first_name, middle_name } = this;
        return compact([last_name, first_name, middle_name]).join(' ');
    }

    refreshToken = async () => {
        try {
            const { data } = await AdminDataStoreApi.refreshToken(this.refresh);
            this.setAdminData(data);
            return data;
        } catch (error) {
            this.logOut({ message: 'Сессия истекла, повторите вход', reason: 'token_expired' });
            return null;
        }
    };

    unauthorizedHandler = async (callback = () => {}) => {
        if (!this.refreshRequest) {
            this.refreshRequest = this.refreshToken();
            const data = await this.refreshRequest;
            this.refreshRequest = null;
            if (data.access) {
                this.setAdminData(data);
                return callback();
            }
            // this.logOut();
            return null;
        }
        try {
            await this.refreshRequest;
            return callback();
        } catch (error) {
            return null;
        }
    };

    // // TODO: доделать стек вызовов колбеков, сейчас они гасятся
    // onTokenExpired = debounce(this.unauthorizedHandler, 1000, {
    //     leading: true,
    //     trailing: false
    // });

    loadAdminData = async (props = {}) => {
        const access = props.access || this.access || storageHelper.get('access');
        const token = props.token || this.token || storageHelper.get('token');
        try {
            if (access && token) {
                const { data: userData } = await AdminDataStoreApi.getUserData(access, token);

                if (userData) {
                    this.setAdminData({ ...userData, ...props });
                }
                return userData;
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                return this.unauthorizedHandler(() => this.loadAdminData(props));
            }
        }
        return console.error('нет токена');
    };

    logOut = (
        { message, reason } = { message: 'Вы вышли из своего профиля', reason: 'logout' }
    ) => {
        try {
            AdminDataStoreApi.logOut(this.access, this.refresh);
        } catch (error) {
            //
        }
        this.clearStore();
        window.amToastControls[reason === 'logout' ? 'addInfoToast' : 'addErrorToast'](message);
    };

    setAdminData = (userData) => {
        Object.entries(userData).forEach(([key, value]) => this.updateField(key, value));
        return this.getAdminData;
    };

    updateField = (key, value) => {
        this[key] = value;
        if (SAVE_LOCAL.includes(key)) {
            storageHelper.save(key, value);
        }
    };

    clearStore = () => {
        Object.entries(DEFAULT_VALUES).forEach(([key, value]) => {
            this.updateField(key, value);
        });
    };
}
