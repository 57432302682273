import makeStyles from '@mui/styles/makeStyles';

interface Theme {
    // eslint-disable-next-line no-unused-vars
    spacing: (...args: number[]) => number;
    palette: {
        primary: {
            dark: string;
            main: string;
        };
    };
    breakpoints: {
        // eslint-disable-next-line no-unused-vars
        down: (key: string) => string;
    };
}

const useFormFieldStyles = makeStyles((theme: Theme) => ({
    formField: {
        margin: theme.spacing(2, 0, 2),
        '&:last-of-type': {
            marginBottom: 0
        }
    },
    input: {
        color: theme.palette.primary.dark,
        fontSize: '1rem',

        '&:disabled': {
            color: 'rgba(0, 0, 0, 0.38)'
            // cursor: 'not-allowed'
        }

        // [theme.breakpoints.down('xl')]: {
        //     fontSize: '1rem'
        // },
        // [theme.breakpoints.down('md')]: {
        //     fontSize: '0.8rem'
        // },
        // [theme.breakpoints.down('xs')]: {
        //     fontSize: '0.6rem'
        // }
    },
    errorText: {
        marginLeft: 0,

        [theme.breakpoints.down('xl')]: {
            fontSize: '0.8rem'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.6rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '0.4rem'
        }
    },
    errorIcon: {
        [theme.breakpoints.down('xl')]: {
            width: '1em',
            height: '1em'
        },
        [theme.breakpoints.down('xl')]: {
            width: '0.8em',
            height: '0.8em'
        },
        [theme.breakpoints.down('md')]: {
            width: '0.6em',
            height: '0.6em'
        }
    },
    formControl: {
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: ({ error }: { error: boolean }) =>
                error ? theme.palette.primary.main : theme.palette.primary.dark,
            borderWidth: 1
        },
        '& .MuiOutlinedInput-root': {
            fontSize: '1rem',

            '& fieldset': {
                borderColor: ({ error }) =>
                    error ? theme.palette.primary.main : theme.palette.primary.dark
            }
        },
        '& .MuiInputLabel-root': {
            fontSize: '1rem',

            '&.MuiInputLabel-shrink': {
                color: ({ error }) =>
                    error ? theme.palette.primary.main : theme.palette.primary.dark
            }
        },
        '& .MuiSelect-root': {
            '&.MuiSelect-select:focus': {
                backgroundColor: 'transparent'
            }
        }
    },
    selectItem: {
        fontSize: '1rem'
    },
    autocomplete: {
        margin: theme.spacing(2, 0, 0),

        '& .MuiFormLabel-root': {
            fontSize: '1rem'
        },

        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50%-15px)'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            fontSize: '1rem'
        }
    },
    label: {
        fontSize: '1rem'
    }
}));

export default useFormFieldStyles;
