import { useState } from 'react';
import { parsePhoneNumber } from 'awesome-phonenumber';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { TextFieldProps } from '@mui/material';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export type PhoneProps = TextFieldProps & {
    label: string;
    value: string;
    // eslint-disable-next-line no-unused-vars
    setFieldValue: (field: string, value: any) => void;
    name: string;
    touched: boolean;
    errors: string;
    // eslint-disable-next-line no-unused-vars
    handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

const Phone = ({
    label,
    value,
    setFieldValue,
    handleBlur,
    name,
    touched,
    errors,
    ...other
}: PhoneProps) => {
    const classes = useFormFieldStyles({
        error: !!errors
    });
    const [text, setText] = useState(parsePhoneNumber(`+${value}`)?.number?.international || value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value: newValue }
        } = e;
        const cleared = newValue.replace(/[^0-9+-\s]/g, '');

        const parsed = parsePhoneNumber(cleared);
        if (cleared.replace(/[^0-9+]/g, '') === text.replace(/[^0-9+]/g, '')) {
            setText(cleared);
        } else {
            setText(
                parsed.canBeInternationallyDialled && parsed.number
                    ? parsed.number.international
                    : cleared
            );
        }

        setFieldValue(name, parsed.number?.e164 || cleared);
    };

    return (
        <TextFieldStyled
            label={label}
            name={name}
            type="text"
            className={classes.formField}
            InputProps={{
                className: classes.input,
                endAdornment: !!(touched && errors) && (
                    <InputAdornment position="end">
                        <CloseIcon color="primary" />
                    </InputAdornment>
                )
            }}
            InputLabelProps={{
                className: classes.input
            }}
            FormHelperTextProps={{
                className: classes.errorText
            }}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched && errors)}
            helperText={touched && errors}
            fullWidth
            {...other}
        />
    );
};

export default Phone;
