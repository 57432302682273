import { isEqual } from 'lodash';

export default function selectChangedProperties(oldValue, newValue) {
    const changedValues = {};

    Object.keys(newValue).forEach((key) => {
        if (!isEqual(newValue[key], oldValue[key])) {
            changedValues[key] = newValue[key];
        }
    });

    return changedValues;
}
