import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import config from '../../config';
import { useAxios, useQuery } from '../../hooks';
import { useAdminDataStore } from '../../hooks/useStore';
import { ToastContext } from '../../utils/context';

const useLogic = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const adminDataStore = useAdminDataStore();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const { addErrorToast } = useContext(ToastContext);
    const { request: performSignIn } = useAxios(
        {
            method: 'post',
            url: `${config.server.serviceHost}${config.server.api.signin.uri}`
        },
        { withCaptcha: true }
    );
    const { request: performOAuthSignIn } = useAxios(
        {
            method: 'post',
            url: `${config.server.serviceHost}${config.server.api.oauth.uri}`
        },
        { withCaptcha: true }
    );
    const { request: getCurrentUserInfo } = useAxios({
        method: 'get',
        url: `${config.server.host}${config.server.api.getCurrentUserInfo.uri}`
    });

    const submitAuthForm = async (values) => {
        const { email, password } = values;
        setIsFormSubmitting(true);

        try {
            const signInData = await performSignIn({
                email,
                password
            });

            const currentUser = adminDataStore.loadAdminData({
                access: signInData.access,
                token: signInData.token,
                refresh: signInData.refresh
            });

            if (currentUser) {
                if (currentUser.role === 'player' || currentUser.role === 'visitor') {
                    throw new Error({
                        response: {
                            data: {
                                message: 'No access rights'
                            }
                        }
                    });
                } else {
                    adminDataStore.setAdminData({
                        ...values,
                        ...signInData,
                        ...currentUser
                    });

                    // Cookies.set('adminData', adminDataStore.getAdminData);
                    setIsFormSubmitting(false);
                    navigate(config.routes.DASHBOARD_HOME, { replace: true });
                }
            }
        } catch (error) {
            //
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const performOAuth = async () => {
        try {
            const signUpData = await performOAuthSignIn({
                code: query.get('code'),
                provider: query.get('from'),
                source: 'admin'
            });

            const currentUser = await getCurrentUserInfo(null, {
                Authorization: `Bearer ${signUpData.access}`
            });

            if (currentUser) {
                if (currentUser.role === 'player' || currentUser.role === 'visitor') {
                    addErrorToast('Ошибка входа. Недостаточно прав доступа');
                } else {
                    adminDataStore.setAdminData({
                        ...signUpData,
                        ...currentUser
                    });

                    // Cookies.set('adminData', adminDataStore.getAdminData);
                    navigate(config.routes.DASHBOARD_HOME, { replace: true });
                }
            }
            setIsFormSubmitting(false);
        } catch {
            //
        }
    };

    return {
        loading: isFormSubmitting,
        setLoading: setIsFormSubmitting,
        submitAuthForm,
        performOAuth
    };
};

export default useLogic;
