import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Checkbox as MuiCheckbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import useFormFieldStyles from './styles';

export interface CheckboxProps {
    label: string;
    name: string;
    value: boolean;
    // eslint-disable-next-line no-unused-vars
    setFieldValue: (name: string, value: boolean) => void;
    // eslint-disable-next-line no-unused-vars
    setTouched?: (name: string, value: boolean) => void;
    touched?: boolean;
    errors?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (value: boolean) => void;
    disabled?: boolean;
}

const Checkbox = ({
    label,
    name,
    value,
    setFieldValue,
    // eslint-disable-next-line no-unused-vars
    setTouched = (...args) => {},
    touched,
    errors,
    onChange,
    disabled
}: CheckboxProps) => {
    const classes = useFormFieldStyles({
        error: !!errors && !!touched
    });

    return (
        <FormControl error={!!(touched && errors)}>
            <FormControlLabel
                classes={{
                    label: classes.label
                }}
                control={
                    <MuiCheckbox
                        checked={value}
                        onChange={(e) => {
                            setFieldValue(name, e.target.checked);

                            if (onChange) {
                                onChange(e.target.checked);
                            }
                        }}
                        name={name}
                        onBlur={() => setTouched(name, true)}
                        color="primary"
                        disabled={disabled}
                    />
                }
                label={label}
            />
            {!!(touched && errors) && (
                <FormHelperText
                    classes={{
                        root: classes.errorText
                    }}
                >
                    {errors}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default Checkbox;
