import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import config from '../../config';
import { useAxios } from '../../hooks';
import { useAdminDataStore } from '../../hooks/useStore';

export default function useSideMenuLogic() {
    const location = useLocation();

    const adminDataStore = useAdminDataStore();

    const [tournamentsCount, setTournamentsCount] = useState(0);
    const { request: getTournamentsCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.tournamentsCount.uri}`,
        params: {
            is_over: 0,
            is_publish: 0,
            canceled: 0
        }
    });
    const [promotionsCount, setPromotionsCount] = useState(0);
    const { request: getPromotionsCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.promotionsCount.uri}`,
        params: {
            is_publish: 0,
            is_paid: 1
        }
    });
    const [directorApplicationsCount, setDirectorApplicationsCount] = useState(0);
    const { request: getDirectorApplicationsCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.directorApplicationsCount.uri}`
    });
    const [photoAlbumsCount, setPhotoAlbumsCount] = useState(0);
    const { request: getPhotoAlbumsCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.photoAlbumsCount.uri}`,
        params: {
            state: 'draft'
        }
    });
    const [videosCount, setVideosCount] = useState(0);

    const { request: getPostsCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.publicationsCount.uri}`,
        params: {
            state: 'draft'
        }
    });
    const [postsCount, setPostsCount] = useState(0);

    const { request: getVideosCount } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.videosCount.uri}`,
        params: {
            state: 'draft'
        }
    });

    const MAPPED_REQUESTS = useMemo(
        () =>
            new Map([
                ...(adminDataStore.isAdmin
                    ? [
                          [setTournamentsCount, getTournamentsCount],
                          [setPromotionsCount, getPromotionsCount],
                          [setPhotoAlbumsCount, getPhotoAlbumsCount],
                          [setVideosCount, getVideosCount],
                          [setPostsCount, getPostsCount]
                      ]
                    : []),
                ...[[setDirectorApplicationsCount, getDirectorApplicationsCount]]
            ]),
        [adminDataStore.isAdmin]
    );

    const loadCountData = useMemo(
        () =>
            debounce(
                async () => {
                    const results = await Promise.allSettled(
                        [...MAPPED_REQUESTS.entries()].map(async ([, request]) => request())
                    );

                    if (results) {
                        [...MAPPED_REQUESTS.entries()].forEach(([setter], i) =>
                            setter(results[i].value.count)
                        );
                    }
                },
                15_000,
                {
                    leading: true,
                    trailing: true
                }
            ),
        [adminDataStore.id, adminDataStore.isAdmin]
    );

    useEffect(() => {
        if (adminDataStore.id) {
            loadCountData();
        }
    }, [location, adminDataStore.isAdmin, adminDataStore.id]); // TODO: можно привязать к роутам, но задебоунсить

    return {
        tournamentsCount,
        promotionsCount,
        directorApplicationsCount,
        photoAlbumsCount,
        videosCount,
        postsCount
    };
}
