import { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import { useReferencesStore } from '../../hooks/useStore';
import FormField from '../FormField';
import config from '../../config';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ReferencesForms from '../ReferencesForms/ReferencesForms';
import { useAxios } from '../../hooks';
import useReferenceBlockStyles from './styles';
import useLogic from './useLogic';

// TODO: на случай, если какие-то другие референсов ручки тоже переведем на админские
const REFERERECES_FROM_ADMIN_HOST = ['cities'];

const ReferenceBlock = ({ reference, label, customEdit }) => {
    const classes = useReferenceBlockStyles();
    const referencesStore = useReferencesStore();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [referenceItemToDelete, setReferenceItemToDelete] = useState(null);
    const [referenceItemToEdit, setReferenceItemToEdit] = useState(null);
    const [customEditFormVisible, setCustomEditFormVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [inlineAddFormVisible, setInlineAddFormVisible] = useState(false);
    const [type, setType] = useState('');
    const { request: getReference, loading } = useAxios({
        method: 'get',
        url: `${
            config.server[
                REFERERECES_FROM_ADMIN_HOST.includes(reference)
                    ? 'serviceHost'
                    : 'serviceHostPublic'
            ]
        }${config.server.apiPublic[reference].uri}`
    });
    const { onDeleteOk, handleInlineEdit, handleInlineSubmit } = useLogic({
        reference,
        referenceItemToDelete,
        referenceItemToEdit,
        // filteredName,
        // setFuse,
        setConfirmVisible,
        setReferenceItemToEdit,
        setInlineAddFormVisible,
        host: config.server[
            REFERERECES_FROM_ADMIN_HOST.includes(reference) ? 'serviceHost' : 'serviceHostPublic'
        ]
    });

    const handleDeleteReference = (item) => {
        setReferenceItemToDelete(item);
        setConfirmVisible(true);
    };

    const [filterState, setFilterState] = useState({ search: null });

    const loadItemsHandler = async () => {
        const { results } = await getReference(null, null, filterState);

        referencesStore.setReference(reference, results || []);
    };

    useEffect(() => {
        loadItemsHandler();
    }, [filterState]);

    const delayDebounceFn = useRef(null);
    const onInput = (e) => {
        const { value } = e.target;
        clearTimeout(delayDebounceFn.current);
        if (!value) {
            setFilterState({ search: value });
        } else {
            delayDebounceFn.current = setTimeout(() => {
                setFilterState({ search: value });
            }, 500);
        }
    };
    useEffect(() => () => clearTimeout(delayDebounceFn.current), []);

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper elevation={2} className={classes.root}>
                    <div className={classes.content}>
                        <div className={classes.topBar}>
                            <Typography className={classes.title}>
                                {config.references.referencesHeadings[reference]}
                            </Typography>
                            <IconButton
                                className={classes.addBtn}
                                onClick={() => {
                                    if (customEdit) {
                                        setReferenceItemToEdit(
                                            config.references.referencesInitialValues[reference]
                                        );
                                        setType('create');
                                        setCustomEditFormVisible(true);
                                        setTitle(
                                            `Дополнение справочника "${config.references.referencesHeadings[reference]}"`
                                        );
                                    } else {
                                        setInlineAddFormVisible(true);
                                    }
                                }}
                                size="large"
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        <div className={classes.search}>
                            <FormField.Text
                                size="small"
                                label={label}
                                startAdornmentIcon={<SearchIcon />}
                                onChange={onInput}
                                fullWidth
                            />
                        </div>
                    </div>
                    <List className={classes.list}>
                        {(!loading && (
                            <>
                                {!referencesStore[reference]?.length && (
                                    <ListItem
                                        className={classNames(
                                            classes.listItem,
                                            classes.emptyListItem
                                        )}
                                    >
                                        <Typography variant="body1">Ничего не найдено</Typography>
                                    </ListItem>
                                )}
                                {inlineAddFormVisible && (
                                    <>
                                        <ListItem>
                                            <Formik
                                                initialValues={{
                                                    name: ''
                                                }}
                                                onSubmit={(values) => handleInlineSubmit(values)}
                                            >
                                                {({
                                                    values,
                                                    handleBlur,
                                                    handleChange,
                                                    touched,
                                                    errors
                                                }) => (
                                                    <Form className={classes.inlineForm}>
                                                        <FormField.Text
                                                            name="name"
                                                            label="Новое значение"
                                                            type="text"
                                                            size="small"
                                                            value={values.name}
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                            touched={touched.name}
                                                            errors={errors.name}
                                                            customClass={classes.inlineInput}
                                                            fullWidth
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            type="submit"
                                                            className={classNames(
                                                                classes.confirmButton,
                                                                classes.inlineConfirmButton
                                                            )}
                                                        >
                                                            <CheckIcon className={classes.icon} />
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.deleteButton}
                                                            onClick={() =>
                                                                setInlineAddFormVisible(false)
                                                            }
                                                        >
                                                            <CloseIcon className={classes.icon} />
                                                        </Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </ListItem>
                                        <Divider />
                                    </>
                                )}
                                {referencesStore[reference].map((item) => (
                                    <Fragment key={item.id}>
                                        <ListItem className={classes.listItem}>
                                            {referenceItemToEdit?.id === item.id && !customEdit ? (
                                                <Formik
                                                    initialValues={{
                                                        name: item.name
                                                    }}
                                                    onSubmit={(values) => handleInlineEdit(values)}
                                                >
                                                    {({
                                                        values,
                                                        handleBlur,
                                                        handleChange,
                                                        touched,
                                                        errors
                                                    }) => (
                                                        <>
                                                            <Form
                                                                className={classes.form}
                                                                id={reference}
                                                            >
                                                                <FormField.Text
                                                                    name="name"
                                                                    type="text"
                                                                    size="small"
                                                                    value={values.name}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    touched={touched.name}
                                                                    errors={errors.name}
                                                                    fullWidth
                                                                />
                                                            </Form>
                                                        </>
                                                    )}
                                                </Formik>
                                            ) : (
                                                <div className={classes.listItemHeading}>
                                                    <Typography
                                                        variant="body1"
                                                        className={classes.listItemText}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    {item.note && (
                                                        <Typography
                                                            className={classes.listItemDescription}
                                                        >
                                                            {item.note}
                                                        </Typography>
                                                    )}
                                                </div>
                                            )}
                                            <div>
                                                {referenceItemToEdit?.id === item.id &&
                                                !customEdit ? (
                                                    <Button
                                                        key={`Submit ${reference}`}
                                                        variant="contained"
                                                        type="submit"
                                                        form={reference}
                                                        className={classes.confirmButton}
                                                    >
                                                        <CheckIcon className={classes.icon} />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        key={`Edit ${reference}`}
                                                        variant="contained"
                                                        type="button"
                                                        className={classes.editButton}
                                                        onClick={() => {
                                                            setReferenceItemToEdit(item);
                                                            setType('edit');

                                                            if (customEdit) {
                                                                setCustomEditFormVisible(true);
                                                                setTitle(
                                                                    `Редактирование справочника "${config.references.referencesHeadings[reference]}"`
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <EditIcon className={classes.icon} />
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="contained"
                                                    className={classes.deleteButton}
                                                >
                                                    <DeleteIcon
                                                        className={classes.icon}
                                                        onClick={() => handleDeleteReference(item)}
                                                    />
                                                </Button>
                                            </div>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </>
                        )) || (
                            <ListItem style={{ justifyContent: 'center' }}>
                                <CircularProgress />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            </Grid>
            {referenceItemToDelete && confirmVisible && (
                <ConfirmationDialog
                    onOk={onDeleteOk}
                    open={confirmVisible}
                    text={
                        <>
                            Вы уверены, что хотите удалить элемент &nbsp;
                            <b>"{referenceItemToDelete.name}"</b> &nbsp; из справочника &nbsp;
                            <b>"{config.references.referencesHeadings[reference]}"</b>?
                        </>
                    }
                    title="Подтвердите удаление из справочника"
                    handleClose={() => {
                        setConfirmVisible(false);
                    }}
                />
            )}
            {referenceItemToEdit &&
                Object.keys(config.references.referencesInitialValues).includes(reference) && (
                    <ReferencesForms
                        initialValues={referenceItemToEdit}
                        setVisible={setCustomEditFormVisible}
                        visible={customEditFormVisible}
                        reference={reference}
                        title={title}
                        type={type}
                    />
                )}
        </>
    );
};

ReferenceBlock.propTypes = {
    reference: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customEdit: PropTypes.bool
};

ReferenceBlock.defaultProps = {
    customEdit: false
};

export default observer(ReferenceBlock);
