import { parsePhoneNumber } from 'awesome-phonenumber';
import * as Yup from 'yup';
import Calendar from '../../utils/helpers/Calendar';
import isValidDate from '../../utils/helpers/isValidDate';

export const base2 = {
    last_name: Yup.string()
        .when('role', {
            is: (value: string) => value !== 'visitor',
            then: Yup.string().required('Необходимо указать фамилию')
        })
        .max(50, 'Слишком длинное значение')
        .matches(/^[a-zA-Zа-яА-ЯёЁ\s-]+$/gi, {
            message: 'Фамилия не может содержать специальные символы и цифры',
            excludeEmptyString: true
        })
        .nullable(),
    first_name: Yup.string()
        .max(50, 'Слишком длинное значение')
        .matches(/^[a-zA-Zа-яА-ЯёЁ\s-]+$/gi, {
            message: 'Имя не может содержать специальные символы и цифры',
            excludeEmptyString: true
        })
        .nullable()
        .required('Необходимо указать имя'),
    middle_name: Yup.string()
        .nullable()
        .max(50, 'Слишком длинное значение')
        .matches(/^[a-zA-Zа-яА-ЯёЁ\s-]+$/gi, {
            message: 'Отчество не может содержать специальные символы и цифры',
            excludeEmptyString: true
        }),
    role: Yup.string().nullable().required('Необходимо указать роль'),
    city: Yup.object()
        .shape({
            id: Yup.number().required().nullable(),
            name: Yup.string().required().nullable(),
            region: Yup.string().required().nullable()
        })
        .nullable()
        .strict()
        .when('role', {
            is: (value: string) => value !== 'visitor',
            then: Yup.object()
                .nullable()
                .shape({
                    id: Yup.number().required().nullable(),
                    name: Yup.string().required().nullable(),
                    region: Yup.string().required().nullable()
                })
                .required('Необходимо указать город')
                .strict()
        }),
    status: Yup.string()
        .nullable()
        .when('role', {
            is: (value: string) => value !== 'visitor',
            then: Yup.string().required('Необходимо указать статус')
        }),
    email: Yup.string()
        .email('Указан некорректный email')
        .nullable()
        .required('Необходимо указать email'),
    phone: Yup.string()
        .nullable()
        .when('role', {
            is: (value: string) => value !== 'visitor',
            then: Yup.string().required('Необходимо указать телефон')
        })
        .test(
            'phone',
            'Указан некорректный номер телефона. Номер должен быть указан в международном формате, начиная с +',
            (value) => (value ? parsePhoneNumber(`+${value}`).possible : true)
        ),
    birthdate: Yup.string()
        .nullable()
        .when('role', {
            is: (value: string) => value !== 'visitor',
            then: Yup.string().required('Необходимо указать дату рождения')
        })
        .test('birthdate', 'Некорректный формат даты', (value) =>
            value ? isValidDate(value) : true
        )
        .test('birthdate', 'Дата рождения не может быть в будущем', (value) =>
            value ? Calendar.compareDates(new Date(value), new Date()) <= 0 : true
        )
        .test('birthdate', 'Дата рождения не может быть такой', (value) =>
            value ? Calendar.compareDates(new Date('1900-01-01'), new Date(value)) <= 0 : true
        ),
    staff_email: Yup.string()
        .when('role', {
            is: (value: string) => ['director', 'admin'].includes(value),
            then: Yup.string()
                // .required('Необходимо указать email (STAFF)')
                .email('Указан некорректный email')
        })
        .nullable(),
    staff_phone: Yup.string()
        .when('role', {
            is: (value: string) => ['director', 'admin'].includes(value),
            then: Yup.string()
            // .required('Необходимо указать телефон (STAFF)')
        })
        .nullable()
        .test(
            'staff_phone',
            'Указан некорректный номер телефона. Номер должен быть указан в международном формате, начиная с +',
            (value) => (value ? parsePhoneNumber(`+${value}`).possible : true)
        )
};

const validationSchema = Yup.object().shape(base2);
export default validationSchema;
