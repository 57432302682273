import { useContext } from 'react';
import { StoreContext } from '../utils/context/StoreContext';

const useStore = () => {
    const context = useContext(StoreContext);

    const clear = () => {
        Object.values((store) => store.clear());
    };

    if (!context) {
        throw new Error('useRootStore must be used within RootStoreProvider');
    }

    return { ...context, clear };
};

export const useAdminDataStore = () => {
    const { adminDataStore } = useStore();
    return adminDataStore;
};

export const useApplicationsStore = () => {
    const { applicationsStore } = useStore();
    return applicationsStore;
};

export const useTournamentsStore = () => {
    const { tournamentsStore } = useStore();
    return tournamentsStore;
};

export const useUsersStore = () => {
    const { usersStore } = useStore();
    return usersStore;
};

export const useReferencesStore = () => {
    const { referencesStore } = useStore();
    return referencesStore;
};

export const usePartnersStore = () => {
    const { partnersStore } = useStore();
    return partnersStore;
};

export const usePhotoAlbumsStore = () => {
    const { photoAlbumsStore } = useStore();
    return photoAlbumsStore;
};

export const useVideosStore = () => {
    const { videosStore } = useStore();
    return videosStore;
};

export const usePublicationsStore = () => {
    const { publicationsStore } = useStore();
    return publicationsStore;
};

export const usePromotionsStore = () => {
    const { promotionsStore } = useStore();
    return promotionsStore;
};

export const useSubStageProtocolStore = () => {
    const { subStageProtocolStore } = useStore();
    return subStageProtocolStore;
};
export const useTournamentTableStoresStore = () => {
    const { tournamentTableStores } = useStore();
    return tournamentTableStores;
};

export default useStore;
