import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import config from '../../config';
import Popup from '../Popup/Popup';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useApplicationsStore } from '../../hooks/useStore';
import { getPlayerFullName } from '../../utils/helpers';
import Avatar from '../Avatar/Avatar';
import { useAxios } from '../../hooks';
import { ToastContext } from '../../utils/context';

import useStyles from './styles';

const ChooseTournamentPartner = ({
    selectPartnerVisible,
    setSelectPartnerVisible,
    tournamentForPartner,
    partner
}) => {
    const classes = useStyles();
    const applicationsStore = useApplicationsStore();
    const { addSuccessToast } = useContext(ToastContext);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [availablePartners, setAvailablePartners] = useState([]);
    const [value, setValue] = useState(
        Object.keys(partner).length ? { ...partner, name: getPlayerFullName(partner) } : null
    );
    const [isLoading, setIsLoading] = useState(false);
    const { request: getTournamentPlayers } = useAxios({
        method: 'get',
        url: `${config.server.serviceHost}${config.server.api.tournamentPlayers(
            tournamentForPartner.tournamentId
        )}`,
        params: {
            status: config.references.requestStatusOptions.draft,
            with_partner: +false
        }
    });
    const { request: mergeRequests } = useAxios({
        method: 'post',
        url: `${config.server.serviceHost}${config.server.api.mergeRequests(
            tournamentForPartner.tournamentId
        )}`
    });
    const { request: invitePlayer } = useAxios({
        method: 'post',
        url: `${config.server.serviceHost}${config.server.api.invitePlayer.uri}`
    });

    const onOk = async () => {
        if (value.requestId) {
            await mergeRequests({
                request_1: tournamentForPartner.requestId,
                request_2: value.requestId
            });

            applicationsStore.updateApplication(tournamentForPartner.requestId, value, 'player_2');
            applicationsStore.removeApplication(value.requestId);
            addSuccessToast('Заявки были успешно объединены');
        } else {
            await invitePlayer({
                tournament_id: tournamentForPartner.tournamentId,
                player_id: value.id
            });

            addSuccessToast('Приглашение было успешно выслано');
        }
        setConfirmVisible(false);
        setSelectPartnerVisible(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const requestsAvailableToMerge = await getTournamentPlayers();

            const enhancedPlayers = requestsAvailableToMerge
                .map(({ id, player }) => ({
                    requestId: id,
                    name: getPlayerFullName(player),
                    ...player
                }))
                .filter(({ id }) => id !== tournamentForPartner.playerId);

            setAvailablePartners(enhancedPlayers);
            setIsLoading(false);
        })();
    }, []);

    return (
        <>
            {tournamentForPartner && (
                <Popup
                    setVisible={setSelectPartnerVisible}
                    visible={selectPartnerVisible}
                    title="Выберите партнера"
                >
                    {isLoading && (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )}
                    {!isLoading && (
                        <Grid container spacing={3}>
                            {availablePartners.length ? (
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            name="partner"
                                            options={availablePartners}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ''
                                            }
                                            value={value}
                                            classes={{
                                                root: classes.autocomplete,
                                                input: classes.input,
                                                inputFocused: classes.input,
                                                listbox: classes.input
                                            }}
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            onChange={(e, value) => {
                                                const newFieldValue = value || null;

                                                setValue(newFieldValue);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Avatar user={option} />
                                                    <span style={{ marginLeft: 10 }}>
                                                        {option.first_name} {option.last_name}
                                                        {','} {option.city}
                                                    </span>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Выбор партнера"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.btn}
                                            color="primary"
                                            variant="contained"
                                            disabled={!value}
                                            onClick={() => setConfirmVisible(true)}
                                            size="large"
                                        >
                                            Создать пару
                                        </Button>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">
                                        Игроки для выбора не найдены
                                    </Typography>
                                </Grid>
                            )}
                            {confirmVisible && (
                                <ConfirmationDialog
                                    onOk={onOk}
                                    open={confirmVisible}
                                    text="Вы уверены, что хотите создать пару?"
                                    title="Подтвердите создание новой пары игроков"
                                    handleClose={() => {
                                        setConfirmVisible(false);
                                    }}
                                />
                            )}
                        </Grid>
                    )}
                </Popup>
            )}
        </>
    );
};

ChooseTournamentPartner.propTypes = {
    selectPartnerVisible: PropTypes.bool.isRequired,
    tournamentForPartner: PropTypes.shape({
        requestId: PropTypes.number,
        playerId: PropTypes.number,
        tournamentId: PropTypes.number
    }).isRequired,
    setSelectPartnerVisible: PropTypes.func.isRequired,
    partner: PropTypes.oneOf([PropTypes.object, null])
};

ChooseTournamentPartner.defaultProps = {
    partner: null
};

export default ChooseTournamentPartner;
