import { createContext } from 'react';
import AdminDataStore from './adminDataStore/adminDataStore';
import ApplicationsStore from './applicationsStore';
import TournamentsStore from './tournamentsStore';
import UsersStore from './usersStore';
import ReferencesStore from './referencesStore';
import PartnersStore from './partnersStore';
import PhotoAlbumsStore from './photoAlbumsStore';
import VideosStore from './videosStore';
import PublicationsStore from './publicationsStore';
import PromotionsStore from './promotionsStore';
import SubStageProtocolStore from './substageProtocolStore';
import TournamentTableStores from './tournamentTableStore/tournament.tableStores';

class RootStoreClass {
    adminDataStore = new AdminDataStore(this);

    applicationsStore = new ApplicationsStore(this);

    tournamentsStore = new TournamentsStore(this);

    usersStore = new UsersStore(this);

    referencesStore = new ReferencesStore(this);

    partnersStore = new PartnersStore(this);

    photoAlbumsStore = new PhotoAlbumsStore(this);

    videosStore = new VideosStore(this);

    publicationsStore = new PublicationsStore(this);

    promotionsStore = new PromotionsStore(this);

    subStageProtocolStore = new SubStageProtocolStore(this);

    tournamentTableStores = new TournamentTableStores(this);
}

export const rootStore = new RootStoreClass();

export const RootStoreContext = createContext(rootStore);
