import { makeAutoObservable } from 'mobx';
import { get, set } from 'lodash';

export default class SubStageProtocolStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    protocol = {};

    scores = {};

    matchesSets = {};

    get getProtocol() {
        return this.protocol;
    }

    setProtocol = (protocol) => {
        this.protocol = protocol;

        return this;
    };

    get getScores() {
        return this.scores;
    }

    setScores = (scores) => {
        this.scores = scores;

        return this;
    };

    getInScores = (path) => get(this.scores, path);

    setInScores = (path, value) => {
        // TODO: в групповой что-то ломалось...
        try {
            set(this.scores, path, value);
        } catch (error) {
            console.warn(error);
        }
    };

    getMatchSets = (matchId) => this.matchesSets[matchId];

    setMatchSets = (matchId, sets) => {
        this.matchesSets[matchId] = sets;
    };

    getInProtocol = (path) => get(this.protocol, path);

    setInProtocol = (path, value) => {
        set(this.protocol, path, value);

        return this;
    };

    setInMatches = ([matchId, setIdx], value) => {
        this.matchesSets[matchId][setIdx] = value;

        return this;
    };

    removeInMatches = (matchId, setToRemove) => {
        this.matchesSets[matchId] = this.matchesSets[matchId].filter(
            (set) => set.id !== setToRemove
        );
    };

    clearStore = () => {
        // This array accepts all fields that have an empty object as initial value
        ['protocol, scores', 'matchesSets'].forEach((key) => {
            this[key] = {};
        });
        console.warn('ReferencesStoSubStageProtocolStoree clear');
        return this;
    };
}
