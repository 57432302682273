import { makeAutoObservable } from 'mobx';

export default class TournamentsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    tournaments = [];

    getTournamentBy = (key, value) =>
        this.tournaments.find((tournament) => tournament[key] === value);

    setTournaments = (tournaments) => {
        this.tournaments = tournaments;
    };

    updateTournament = (id, newTournamentValues) => {
        const tournamentIndex = this.tournaments.findIndex((tournament) => tournament.id === id);

        this.tournaments[tournamentIndex] = {
            ...this.tournaments[tournamentIndex],
            ...newTournamentValues
            // orderNumber: this.tournaments?.[tournamentIndex]?.orderNumber // TODO: вот эта чушь выдает TypeError
        };
    };

    updateTournamentField = (id, value, field) => {
        const tournamentIndex = this.tournaments.findIndex((user) => user.id === id);
        this.tournaments[tournamentIndex] = {
            ...this.tournaments[tournamentIndex],
            [field]: value
        };
    };

    addTournament = (tournamentToAdd) => {
        const oldTournaments = this.tournaments.map((tournament) => ({
            ...tournament,
            orderNumber: tournament.orderNumber + 1
        }));

        this.tournaments = [tournamentToAdd, ...oldTournaments];
    };

    removeTournament = (id) => {
        this.tournaments = this.tournaments
            .filter((tournament) => tournament.id !== id)
            .map((tournament, idx) => ({
                ...tournament,
                orderNumber: idx + 1
            }));
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['tournaments'].forEach((key) => {
            this[key] = [];
        });
        console.warn('TournamentsStore clear');
    };
}
