import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';

export default class PhotoAlbumsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    photoAlbums = [];

    page = 1;

    title = null;

    page_size = 6;

    state = null;

    dates = { gte: null, lte: null };

    setPhotoAlbums = ({ results, count }) => {
        this.count = count || 0;
        this.photoAlbums = results || [];
    };

    clearQuery = () => {
        this.page = 1;
        this.title = null;
        this.page_size = 6;
        this.state = null;
        this.dates = { gte: null, lte: null };
    };

    get queryParams() {
        const { page, page_size, title, state } = this;

        let gte = null;
        let lte = null;

        if (this.dates.gte) {
            try {
                gte = format(this.dates.gte, 'yyyy-MM-dd');
            } catch (er) {
                //
            }
        }
        if (this.dates.lte) {
            try {
                lte = format(this.dates.lte, 'yyyy-MM-dd');
            } catch (er) {
                //
            }
        }

        return {
            page: page > 1 ? page : null,
            page_size,
            title: title || null,
            state: state || null,
            gte,
            lte
        };
    }

    get datesAsArray() {
        const { gte, lte } = this.dates;
        return [gte, lte];
    }

    get dateRangePickerRanges() {
        const { gte, lte } = this.dates;
        return [{ startDate: gte, endDate: lte }];
    }

    setDates = ([gte, lte]) => {
        this.dates = { gte, lte };
    };

    setDateRangePickerRanges = ({ startDate, endDate }) => {
        this.dates = { gte: startDate, lte: endDate };
    };

    setTitle = (title) => {
        this.title = title;
        this.page = 1;
    };

    setState = (state) => {
        this.state = state;
    };

    handlePageChange = (_, newPage) => {
        if (newPage !== this.page) {
            this.page = newPage;
        }
    };

    updatePhotoAlbum = (id, value, field) => {
        const albumIndex = this.photoAlbums.findIndex((album) => album.id === id);
        this.photoAlbums[albumIndex] = {
            ...this.photoAlbums[albumIndex],
            [field]: value
        };
    };

    removePhotoAlbum = (id) => {
        this.photoAlbums = this.photoAlbums.filter((album) => album.id !== id);
        this.count -= 1;
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['photoAlbums'].forEach((key) => {
            this[key] = [];
        });
        console.warn('PhotoAlbumsStore clear');
    };
}
