import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, List } from '@mui/material';
import styles from './Promocodes.module.scss';
import { promocodeTypes } from './Promocodes.schema';
import PromocodesListitem from './Promocodes.listItem';

const PromocodesList = ({ list, isLoading }) =>
    (!isLoading && (
        <List className={styles.promocodesList}>
            {list.map((promocode) => (
                <PromocodesListitem key={promocode.id} promocode={promocode} />
            ))}
        </List>
    )) || (
        <div className={styles.loader}>
            <CircularProgress />
        </div>
    );

PromocodesList.propTypes = {
    isLoading: PropTypes.bool,
    list: PropTypes.arrayOf(promocodeTypes)
};

export default PromocodesList;
