import { camelCase, differenceBy, flatten, isError } from 'lodash';
import { makeAutoObservable } from 'mobx';
import config from '../../../config';
import differenceBetweenObjects from '../../../utils/helpers/differenceBetweenObjects';
import SubstageClass from './Substage.class';

const SCHEMA = {
    id: null,
    name: '',
    order: null,
    players: null,
    stage_category: '',
    stage_type: '',
    tournament: null,
    rating_stage: true,
    has_third_place_match: true
};

const DEFAULT_VALUES = {
    ...SCHEMA,
    substages: null,
    inited: false
};

const SUBSTAGE_CATEGORY = config.references.subStageTypes.reduce((acc, cur) => {
    acc[camelCase(cur.id)] = cur.id;
    return acc;
}, {});

const STAGE_TYPES = config.references.stageTypes.reduce((acc, cur) => {
    acc[camelCase(cur.id)] = cur.id;
    return acc;
}, {});

class StageClass {
    substages = null;

    constructor(api, values = {}, parent, autoInit = false) {
        this.api = api;
        this.parent = parent;
        const players = this.findPlayersFromParticipants(values.players);

        const initialValues = { ...SCHEMA, ...values, players };
        this.initialValues = initialValues;

        Object.entries({ ...DEFAULT_VALUES, ...initialValues }).forEach(([key, value]) => {
            this[key] = value;
        });
        this.players = players;

        if (autoInit && this.id) {
            this.init();
        }

        makeAutoObservable(this);
    }

    get basicSubstages() {
        return (
            (this.substages &&
                this.substages.filter((substage) =>
                    ['other', 'semifinal', 'final'].includes(substage.type)
                )) ||
            []
        );
    }

    get otherSubstages() {
        return (
            (this.substages &&
                this.substages.filter(
                    (substage) => !['other', 'semifinal', 'final'].includes(substage.type)
                )) ||
            null
        );
    }

    get getData() {
        return Object.keys(SCHEMA).reduce((acc, curr) => {
            acc[curr] = this[curr];
            return acc;
        }, {});
    }

    // TODO:  условия
    get canDeterminePlaces() {
        switch (this.stage_type) {
            case STAGE_TYPES.playoff:
                return !!this.id;
            case STAGE_TYPES.single:
                return !!this.id;
            default:
                return !!this.id;
        }
    }

    // TODO:  условия: не завершен прием заявок, турнир закрыт
    get canAutoProcessStage() {
        switch (this.stage_type) {
            case STAGE_TYPES.playoff:
                return !!this.id;
            case STAGE_TYPES.single:
                return !!this.id;
            default:
                return !!this.id;
        }
    }

    get canUploadTable() {
        return !!this.id; // TODO:  условия
    }

    get usedPlayers() {
        return flatten(this?.substages.map((substage) => substage.players)) || [];
    }

    get unusedPlayers() {
        return differenceBy(this.players, this.usedPlayers, 'id');
    }

    findPlayersFromParticipants = (ids) => {
        // TODO: Сделать проверку на arrayOf
        if (!this.inited && ids) {
            if (typeof ids[0] === 'object') {
                return ids;
            }
            return ids.map((id) => this.parent.participants.find((player) => id === player.id));
        }
        return this.players;
    };

    init = async (enableReinit = false) => {
        // this.inited = false;
        if (!this.inited || enableReinit) {
            await this.loadSubstages();
            // await this.loadStagePlaces();
        }
        this.inited = true;
    };

    loadThisStage = async () => {
        if (this.id) {
            const stage = this.api.getTournamentStage(this.id);
            return this.setData(stage);
        }

        console.warn('Не задан id стейджа');
        return null;
    };

    uploadStage = async () => {
        if (this.id) {
            const payload = differenceBetweenObjects(this.getData, this.initialValues);

            if (!Object.keys(payload).length) {
                return { ...this.getData, ok: true, changes: null };
            }

            if (payload.players) {
                payload.players = this.players.map((player) => player.id);
            }
            try {
                const stage = await this.api.patchStage({
                    stageId: this.id,
                    payload
                });
                if (stage) {
                    this.initialValues = { ...stage, players: this.players };
                    return {
                        ...this.setData({ ...stage, players: this.players }),
                        ok: true,
                        changes: payload
                    };
                }
            } catch (error) {
                this.setData({ ...this.initialValues });
                return { ...this.getData, ok: !isError(error), changes: null };
            }
            return { ...this.getData, ok: true, changes: null };
        }
        const stage = await this.api.createTournamentStage({
            ...this.getData,
            players: this.players.map((player) => player.id)
        });
        if (stage) {
            this.initialValues = { ...stage, players: this.players };
            return {
                ...this.setData({ ...stage, players: this.players }),
                ok: true,
                changes: this.getData
            };
        }
    };

    deleteStage = async () => {
        if (this.id) {
            await this.api.deleteStage(this.id);
            this.parent.deleteStage(this.id);
        }
    };

    uploadTable = async () => {};

    setData = (data) => {
        Object.entries(data).forEach(([key, value]) => this.updateField(key, value));
        return this.getData;
    };

    updateField = (key, value) => {
        this[key] = value;
        return this[key];
    };

    setSubstages = (substages) => {
        this.substages = substages;
    };

    deleteSubstage = (id) => {
        this.setSubstages(this.substages.filter((substage) => substage.id !== id));
    };

    newSubstage = () => new SubstageClass(this.api, this, { tournament_stage: this.id });

    addSubstage = (substage) => {
        if (substage.constructor === SubstageClass) {
            this.substages = [...this.substages, substage];
            substage.init(true);
        } else {
            this.substages = [...this.substages, new SubstageClass(this.api, this, substage)];
        }
    };

    loadSubstages = async () => {
        const substages = await this.api.getStageSubstages(this.id);

        this.setSubstages(substages.map((substage) => new SubstageClass(this.api, this, substage)));
        return this.substages;
    };

    initSubstages = async () => {
        this.substages?.forEach((substage) => substage.init());
    };

    loadDiffSubstages = async () => {
        const substages = await this.api.getStageSubstages(this.id);
        const tSubstages = this.substages?.map((tSubstages) => tSubstages.id) || [];

        const newSubstages = substages.filter((substage) => !tSubstages.includes(substage.id));

        this.setSubstages(
            [
                ...this.substages,
                ...newSubstages.map((substage) => new SubstageClass(this.api, this, substage))
            ].sort((a, b) => a.id - b.id)
        );
        return this.substages;
    };

    handleAutoProcessStage = async ({ payload = null }) => {
        const response = await this.api.handleAutoProcessStage(this.id, payload);
        if (response) {
            if (payload) {
                this.parent.initialLoad();
            } else {
                this.loadDiffSubstages();
            }
        }
    };

    handleDeterminePlaces = async () => {
        // TODO: ручка возвращает места, но с id внутри стейджа, а не сабстейджа, поэтому не будет работать патч
        // const places = await this.api.handleDeterminePlaces(this.id);
        await this.api.handleDeterminePlaces(this.id);
        this.substages.forEach((substage) => {
            substage.loadSubstagePlaces();
        });
    };
}

export default StageClass;
export { SCHEMA };
