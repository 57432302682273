import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';

const usePopupStyles = makeStyles((theme) => ({
    closeButton: {
        backgroundColor: tinycolor(theme.palette.primary.main).lighten(45).toString(),
        borderRadius: 10,
        marginRight: '0 !important',

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(40).toString()
        }
    },
    root: {
        height: '90%'
    },
    content: {
        minHeight: '5rem'
    },
    titleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

export default usePopupStyles;
