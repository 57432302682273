import { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Alert, Snackbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isArray, isUndefined } from 'lodash';
import { useToastPortal } from '../../hooks';
import useToastPortalStyles from './styles';

const ToastPortal = forwardRef(({ autoCloseTime }, ref) => {
    const classes = useToastPortalStyles();
    const [toasts, setToasts] = useState([]);
    const { loaded, portalId } = useToastPortal();

    const removeToast = (id) => setToasts(toasts.filter((toast) => toast.id !== id));

    const handleClose = (_, reason, id) => {
        if (reason === 'clickaway') {
            return;
        }

        removeToast(id);
    };

    useImperativeHandle(
        ref,
        () => ({
            addMessage(toast) {
                setToasts((prevToasts) => [{ ...toast, id: uuid() }, ...prevToasts]);
            }
        }),
        /* eslint-disable-next-line */
        []
    );

    return loaded
        ? ReactDOM.createPortal(
              <div className={classes.toastContainer}>
                  {toasts.map((toast) => (
                      <Snackbar
                          autoHideDuration={
                              isUndefined(toast.autoCloseTime) ? autoCloseTime : toast.autoCloseTime
                          }
                          open={Boolean(toasts.find(({ id }) => id === toast.id))}
                          key={toast.id}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={(e, reason) => handleClose(e, reason, toast.id)}
                          classes={{
                              root: classes.toast
                          }}
                      >
                          <Alert severity={toast.mode} onClose={() => removeToast(toast.id)}>
                              {isArray(toast.message) ? (
                                  <>
                                      {toast.message.map((message, index) => (
                                          <Typography key={index} variant="h6">
                                              {message}
                                          </Typography>
                                      ))}
                                  </>
                              ) : (
                                  <Typography variant="h6">{toast.message}</Typography>
                              )}
                          </Alert>
                      </Snackbar>
                  ))}
              </div>,
              document.getElementById(portalId)
          )
        : null;
});

ToastPortal.displayName = 'ToastPortal';

ToastPortal.propTypes = {
    // autoClose: PropTypes.bool,
    autoCloseTime: PropTypes.number
};

ToastPortal.defaultProps = {
    // autoClose: false,
    autoCloseTime: 3000
};

export default ToastPortal;
