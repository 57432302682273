import { useContext } from 'react';
import { useAxios } from '../../hooks';
import config from '../../config';
import { useReferencesStore } from '../../hooks/useStore';
import { ToastContext } from '../../utils/context';

const useLogic = ({
    reference,
    referenceItemToDelete,
    referenceItemToEdit,
    setConfirmVisible,
    setReferenceItemToEdit,
    setInlineAddFormVisible,
    host
}) => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { request: deleteReference } = useAxios({
        method: 'delete',
        url: `${host}${config.server.apiPublic[reference].uri}${referenceItemToDelete?.id}/`
    });
    const { request: patchReference } = useAxios({
        method: 'patch',
        url: `${host}${config.server.apiPublic[reference].uri}${referenceItemToEdit?.id}/`
    });
    const { request: createReference } = useAxios({
        method: 'post',
        url: `${host}${config.server.apiPublic[reference].uri}`
    });

    const onDeleteOk = async () => {
        await deleteReference();

        referencesStore.removeReferenceItem(reference, referenceItemToDelete?.id);
        setConfirmVisible(false);
        addSuccessToast('Значение было успешно удалено из справочника');
    };

    const handleInlineEdit = async (values) => {
        const patchedReferenceData = await patchReference({ ...referenceItemToEdit, ...values });

        referencesStore.updateReferenceItem(
            reference,
            patchedReferenceData.id,
            patchedReferenceData
        );

        addSuccessToast('Изменения в справочник были успешно внесены');
        setReferenceItemToEdit(null);
    };

    const handleInlineSubmit = async (values) => {
        const newReferenceData = await createReference({ ...values });

        referencesStore.setReference(reference, [
            newReferenceData,
            ...referencesStore.getReference(reference)
        ]);

        addSuccessToast('Значение было успешно добавлено в справочник');
        setInlineAddFormVisible(false);
    };

    return {
        onDeleteOk,
        handleInlineEdit,
        handleInlineSubmit
    };
};

export default useLogic;
