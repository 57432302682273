import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import usePopupStyles from './styles';
import PopupContext from './Popup.context';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
Transition.displayName = 'Transition';

const Popup = ({
    title,
    children,
    visible,
    setVisible,
    onClose,
    width,
    fullHeight,
    fullScreen
}) => {
    const classes = usePopupStyles();
    const handleCLose = () => {
        setVisible(false);
        onClose();
    };

    return (
        <PopupContext.Provider value={{ visible, setVisible }}>
            <Dialog
                open={visible}
                maxWidth={width}
                classes={{ paper: fullHeight ? classes.root : null }}
                fullScreen={fullScreen}
                TransitionComponent={Transition}
                fullWidth
            >
                <DialogTitle>
                    <div className={classes.titleWrapper}>
                        <Typography variant="h4" component="h4">
                            {title}
                        </Typography>
                        <IconButton
                            classes={{ root: classes.closeButton }}
                            onClick={handleCLose}
                            size="large"
                        >
                            <CloseIcon color="primary" style={{ fontSize: 20 }} />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.content}>{children}</DialogContent>
            </Dialog>
        </PopupContext.Provider>
    );
};

Popup.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    width: PropTypes.string,
    fullHeight: PropTypes.bool,
    fullScreen: PropTypes.bool
};

Popup.defaultProps = {
    onClose: () => {},
    width: 'md',
    fullHeight: false,
    fullScreen: false
};

export default Popup;
