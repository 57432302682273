import { makeAutoObservable, ObservableMap } from 'mobx';
import TournamentTableStore from './tournament.tableStore';

export default class TournamentTableStores {
    stores = new ObservableMap();

    constructor(rootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    setTable = (tournamentId) => {
        const store = new TournamentTableStore(this.rootStore, tournamentId);
        this.stores.set(tournamentId, store);
        return store;
    };

    getTable = (tournamentId) => {
        if (this.stores.has(tournamentId)) {
            const store = this.stores.get(tournamentId);
            return store;
        }

        return this.setTable(tournamentId);
    };

    removeTable = (tournamentId) => {
        this.stores.delete(tournamentId);
    };

    clearStore = () => {
        this.stores = new ObservableMap();
    };
}
