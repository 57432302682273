import { useCallback, useContext, useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray, FastField } from 'formik';
import Button from '@mui/material/Button';
import { SketchPicker } from 'react-color';
// import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
// import Grid from '@mui/material/Grid';
// import SettingsIcon from '@mui/icons-material/Settings';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
import { isEqual, unionWith } from 'lodash';

import Popup from '../Popup/Popup';
import FormField from '../FormField';
import { useReferencesStore } from '../../hooks/useStore';
import config from '../../config';
import { selectChangedProperties } from '../../utils/helpers';
import ReferencesContext from '../../utils/context/ReferencesContext';
import { useAxios, useGetReferences } from '../../hooks';

import useReferencesFormsStyles from './styles';
import { ToastContext } from '../../utils/context';
import differenceBetweenObjects from '../../utils/helpers/differenceBetweenObjects';

const DEFAULT_POINTS = [
    {
        place: '1',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '2',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '3',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '1/2',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '1/4',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '1/8',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '1/16',
        points: 0,
        stage_category: 'basic'
    },
    {
        place: '1',
        points: 0,
        stage_category: 'additional'
    }
];

// TODO: Написать схемы валидации и перенести их в свой файл
const CategoriesValidationSchema = Yup.object().shape({});
const CitiesValidationSchema = Yup.object().shape({});
const SportsValidationSchema = Yup.object().shape({
    name: Yup.string().required('Необходимо указать название разряда'),
    is_pair: Yup.bool().required('Необходимо указать праность разряда')
});
const RegionsValidationSchema = Yup.object().shape({});

const TournamentCategoriesForm = ({ initialValues, handleSubmit, setInitialPoints, formType }) => {
    const classes = useReferencesFormsStyles();
    const [combinedInitialValues, setCombinedInitialValues] = useState({
        ...initialValues,
        points: [...DEFAULT_POINTS]
    });
    const { request: getTournamentsCategoryPoints, loading } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.tournamentCategoryPoints(
            initialValues.id
        )}`
    });

    const [groups, setGroups] = useState([]);

    const { request: getGroups } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.categoryGroups.uri}`
    });

    const loadGroups = async () => {
        const data = await getGroups();
        if (data) {
            setGroups(data);
        }
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (formType === 'edit') {
            (async () => {
                const categoryPoints = await getTournamentsCategoryPoints();

                if (categoryPoints.status !== 404) {
                    setCombinedInitialValues({
                        ...combinedInitialValues,
                        points: unionWith(
                            categoryPoints,
                            DEFAULT_POINTS,
                            (el1, el2) =>
                                el1.place === el2.place && el1.stage_category === el2.stage_category
                        )
                    });
                    setInitialPoints(categoryPoints);
                }
            })();
        } else {
            setCombinedInitialValues({
                ...combinedInitialValues,
                points: [...DEFAULT_POINTS]
            });
        }
    }, []);

    return (
        <Formik
            initialValues={combinedInitialValues}
            validationSchema={CategoriesValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                values,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                errors,
                touched,
                isValid
            }) => (
                <Form>
                    <FormField.Text
                        label="Название"
                        name="name"
                        type="text"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.name}
                        errors={errors.name}
                    />
                    <FormField.Text
                        label="Подсказка"
                        name="note"
                        value={values.note}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.note}
                        errors={errors.note}
                        multiline
                    />
                    <FormField.Select
                        label="Группа"
                        name="group"
                        value={values.group}
                        // setFieldValue={setFieldValue}
                        setTouched={setFieldTouched}
                        handleChange={handleChange}
                        touched
                        errors={errors.group}
                        options={groups}
                    />
                    <FormField.Checkbox
                        label="Сервисный сбор"
                        name="service_fee"
                        value={values.service_fee}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        touched={touched.service_fee}
                        errors={errors.service_fee}
                    />
                    <div className={classes.pointsTable}>
                        <Typography variant="h5">Распределение очков:</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography variant="h5">Место</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5">Очки</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name="points">
                                    {() =>
                                        values?.points.map((pointsRow, idx) => {
                                            const pointErrors =
                                                (errors.points?.length && errors.points[idx]) || {};
                                            const pointTouched =
                                                (touched.points?.length && touched.points[idx]) ||
                                                {};
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        <Typography variant="h6">
                                                            {pointsRow.stage_category ===
                                                            'additional'
                                                                ? 'Доп. очки'
                                                                : pointsRow.place}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <FastField name={`points.${idx}.points`}>
                                                            {() => (
                                                                <FormField.Number
                                                                    placeholder="Количество рейтинговых очков"
                                                                    min={0}
                                                                    name={`points.${idx}.points`}
                                                                    value={pointsRow.points}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    touched={pointTouched?.points}
                                                                    errors={pointErrors?.points}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </FieldArray>
                            </TableBody>
                        </Table>
                    </div>
                    <Button
                        disabled={loading || !isValid}
                        className={classes.btn}
                        size="large"
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Сохранить
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const EditTournamentCategory = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast, addInfoToast } = useContext(ToastContext);
    const [initialPoints, setInitialPoints] = useState([]);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: patchRequest } = useAxios({
        method: 'patch'
    });
    const { request: postPoints } = useAxios({
        method: 'post'
    });
    const { request: putPoints } = useAxios({
        method: 'put'
    });

    const handleSubmit = async ({ id, name, note, service_fee, points, group }) => {
        const infoValues = { id, name, service_fee, note, group };

        if (!isEqual(initialValues, infoValues)) {
            const changedValues = selectChangedProperties(initialValues, infoValues);

            const patchedCategory = await patchRequest(
                changedValues,
                null,
                null,
                `${config.server.serviceHostPublic}${config.server.apiPublic.tournamentCategories.uri}${id}/`
            );

            referencesStore.updateReferenceItem(
                'tournamentCategories',
                patchedCategory.id,
                patchedCategory
            );
        }

        if (!isEqual(initialPoints, points)) {
            try {
                await postPoints(
                    points,
                    null,
                    null,
                    `${
                        config.server.serviceHostPublic
                    }${config.server.apiPublic.tournamentCategoryPoints(id)}`
                );
            } catch (e) {
                if (e?.response?.status === 404) {
                    await putPoints(
                        points,
                        null,
                        null,
                        `${
                            config.server.serviceHostPublic
                        }${config.server.apiPublic.tournamentCategoryPoints(id)}`
                    );
                }
            }
            addSuccessToast('Изменения в справочник были успешно внесены');
        } else {
            addInfoToast('Без изменений');
        }

        setVisible(false);
    };

    return (
        <TournamentCategoriesForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            setInitialPoints={setInitialPoints}
            formType="edit"
        />
    );
};

const CreateTournamentCategory = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: createTournamentCategory } = useAxios({
        method: 'post',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.tournamentCategories.uri}`
    });
    const { request: createPoints } = useAxios({
        method: 'put'
    });

    const handleSubmit = async ({ name, note, service_fee, points, group }) => {
        const infoValues = { name, note, service_fee, group };

        const newCategoryData = await createTournamentCategory(infoValues);

        referencesStore.setReference('tournamentCategories', [
            newCategoryData,
            ...referencesStore.getReference('tournamentCategories')
        ]);
        referencesStore.setReference('filteredTournamentCategories', [
            newCategoryData,
            ...referencesStore.getReference('filteredTournamentCategories')
        ]);

        if (points.length) {
            /* eslint-disable-next-line */
            const data = await createPoints(
                points,
                null,
                null,
                `${
                    config.server.serviceHostPublic
                }${config.server.apiPublic.tournamentCategoryPoints(newCategoryData.id)}`
            );
        }

        addSuccessToast('Значение было успешно добавлено в справочник');
        setVisible(false);
    };

    return (
        <TournamentCategoriesForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            formType="create"
        />
    );
};

const CitiesForm = ({ initialValues, handleSubmit }) => {
    const classes = useReferencesFormsStyles();
    // const referencesStore = useReferencesStore();
    const { getRegions } = useGetReferences();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={CitiesValidationSchema}
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setFieldTouched,
                setFieldValue
            }) => (
                <Form>
                    <FormField.Text
                        label="Название"
                        name="name"
                        type="text"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.name}
                        errors={errors.name}
                    />
                    <FormField.AsyncAutoComplete
                        label="Регион"
                        name="region"
                        value={values.region}
                        setTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        // options={referencesStore.regions}
                        touched={touched.region}
                        errors={errors.region}
                        request={getRegions}
                    />
                    <Button
                        className={classes.btn}
                        disabled={!isValid}
                        size="large"
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Сохранить
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const EditCity = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: patchCity } = useAxios({
        method: 'patch',
        url: `${config.server.serviceHost}${config.server.apiPublic.cities.uri}${initialValues.id}/`
    });

    const handleSubmit = async (values) => {
        const newData = { ...values, region: values.region.id };

        const patchedCity = await patchCity(newData);

        referencesStore.updateReferenceItem('cities', patchedCity.id, values);
        // referencesStore.updateReferenceItem('filteredCities', patchedCity.id, patchedCity);
        addSuccessToast('Изменения в справочник были успешно внесены');
        setVisible(false);
    };

    return <CitiesForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

const CreateCity = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: createCity } = useAxios({
        method: 'post',
        url: `${config.server.serviceHost}${config.server.apiPublic.cities.uri}`
    });

    const handleSubmit = async (values) => {
        const newCityData = { ...values, region: values.region.id };

        const newCity = await createCity(newCityData);

        referencesStore.setReference('cities', [
            newCity,
            ...referencesStore.getReference('cities')
        ]);
        referencesStore.setReference('filteredCities', [
            newCity,
            ...referencesStore.getReference('filteredCities')
        ]);
        addSuccessToast('Значение было успешно добавлено в справочник');
        setVisible(false);
    };

    return <CitiesForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

const RegionsForm = ({ initialValues, handleSubmit }) => {
    const classes = useReferencesFormsStyles();

    const { getCountries } = useGetReferences();

    const [currentCountry, setCurrentCountry] = useState('');

    const getCurrentCountry = useCallback(async () => {
        const country = await getCountries(
            null,
            null,
            null,
            `${config.server.serviceHostPublic}${config.server.apiPublic.countries.uri}${initialValues.country}/`
        );
        setCurrentCountry(country);
    }, [initialValues.country]);

    useEffect(() => {
        getCurrentCountry();
    }, [getCurrentCountry]);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                country: currentCountry
            }}
            enableReinitialize
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={RegionsValidationSchema}
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setFieldTouched,
                setFieldValue
            }) => (
                <Form>
                    <FormField.Text
                        label="Название"
                        name="name"
                        type="text"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.name}
                        errors={errors.name}
                    />
                    <FormField.AsyncAutoComplete
                        label="Страна"
                        name="country"
                        value={values.country}
                        setTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        // options={referencesStore.countries}
                        touched={touched.country}
                        errors={errors.country}
                        request={getCountries}
                    />
                    <div className={classes.colorBlock}>
                        <Typography variant="h5" className={classes.colorTitle}>
                            Цвет региона:
                        </Typography>
                        <SketchPicker
                            color={values.region_color}
                            onChange={({ hex }) => setFieldValue('region_color', hex)}
                        />
                    </div>
                    <Button
                        className={classes.btn}
                        disabled={!isValid}
                        size="large"
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Сохранить
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const EditRegion = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: patchRegion } = useAxios({
        method: 'patch',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.regions.uri}${initialValues.id}/`
    });

    const handleSubmit = async (values) => {
        const newData = { ...values, country: values.country.id };

        const patchedRegion = await patchRegion(newData);

        referencesStore.updateReferenceItem('regions', patchedRegion.id, patchedRegion);
        // referencesStore.updateReferenceItem('filteredRegions', patchedRegion.id, patchedRegion);
        addSuccessToast('Изменения в справочник были успешно внесены');
        setVisible(false);
    };

    return <RegionsForm initialValues={initialValues} handleSubmit={handleSubmit} />;
};

const CreateRegion = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: createRegion } = useAxios({
        method: 'post',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.regions.uri}`
    });

    const handleSubmit = async (values) => {
        const newRegionData = { ...values, country: values.country.id };

        const newRegion = await createRegion(newRegionData);

        referencesStore.setReference('regions', [
            newRegion,
            ...referencesStore.getReference('regions')
        ]);
        referencesStore.setReference('filteredRegions', [
            newRegion,
            ...referencesStore.getReference('filteredRegions')
        ]);
        addSuccessToast('Значение было успешно добавлено в справочник');
        setVisible(false);
    };

    return <RegionsForm initialValues={initialValues} handleSubmit={handleSubmit} />;
};

const ClubsForm = ({ initialValues, handleSubmit }) => {
    const classes = useReferencesFormsStyles();

    const { getCities } = useGetReferences();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={CitiesValidationSchema}
            enableReinitialize
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setFieldTouched,
                setFieldValue
            }) => (
                <Form>
                    <FormField.Text
                        label="Название"
                        name="name"
                        type="text"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.name}
                        errors={errors.name}
                    />
                    <FormField.AsyncAutoComplete
                        label="Город"
                        name="city"
                        value={values.city}
                        setTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched.city}
                        errors={errors.city}
                        request={getCities}
                    />
                    <FormField.Text
                        label="Адрес"
                        name="address"
                        type="text"
                        value={values.address}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.address}
                        errors={errors.address}
                    />
                    <Button
                        className={classes.btn}
                        disabled={!isValid}
                        size="large"
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Сохранить
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const CreateClub = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: createClub } = useAxios({
        method: 'post',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.clubs.uri}`
    });

    const handleSubmit = async (values) => {
        const newClubData = { ...values, city: values.city.id };

        const newClub = await createClub(newClubData);

        referencesStore.setReference('clubs', [newClub, ...referencesStore.getReference('clubs')]);
        referencesStore.setReference('filteredClubs', [
            newClub,
            ...referencesStore.getReference('filteredClubs')
        ]);
        addSuccessToast('Значение было успешно добавлено в справочник');
        setVisible(false);
    };

    return <ClubsForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

const EditClub = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast, addInfoToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: patchClub } = useAxios({
        method: 'patch',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.clubs.uri}${initialValues.id}/`
    });

    const handleSubmit = async (values) => {
        const newData = { ...values, city: values.city.id };
        const difference = differenceBetweenObjects(newData, initialValues);

        if (Object.keys(difference).length) {
            try {
                const patchedClub = await patchClub(difference);
                if (patchedClub) {
                    referencesStore.updateReferenceItem('clubs', patchedClub.id, patchedClub);
                    // referencesStore.updateReferenceItem('filteredClubs', patchedClub.id, patchedClub);
                    addSuccessToast('Изменения в справочник были успешно внесены');
                }
            } catch (error) {
                console.error(error);
                addSuccessToast('Изменения в справочник были успешно внесены');
            }
        } else {
            addInfoToast('Без изменений');
        }
        setVisible(false);
    };

    const [initialCity, setInitialCity] = useState(null);

    const { request: getCity } = useAxios({
        method: 'get',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.cities.uri}${initialValues.city}/`
    });

    const loadInitialCity = async () => {
        const data = await getCity();
        setInitialCity(data);
    };

    useLayoutEffect(() => {
        loadInitialCity();
    }, []);

    return (
        <ClubsForm
            handleSubmit={handleSubmit}
            initialValues={{
                ...initialValues,
                city: initialCity
            }}
        />
    );
};

const SportsCategoryForm = ({ initialValues, handleSubmit }) => {
    const classes = useReferencesFormsStyles();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={SportsValidationSchema}
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setFieldTouched
                // setFieldValue
            }) => (
                <Form>
                    <FormField.Text
                        label="Название разряда"
                        name="name"
                        type="text"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.name}
                        errors={errors.name}
                    />
                    <FastField name="is_pair">
                        {() => (
                            <FormField.RadioGroup
                                name="is_pair"
                                handleChange={handleChange}
                                value={values.is_pair}
                                touched={touched.is_pair}
                                errors={errors.is_pair}
                                setTouched={setFieldTouched}
                                options={[
                                    { id: true, name: 'Парный' },
                                    { id: false, name: 'Одиночный' }
                                ]}
                            />
                        )}
                    </FastField>
                    <Button
                        className={classes.btn}
                        size="large"
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Сохранить
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const CreateSportsCategory = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: createSportsCategory } = useAxios({
        method: 'post',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.sportCategories.uri}`
    });

    const handleSubmit = async (values) => {
        const newSportsCategoryData = { ...values };

        const newSportsCategory = await createSportsCategory(newSportsCategoryData);

        referencesStore.setReference('sportCategories', [
            newSportsCategory,
            ...referencesStore.getReference('sportCategories')
        ]);
        addSuccessToast('Значение было успешно добавлено в справочник');
        setVisible(false);
    };

    return <SportsCategoryForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

const EditSportsCategory = () => {
    const referencesStore = useReferencesStore();
    const { addSuccessToast, addInfoToast } = useContext(ToastContext);
    const { initialValues, setVisible } = useContext(ReferencesContext);
    const { request: patchSportsCategory } = useAxios({
        method: 'patch',
        url: `${config.server.serviceHostPublic}${config.server.apiPublic.sportCategories.uri}${initialValues.id}/`
    });

    const handleSubmit = async (values) => {
        const newData = { ...values };
        const difference = differenceBetweenObjects(newData, initialValues);
        if (Object.keys(difference).length) {
            try {
                const patchedSportsCategory = await patchSportsCategory(difference);
                if (patchedSportsCategory) {
                    referencesStore.updateReferenceItem(
                        'sportCategories',
                        patchedSportsCategory.id,
                        patchedSportsCategory
                    );
                    addSuccessToast('Изменения в справочник были успешно внесены');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            addInfoToast('Без изменений');
        }
        setVisible(false);
    };

    return <SportsCategoryForm handleSubmit={handleSubmit} initialValues={initialValues} />;
};

// при добавлении попапа добавлять соответствующие ключи к referencesInitialValues в конфиг
const ReferencesForms = ({ reference, visible, setVisible, initialValues, title, type }) => (
    <ReferencesContext.Provider
        value={{
            initialValues,
            setVisible
        }}
    >
        <Popup visible={visible} setVisible={setVisible} width="sm" title={title}>
            {
                {
                    create: {
                        sportCategories: <CreateSportsCategory />,
                        tournamentCategories: <CreateTournamentCategory />,
                        cities: <CreateCity />,
                        regions: <CreateRegion />,
                        clubs: <CreateClub />
                    },
                    edit: {
                        sportCategories: <EditSportsCategory />,
                        tournamentCategories: <EditTournamentCategory />,
                        cities: <EditCity />,
                        regions: <EditRegion />,
                        clubs: <EditClub />
                    }
                }[type][reference]
            }
        </Popup>
    </ReferencesContext.Provider>
);

ReferencesForms.propTypes = {
    reference: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

TournamentCategoriesForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formType: PropTypes.string.isRequired,
    setInitialPoints: PropTypes.func
};

TournamentCategoriesForm.defaultProps = {
    setInitialPoints: PropTypes.func
};

CitiesForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

RegionsForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

ClubsForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

SportsCategoryForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default ReferencesForms;
