import FormHelperText from '@mui/material/FormHelperText';
import { RadioGroup as MuiRadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import useFormFieldStyles from './styles';
import Radio from './Radio';

export interface RadioGroupProps {
    options: any[];
    name: string;
    value: string;
    handleChange: any;
    setTouched: any;
    touched: any;
    errors: any;
    row?: boolean;
    disabled?: boolean;
}

const RadioGroup = ({
    options,
    name,
    value,
    handleChange,
    setTouched,
    touched,
    errors,
    row,
    disabled
}: RadioGroupProps) => {
    const classes = useFormFieldStyles({
        error: !!errors
    });

    return (
        <FormControl error={!!(touched && errors)} disabled={disabled}>
            <MuiRadioGroup
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={() => {
                    if (setTouched) {
                        setTouched(name, true);
                    }
                }}
                row={row}
            >
                {options.map((option) => (
                    <Radio key={option.id} {...option} disabled={disabled} />
                ))}
            </MuiRadioGroup>
            {!!(touched && errors) && (
                <FormHelperText
                    classes={{
                        root: classes.errorText
                    }}
                >
                    {errors}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default RadioGroup;
