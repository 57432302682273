import { useCallback, useContext, useMemo, useState } from 'react';
import axios from 'axios';

import useStore from './useStore';
import useOnError from './useOnError';
import RecaptchaContext from '../utils/context/RecaptchaContext';

const DEFAULT_CONFIG = {
    displayErrors: true,
    withCaptcha: false
};

const useAxios = ({ url = '', params = {}, headers = {}, ...rest }, config = {}) => {
    const { displayErrors, withCaptcha } = useMemo(
        () => ({ ...DEFAULT_CONFIG, ...config }),
        [config]
    );
    const { adminDataStore } = useStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { onError: onUnauthorisedError } = useOnError();

    const context = useContext(RecaptchaContext);

    const request = useCallback(
        async (payload, customHeaders, customParams, customUrl, isRetry = false) => {
            setLoading(true);

            if (adminDataStore.refreshRequest) {
                const data = await onUnauthorisedError(
                    null,
                    isRetry,
                    request,
                    [payload, customHeaders, customParams, customUrl],
                    displayErrors,
                    true
                );

                return data;
            }

            try {
                let XCaptchaToken = null;
                if (withCaptcha) {
                    XCaptchaToken = await context.getToken();
                }
                const { data } = await axios.request({
                    ...rest,
                    ...(payload && { data: payload }),
                    url: customUrl || url,
                    params: {
                        ...params,
                        ...(customParams && { ...customParams })
                    },
                    headers: {
                        ...headers,
                        ...(customHeaders && { ...customHeaders }),
                        ...(adminDataStore.access && {
                            Authorization: `Bearer ${adminDataStore.access}`
                        }),
                        ...(adminDataStore.token && {
                            'X-Requested-With': adminDataStore.token
                        }),
                        ...(XCaptchaToken && { 'X-Captcha-Token': XCaptchaToken }),
                        ...(XCaptchaToken && { 'X-Captcha-V': 3 })
                    }
                });
                setLoading(false);
                return data;
            } catch (error) {
                onUnauthorisedError(
                    error,
                    isRetry,
                    request,
                    [payload, customHeaders, customParams, customUrl],
                    displayErrors
                );
            } finally {
                setLoading(false);
            }
        },
        [adminDataStore.access, adminDataStore.token, url, params, headers, rest]
    );

    const clearError = useCallback(() => setError(null), []);

    return {
        loading,
        setLoading,
        request,
        error,
        clearError
    };
};

export default useAxios;
