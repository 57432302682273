import dataUrlToFile from './dataUrlToFile';

export default function getCroppedImage(
    image,
    pixelCrop,
    fileName,
    output = { width: pixelCrop.width, height: pixelCrop.height }
) {
    // const outputOpts = output || { width: pixelCrop.width, height: pixelCrop.height };
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // const pixelRatio = window.devicePixelRatio;
    const pixelRatio = 1.5; // с window.devicePixelRatio будет разное поведение на разных устройствах, захардкодим 1,5

    canvas.width = pixelCrop.width * pixelRatio;
    canvas.height = pixelCrop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    const canvas2 = document.createElement('canvas');
    canvas2.width = output.width;
    canvas2.height = output.height;
    const ctx2 = canvas2.getContext('2d');
    ctx2.imageSmoothingQuality = 'high';
    ctx2.drawImage(canvas, 0, 0, output.width, output.height);

    // As Base64 string (если нужно превью)
    const base64Image = canvas2.toDataURL('image/jpeg');

    // As a blob
    const blobPromise = new Promise((resolve) => {
        canvas2.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve({ image: blob });
            },
            'image/jpeg',
            1
        );
    });

    return new Promise((resolve) => {
        const image = dataUrlToFile(base64Image, fileName);
        image.path = fileName;
        resolve({ image });
    });
}
