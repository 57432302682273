import makeStyles from '@mui/styles/makeStyles';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(7),

        '&:hover': {
            backgroundColor: tinycolor(theme.palette.primary.main).lighten(5).toString()
        }
    }
}));

export default useStyles;
