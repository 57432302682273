import { makeAutoObservable } from 'mobx';

export default class PublicationsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    publications = [];

    get getPublications() {
        return this.publications;
    }

    setPublications = (publications) => {
        this.publications = publications;
    };

    updatePublicationField = (id, value, field) => {
        const publicationIndex = this.publications.findIndex(
            (publication) => publication.id === id
        );
        this.publications[publicationIndex] = {
            ...this.publications[publicationIndex],
            [field]: value
        };
    };

    updatePublication = (id, newPublicationValues) => {
        const publicationIndex = this.publications.findIndex(
            (publication) => publication.id === id
        );
        this.publications[publicationIndex] = {
            ...newPublicationValues,
            orderNumber: this.publications[publicationIndex].orderNumber
        };
    };

    removePublication = (id) => {
        this.publications = this.publications.filter((publication) => publication.id !== id);
    };

    clearStore = () => {
        // This array accepts all fields that has an empty array as initial value
        ['publications'].forEach((key) => {
            this[key] = [];
        });
        console.warn('PublicationsStore clear');
    };
}
