import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { compact, noop } from 'lodash';
import useFormFieldStyles from './styles';
import TextFieldStyled from './TextFieldStyled';

export interface AutoCompleteProps {
    label: string;
    name: string;
    value: any;
    // eslint-disable-next-line no-unused-vars
    setFieldValue?: (name: string, value: any) => void;
    // eslint-disable-next-line no-unused-vars
    setTouched?: (name: string, value: boolean) => void;
    options: any[];
    keysToLabel?: string[];
    disabled?: boolean;
    selectionDisabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    customOnChange?: ((e: any, value: any) => void) | null;
    // eslint-disable-next-line no-unused-vars
    onInput?: (value: any) => void;
    onOpen?: () => void;
    deleteBtn?: JSX.Element | null;
    touched?: boolean;
    errors?: string;
}

const AutoComplete = ({
    label,
    name,
    value,
    setFieldValue,
    setTouched = noop,
    options,
    keysToLabel = [],
    // touched = false,
    // errors = false,
    disabled = false,
    selectionDisabled = false,
    customOnChange = null,
    onInput = () => {},
    deleteBtn = null,
    ...rest
}: AutoCompleteProps) => {
    const classes = useFormFieldStyles({
        error: false
    });

    const [noOptionsText, setNoOptionsText] = useState('Введите запрос');

    const getOptionLabel = (option: any) =>
        option
            ? compact([option?.name, ...keysToLabel.map((key) => option[key] || '')]).join(', ')
            : '';

    return (
        <Autocomplete
            // @ts-ignore
            name={name}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={(option) => option.disabled}
            value={value || ''}
            classes={{
                root: classes.autocomplete,
                input: classes.input,
                inputFocused: classes.input,
                listbox: classes.input
            }}
            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
            onChange={(e, value) => {
                if (!customOnChange) {
                    const newFieldValue = value || '';

                    setFieldValue?.(name, newFieldValue);
                } else {
                    customOnChange(e, value);
                }
            }}
            onInput={(e) => {
                setNoOptionsText(
                    (e.target as HTMLInputElement).value ? 'Ничего не найдено' : 'Введите запрос'
                );
                onInput(e);
            }}
            disabled={selectionDisabled}
            onBlur={() => setTouched && setTouched(name, true)}
            renderInput={(params) => (
                <TextFieldStyled
                    {...params}
                    label={label}
                    variant="outlined"
                    FormHelperTextProps={{
                        className: classes.errorText
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: disabled && (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ),
                        ...(deleteBtn && !disabled && { endAdornment: deleteBtn })
                    }}
                    error={!!(rest.touched && rest.errors)}
                    helperText={rest.touched && rest.errors}
                    disabled={selectionDisabled}
                    fullWidth
                />
            )}
            modifiers={{
                fixed: true
            }}
            openOnFocus
            noOptionsText={<>{noOptionsText}</>}
            {...rest}
        />
    );
};

export default AutoComplete;
